import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Typography } from "@mui/material";

interface Props {
  buttonText: string;
  hideModal: () => void;
  modalTitle: string;
  modalBody1: string;
  modalBody2: string;
  modalBody3: string;
  modalFootnote: string;
  showModal: boolean;
}

export default function TutorialPromptModal({
  buttonText,
  hideModal,
  modalBody1,
  modalBody2,
  modalBody3,
  modalTitle,
  modalFootnote,
  showModal,
}: Props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    // saveUserFeedback();
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
    // SaveUserLevelComplete(completionStats);
  };

  useEffect(() => {
    if (showModal) {
      handleShow();
    } else handleClose();
  }, [showModal]);

  const nextLevelClick = () => {
    // saveUserFeedback();
    // hideModal();
    // nextLevel();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        animation
        style={{ maxWidth: "100vw" }}
      >
        <Modal.Header
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Modal.Title>
            <Typography
              variant="h5"
              color={"primary"}
              sx={{ textAlign: "center" }}
            >
              {modalTitle}
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {modalBody1}
          </Typography>
        </Modal.Body>
        {modalBody2.length > 0 && (
          <Modal.Body
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                whiteSpace: "pre-line",
              }}
            >
              {modalBody2}
            </Typography>
          </Modal.Body>
        )}
        {modalBody3.length > 0 && (
          <Modal.Body
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              {modalBody3}
            </Typography>
            {modalFootnote.length > 0 && (
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic", textAlign: "center" }}
              >
                {modalFootnote}
              </Typography>
            )}
          </Modal.Body>
        )}

        <Modal.Footer
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button variant="contained" color="secondary" onClick={hideModal}>
            {buttonText}
          </Button>
          {/* <Button variant="primary">Next level</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
