import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/authContext";
import { useLevelContext } from "../../context/levelContext";
import {
    colors,
    componentColors,
    getUserCampaignLevel,
    GetTodaysDailyPuzzle,
    GetPuzzleByLevelNumber,
    GetUserGauntletInstance,
    LevelCompleteStats,
    LevelTypes,
    PromptDataInterface,
    PromptType,
    Puzzle,
    PuzzleBoard,
    RdsLevel,
    UpdateUserProfile,
} from "@summitrhode/shared";

import { LevelBox, MoveBox, NextBox, PuzzleBox, PreviousBox, TimerBox, WordBox } from ".";
import { PuzzleBoardProvider, usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import { HintsBox } from "./HintsBox";
import LevelCompleteModal from "./LevelCompleteModal";

let animationData = require("@summitrhode/shared/assets/animations/puzzleComplete.json");
import TutorialPromptModal from "../Tutorial/TutorialPromptModal";
import tutorialPrompts from "../Tutorial/TutorialPrompts";
import { LoadingWheel } from "../UtilityComponents/LoadingWheel";
import GauntletIntroModal from "./GauntletIntroModal";

interface Props {
    levelType: LevelTypes;
}

export function PuzzlePage({ levelType }: Props) {
    const { currentCampaignLevel, currentExpansionLevel, currentGauntletLevel, updateCurrentCampaignLevel, updateCurrentExpansionLevel, updateCurrentGauntletLevel } = useLevelContext();

    const { puzzleBoard } = usePuzzleBoardContext();

    const navigate = useNavigate();
    // const { sessionInfo, authStatus, attrInfo } = useAuth();
    const { authStatus, user } = useAuth();
    const [puzzle, setPuzzle] = useState<Puzzle | undefined>();
    const [bigHintEngaged, setBigHintEngaged] = useState<boolean>(false);
    const [littleHintEngaged, setLittleHintEngaged] = useState<boolean>(false);
    const [checkAllEngaged, setCheckAllEngaged] = useState<boolean>(false);
    const [checkAllEnabled, setCheckAllEnabled] = useState<boolean>(true);
    const [bigHintEnabled, setBigHintEnabled] = useState<boolean>(true);
    const [littleHintEnabled, setLittleHintEnabled] = useState<boolean>(true);
    const [revealPuzzleEngaged, setRevealPuzzleEngaged] = useState<boolean>(false);
    const [revealPuzzleEnabled, setRevealPuzzleEnabled] = useState<boolean>(false);
    const [showLevelCompleteModal, setShowLevelCompleteModal] = useState<boolean>(false);
    const [showTutorialPromptModal, setShowTutorialPromptModal] = useState<boolean>(false);
    const [showGauntletIntroModal, setShowGauntletIntroModal] = useState<boolean>(false);
    const [levelCompleteStats, setLevelCompleteStats] = useState<LevelCompleteStats | undefined>();
    const [noMoreLevels, setNoMoreLevels] = useState<boolean>(false);
    const [levelsLoaded, setLevelsLoaded] = useState<boolean>(false);
    const [currentLevelNumber, setCurrentLevelNumber] = useState<number>(-1);
    const [currentDailyDate, setCurrentDailyDate] = useState<string>("");
    const [isTutorialPuzzle, setIsTutorialPuzzle] = useState<boolean>(false);
    const [littleHintsRemaining, setLittleHintsRemaining] = useState<number>(3);
    const [bigHintsRemaining, setBigHintsRemaining] = useState<number>(1);
    const [checkAllsRemaining, setCheckAllsRemaining] = useState<number>(2);
    const [revealPuzzlesRemaining, setRevealPuzzlesRemaining] = useState<number>(1);

    const [cachedPuzzleBoard, setCachedPuzzleBoard] = useState<PuzzleBoard | undefined>(undefined);

    // Pauses the timer and mechanics
    const [pausePuzzle, setPausePuzzle] = useState<boolean>(true);

    // Waits for user to accept the Gauntlet Prompt before enabling everything
    const [startPuzzle, setStartPuzzle] = useState<boolean>(false);

    const [tutorialPromptInformation, setTutorialPromptInformation] = useState<PromptDataInterface>({
        promptType: PromptType.INITIAL,
        title: "",
        body1: "",
        body2: "",
        body3: "",
        footnote: "",
        buttonText: "",
        settings: {
            bigHintEnabled: true,
            littleHintEnabled: true,
            checkAllEnabled: true,
        },
    });

    // I'm borrowing the MDN doc notation here: "mql" stands for "media query list".
    const mql = window.matchMedia("(max-width: 600px)");

    // Tutorial Pack # in DB
    const _tutorialExpansionPackNumber = 10;

    let mobileView = mql.matches;

    window.addEventListener("blur", (e) => {
        setPausePuzzle(true);
    });

    window.addEventListener("focus", (e) => {
        setPausePuzzle(false);
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (levelType !== LevelTypes.GAUNTLET) {
            setPausePuzzle(false);
            setStartPuzzle(true);
        }
    }, [levelsLoaded]);

    useEffect(() => {
        if (noMoreLevels) {
            navigate("/");
        }
    }, [noMoreLevels]);

    /**
     * Updates level content as campaign or expansion pack level changes
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        if (levelType === LevelTypes.CAMPAIGN) {
            let bigHintCount = 1;
            let checkAllCount = 2;
            let littleHintCount = 3;
            const cachedData = localStorage.getItem("campaignLevelData");
            if (cachedData) {
                const data = JSON.parse(cachedData);
                if (data.levelNumber === currentCampaignLevel.levelNumber && data.userId === user?.userId) {
                    // setCurrentDailyDate(data.currentDailyDate);
                    setCachedPuzzleBoard(data.board as PuzzleBoard);
                    bigHintCount -= data.board._bigHintsUsed;
                    checkAllCount -= data.board._checkAllsUsed;
                    littleHintCount -= data.board._littleHintsUsed;
                }
            }
            setRevealPuzzleEnabled(false);

            setBigHintsRemaining(bigHintCount);
            setBigHintEnabled(bigHintCount > 0 ? true : false);
            setCheckAllsRemaining(checkAllCount);
            setCheckAllEnabled(checkAllCount > 0 ? true : false);
            setLittleHintsRemaining(littleHintCount);
            setLittleHintEnabled(littleHintCount > 0 ? true : false);
            const fetchLevel = async () => {
                updateCurrentCampaignLevel(new RdsLevel("", await getUserCampaignLevel()));
            };

            if (currentCampaignLevel.levelId === "startup" && authStatus) {
                fetchLevel();
                return;
            }

            if (currentCampaignLevel && authStatus) {
                loadLevel(currentCampaignLevel.levelNumber, undefined, undefined);
                return;
            }
        } else if (levelType === LevelTypes.DAILY) {
            const loadDailyLevel = async () => {
                const tempPuzzle = await GetTodaysDailyPuzzle();
                if (tempPuzzle) {
                    setNoMoreLevels(false);
                } else {
                    setNoMoreLevels(true);
                }
                let bigHintCount = 0;
                let checkAllCount = 2;
                let littleHintCount = 3;
                const cachedData = localStorage.getItem("dailyLevelData");
                if (cachedData) {
                    const data = JSON.parse(cachedData);
                    if (data.currentDailyDate === tempPuzzle?.levelNumber && data.userId === user?.userId) {
                        // setCurrentDailyDate(data.currentDailyDate);
                        setCachedPuzzleBoard(data.board as PuzzleBoard);
                        bigHintCount -= data.board._bigHintsUsed;
                        checkAllCount -= data.board._checkAllsUsed;
                        littleHintCount -= data.board._littleHintsUsed;
                    }
                }

                setRevealPuzzleEnabled(false);
                setRevealPuzzlesRemaining(1);

                setBigHintsRemaining(bigHintCount);
                setBigHintEnabled(bigHintCount > 0 ? true : false);
                setCheckAllsRemaining(checkAllCount);
                setCheckAllEnabled(checkAllCount > 0 ? true : false);
                setLittleHintsRemaining(littleHintCount);
                setLittleHintEnabled(littleHintCount > 0 ? true : false);
                setPuzzle(tempPuzzle);
                setCurrentDailyDate(tempPuzzle?.levelNumber ? tempPuzzle.levelNumber + "" : "");
                setLevelsLoaded(true);
            };
            loadDailyLevel();
        } else if (levelType === LevelTypes.EXPANSION) {
            if (currentExpansionLevel && authStatus) {
                let bigHintCount = 1;
                let checkAllCount = 2;
                let littleHintCount = 3;
                const cachedData = localStorage.getItem("expansionLevelData" + currentExpansionLevel.expansionPackId);
                if (cachedData) {
                    const data = JSON.parse(cachedData);
                    if (data.levelNumber === currentExpansionLevel.levelNumber && data.userId === user?.userId) {
                        // setCurrentDailyDate(data.currentDailyDate);
                        setCachedPuzzleBoard(data.board as PuzzleBoard);
                        bigHintCount -= data.board._bigHintsUsed;
                        checkAllCount -= data.board._checkAllsUsed;
                        littleHintCount -= data.board._littleHintsUsed;
                    }
                }

                setRevealPuzzleEnabled(false);

                setBigHintsRemaining(bigHintCount);
                setBigHintEnabled(bigHintCount > 0 ? true : false);
                setCheckAllsRemaining(checkAllCount);
                setCheckAllEnabled(checkAllCount > 0 ? true : false);
                setLittleHintsRemaining(littleHintCount);
                setLittleHintEnabled(littleHintCount > 0 ? true : false);
                loadLevel(currentExpansionLevel.levelNumber, currentExpansionLevel.expansionPackId, undefined);
                return;
            }
        } else if (levelType === LevelTypes.TUTORIAL) {
            if (authStatus) {
                setBigHintsRemaining(5);
                setLittleHintsRemaining(5);
                setRevealPuzzleEnabled(false);

                // default to first level of tutorial
                // in pack #10
                setIsTutorialPuzzle(true);
                loadLevel(1, _tutorialExpansionPackNumber, undefined);
                updateTutorialPrompt(PromptType.INITIAL, 1);
                setShowTutorialPromptModal(true);
                return;
            }
        } else if (levelType === LevelTypes.GAUNTLET) {
            setShowGauntletIntroModal(true);
            if (authStatus) {
                const getGauntlet = async () => {
                    let gauntletLevel: {
                        levelNumber: number;
                        expansionPackID: undefined;
                        gauntletPackID: number;
                    };
                    if (currentGauntletLevel.gauntletPackId) {
                        gauntletLevel = {
                            levelNumber: currentGauntletLevel.levelNumber,
                            expansionPackID: undefined,
                            gauntletPackID: currentGauntletLevel.gauntletPackId,
                        };
                    } else {
                        const gauntletResult = await GetUserGauntletInstance();
                        await updateCurrentGauntletLevel(new RdsLevel("", gauntletResult.levelNumber, LevelTypes.GAUNTLET, undefined, undefined, gauntletResult.gauntletPackId));
                        gauntletLevel = {
                            levelNumber: gauntletResult.levelNumber,
                            expansionPackID: undefined,
                            gauntletPackID: gauntletResult.gauntletPackId ? gauntletResult.gauntletPackId : -1,
                        };
                    }

                    let bigHintCount = 0;
                    let checkAllCount = 1;
                    let littleHintCount = 0;
                    const cachedData = localStorage.getItem("gauntletLevelData" + currentGauntletLevel.gauntletPackId);
                    if (cachedData) {
                        const data = JSON.parse(cachedData);
                        if (data.levelNumber === currentGauntletLevel.levelNumber && data.userId === user?.userId) {
                            // setCurrentDailyDate(data.currentDailyDate);
                            setCachedPuzzleBoard(data.board as PuzzleBoard);
                            bigHintCount -= data.board._bigHintsUsed;
                            checkAllCount -= data.board._checkAllsUsed;
                            littleHintCount -= data.board._littleHintsUsed;
                        }
                    }
                    setRevealPuzzleEnabled(false);

                    setBigHintsRemaining(bigHintCount);
                    setBigHintEnabled(bigHintCount > 0 ? true : false);
                    setCheckAllsRemaining(checkAllCount);
                    setCheckAllEnabled(checkAllCount > 0 ? true : false);
                    setLittleHintsRemaining(littleHintCount);
                    setLittleHintEnabled(littleHintCount > 0 ? true : false);

                    loadLevel(gauntletLevel.levelNumber, gauntletLevel.expansionPackID, gauntletLevel.gauntletPackID);
                };

                getGauntlet();

                return;
            }
        }
    }, [currentCampaignLevel, currentExpansionLevel, currentGauntletLevel, levelType, authStatus]); //[currentLevel, sessionInfo]);

    const cacheLevelData = (board: PuzzleBoard | undefined) => {
        if (board && user) {
            const userId = user.userId;

            if (levelType === LevelTypes.DAILY) {
                localStorage.setItem("dailyLevelData", JSON.stringify({ userId, currentDailyDate, board }));
            } else if (levelType === LevelTypes.CAMPAIGN) {
                localStorage.setItem(
                    "campaignLevelData",
                    JSON.stringify({
                        userId,
                        levelNumber: currentCampaignLevel.levelNumber,
                        board,
                    })
                );
            } else if (levelType === LevelTypes.EXPANSION) {
                localStorage.setItem(
                    "expansionLevelData" + currentExpansionLevel.expansionPackId,
                    JSON.stringify({
                        userId,
                        levelNumber: currentExpansionLevel.levelNumber,
                        board,
                    })
                );
            } else if (levelType === LevelTypes.GAUNTLET) {
                localStorage.setItem(
                    "gauntletLevelData" + currentGauntletLevel.gauntletPackId,
                    JSON.stringify({
                        userId,
                        levelNumber: currentGauntletLevel.levelNumber,
                        board,
                    })
                );
            }
        }
    };

    const loadLevel = async (levelNumber: number, expansionPackId: number | undefined, gauntletPackId: number | undefined) => {
        const tempPuzzle = await GetPuzzleByLevelNumber(levelType, levelNumber, "", expansionPackId, gauntletPackId);
        if (tempPuzzle) {
            setNoMoreLevels(false);
            setPuzzle(tempPuzzle);
            setCheckAllsRemaining(2);
            // setCheckAllEnabled(true);
            setCurrentLevelNumber(levelNumber);
        } else {
            setNoMoreLevels(true);
        }
        setLevelsLoaded(true);
    };

    const nextLevel = () => {
        setLevelsLoaded(false);
        setPausePuzzle(true);
        setStartPuzzle(false);
        setShowLevelCompleteModal(false);
        if (levelType === LevelTypes.CAMPAIGN) {
            updateCurrentCampaignLevel(new RdsLevel("", currentCampaignLevel.levelNumber + 1));
        } else if (levelType === LevelTypes.EXPANSION) {
            updateCurrentExpansionLevel(new RdsLevel("", currentExpansionLevel.levelNumber + 1, LevelTypes.EXPANSION, currentExpansionLevel.expansionPackId, currentExpansionLevel.expansionPackName));
        } else if (levelType === LevelTypes.GAUNTLET) {
            updateCurrentGauntletLevel(
                new RdsLevel("", currentGauntletLevel.levelNumber + 1, LevelTypes.GAUNTLET, undefined, undefined, currentGauntletLevel.gauntletPackId, currentGauntletLevel.gauntletPackName)
            );
        } else if (levelType === LevelTypes.TUTORIAL) {
            if (currentLevelNumber < 3) {
                loadLevel(currentLevelNumber + 1, _tutorialExpansionPackNumber, undefined);
                updateTutorialPrompt(PromptType.INITIAL, currentLevelNumber + 1);
            } else {
                UpdateUserProfile("", "", "", "", true).then(() => navigate(`/`));
                //saveTutorialCompletion
            }
        }
    };

    const previousLevel = () => {
        updateCurrentCampaignLevel(new RdsLevel("", currentCampaignLevel.levelNumber - 1));
    };

    /**
     * Updates prompt as tutorial prompt state changes
     */
    const updateTutorialPrompt = (promptType: PromptType, initialLevelNumber?: number) => {
        let levelNumber = initialLevelNumber ? initialLevelNumber : currentLevelNumber;

        let promptDataArray: PromptDataInterface[] = [];

        tutorialPrompts.forEach((tutorialPrompt) => {
            if (tutorialPrompt.levelNumber === levelNumber) {
                promptDataArray = tutorialPrompt.levelPrompts;
            }
        });

        promptDataArray.forEach((prompt) => {
            if (prompt.promptType === promptType) {
                setBigHintEnabled(prompt.settings.bigHintEnabled);
                setLittleHintEnabled(prompt.settings.littleHintEnabled);
                setCheckAllEnabled(prompt.settings.checkAllEnabled);

                setTutorialPromptInformation(prompt);
                setShowTutorialPromptModal(true);
            }
        });
    };

    const closeTutorialPromptModal = () => {
        setShowTutorialPromptModal(false);
        if (tutorialPromptInformation.promptType === PromptType.SUCCESS) {
            nextLevel();
        }
    };

    const toggleBigHintButton = (hintWasUsed: boolean) => {
        if (bigHintEngaged) {
            setBigHintEngaged(false);
            if (hintWasUsed) {
                setBigHintsRemaining(bigHintsRemaining - 1);
                if (bigHintsRemaining <= 1) {
                    setBigHintEnabled(false);
                }
            }
        } else {
            setBigHintEngaged(true);
            setLittleHintEngaged(false);
            setCheckAllEngaged(false);
            setRevealPuzzleEngaged(false);
        }
    };

    const disableAllHints = () => {
        setBigHintEnabled(false);
        setLittleHintEnabled(false);
        setCheckAllEnabled(false);
        setRevealPuzzleEngaged(false);
    };

    const toggleLittleHintButton = (hintWasUsed: boolean) => {
        if (littleHintEngaged) {
            setLittleHintEngaged(false);
            if (hintWasUsed) {
                setLittleHintsRemaining(littleHintsRemaining - 1);
                if (littleHintsRemaining <= 1) {
                    setLittleHintEnabled(false);
                }
            }
        } else {
            setLittleHintEngaged(true);
            setBigHintEngaged(false);
            setCheckAllEngaged(false);
            setRevealPuzzleEngaged(false);
        }
    };

    const toggleCheckAllButton = (hintWasUsed: boolean) => {
        if (checkAllEngaged) {
            setCheckAllEngaged(false);
            if (hintWasUsed) {
                setCheckAllsRemaining(checkAllsRemaining - 1);
                if (checkAllsRemaining <= 1) {
                    setCheckAllEnabled(false);
                }
            }
        } else {
            setCheckAllEngaged(true);
            setLittleHintEngaged(false);
            setBigHintEngaged(false);
            setRevealPuzzleEngaged(false);
        }
    };

    const enableRevealPuzzleButton = () => {
        if (levelType === LevelTypes.DAILY) {
            setRevealPuzzleEnabled(true);
        }
    };

    const toggleRevealPuzzleButton = (hintWasUsed: boolean) => {
        if (revealPuzzleEngaged) {
            setRevealPuzzleEngaged(false);
            if (hintWasUsed) {
                setRevealPuzzlesRemaining(revealPuzzlesRemaining - 1);
                if (revealPuzzlesRemaining <= 1) {
                    setRevealPuzzleEnabled(false);
                }
            }
        } else {
            setCheckAllEngaged(false);
            setLittleHintEngaged(false);
            setBigHintEngaged(false);
            setRevealPuzzleEngaged(true);
        }
    };

    const timeOutLevel = () => {
        setPausePuzzle(true);
    };

    const completeLevel = (completionStats: LevelCompleteStats) => {
        setPausePuzzle(false);
        disableAllHints();
        setShowLevelCompleteModal(true);
        setLevelCompleteStats(completionStats);
    };
    if (levelsLoaded) {
        if (mobileView) {
            return (
                <div>
                    <Box
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {levelType !== LevelTypes.TUTORIAL && (
                            <LevelCompleteModal
                                puzzleComplete={showLevelCompleteModal}
                                completionStats={levelCompleteStats}
                                levelType={levelType}
                                nextLevel={nextLevel}
                                hideModal={() => setShowLevelCompleteModal(false)}
                                isMobile={mobileView}
                            />
                        )}
                        <TutorialPromptModal
                            buttonText={tutorialPromptInformation.buttonText}
                            hideModal={() => closeTutorialPromptModal()}
                            modalBody1={tutorialPromptInformation.body1}
                            modalBody2={tutorialPromptInformation.body2}
                            modalBody3={tutorialPromptInformation.body3}
                            modalFootnote={tutorialPromptInformation.footnote ? tutorialPromptInformation.footnote : ""}
                            modalTitle={tutorialPromptInformation.title}
                            showModal={showTutorialPromptModal}
                        />
                        {levelType === LevelTypes.GAUNTLET && puzzle && puzzle.timeLimit && puzzle.moveLimit && (
                            <GauntletIntroModal
                                parentCategory={puzzle.parentCategory}
                                subCategory={puzzle.subCategory}
                                difficulty={puzzle.difficulty}
                                timeLimit={puzzle.timeLimit}
                                moveLimit={puzzle.moveLimit}
                                startLevel={() => setPausePuzzle(false)}
                                hideModal={() => setShowGauntletIntroModal(false)}
                                isMobile={mobileView}
                            />
                        )}
                    </Box>
                    <PuzzleBoardProvider>
                        {puzzle && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    overscrollBehaviorY: "contain",
                                    height: "85vh",
                                    width: "100vw",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "98vw",
                                        alignItems: "center",

                                        height: "calc((92.5vh-100vw)*.12)",
                                    }}
                                >
                                    <LevelBox
                                        levelType={levelType}
                                        enableRevealPuzzleButton={enableRevealPuzzleButton}
                                        timeLimit={puzzle.timeLimit ? puzzle.timeLimit : 0}
                                        moveLimit={puzzle.moveLimit ? puzzle.moveLimit : 0}
                                        boxPadding={".75vh"}
                                        cacheLevelData={cacheLevelData}
                                        difficulty={puzzle.difficulty}
                                        isMobile={true}
                                        levelNumber={levelType === LevelTypes.DAILY ? currentDailyDate : currentLevelNumber}
                                        pausePuzzle={pausePuzzle}
                                        showTimer={levelType === LevelTypes.DAILY || levelType === LevelTypes.GAUNTLET ? true : false}
                                        subtitle={puzzle.subtitle}
                                        timeOutLevel={timeOutLevel}
                                        title={puzzle.title}
                                    />
                                </div>

                                <Paper
                                    elevation={5}
                                    sx={{
                                        maxWidth: "1000px",
                                        maxHeight: "95vw",
                                        borderRadius: "1vh",
                                        border: `.4vh ${componentColors.tertiary.borderColor + "CC"}`,
                                        padding: "1vw",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // boxSizing:"border-box",
                                        marginY: ".75vh",

                                        background: componentColors.tertiary.backgroundColorFade,
                                    }}
                                >
                                    <PuzzleBox
                                        pausePuzzle={pausePuzzle}
                                        puzzle={puzzle}
                                        startPuzzle={startPuzzle}
                                        bigHintEngaged={bigHintEngaged}
                                        checkAllEngaged={checkAllEngaged}
                                        cachedPuzzleBoard={cachedPuzzleBoard}
                                        revealPuzzleEngaged={revealPuzzleEngaged}
                                        toggleBigHintButton={toggleBigHintButton}
                                        littleHintEngaged={littleHintEngaged}
                                        toggleLittleHintButton={toggleLittleHintButton}
                                        toggleCheckAllButton={toggleCheckAllButton}
                                        toggleRevealPuzzleButton={toggleRevealPuzzleButton}
                                        completeLevel={completeLevel}
                                        isMobile={true}
                                        isTutorial={isTutorialPuzzle}
                                        updateTutorialPrompt={updateTutorialPrompt}
                                    />
                                </Paper>
                                <div className="bottomContainer" style={{ width: "95vw", height: "calc((100vh-105vw)*.6)" }}>
                                    <WordBox isMobile={true} vWheight={14} />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",

                                            justifyContent: "space-evenly",
                                        }}
                                    >
                                        <HintsBox
                                            bigHintEnabled={bigHintEnabled}
                                            bigHintEngaged={bigHintEngaged}
                                            bigHintsRemaining={bigHintsRemaining}
                                            engageBigHint={toggleBigHintButton}
                                            engageLittleHint={toggleLittleHintButton}
                                            littleHintEnabled={littleHintEnabled}
                                            littleHintEngaged={littleHintEngaged}
                                            littleHintsRemaining={littleHintsRemaining}
                                            engageCheckAll={toggleCheckAllButton}
                                            checkAllEnabled={checkAllEnabled}
                                            checkAllEngaged={checkAllEngaged}
                                            checkAllsRemaining={checkAllsRemaining}
                                            engageRevealPuzzle={toggleRevealPuzzleButton}
                                            revealPuzzleEnabled={revealPuzzleEnabled}
                                            revealPuzzleEngaged={revealPuzzleEngaged}
                                            revealPuzzlesRemaining={revealPuzzlesRemaining}
                                            isMobile={mobileView}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {noMoreLevels && (
                            <div>
                                <div>Dang! You made it to the end of our puzzle list for now.</div>
                                <div>Hopefully you enjoyed it! Go yell at us to make more levels.</div>
                            </div>
                        )}
                    </PuzzleBoardProvider>
                </div>
            );
        } else {
            return (
                <div key={"topLevel"}>
                    {levelType !== LevelTypes.TUTORIAL && (
                        <LevelCompleteModal
                            puzzleComplete={showLevelCompleteModal}
                            completionStats={levelCompleteStats}
                            levelType={levelType}
                            nextLevel={nextLevel}
                            hideModal={() => setShowLevelCompleteModal(false)}
                            isMobile={mobileView}
                        />
                    )}
                    <TutorialPromptModal
                        buttonText={tutorialPromptInformation.buttonText}
                        hideModal={() => closeTutorialPromptModal()}
                        modalBody1={tutorialPromptInformation.body1}
                        modalBody2={tutorialPromptInformation.body2}
                        modalBody3={tutorialPromptInformation.body3}
                        modalFootnote={tutorialPromptInformation.footnote ? tutorialPromptInformation.footnote : ""}
                        modalTitle={tutorialPromptInformation.title}
                        showModal={showTutorialPromptModal}
                    />
                    {levelType === LevelTypes.GAUNTLET && puzzle && puzzle.timeLimit && puzzle.moveLimit && (
                        <GauntletIntroModal
                            parentCategory={puzzle.parentCategory}
                            subCategory={puzzle.subCategory}
                            difficulty={puzzle.difficulty}
                            timeLimit={puzzle.timeLimit}
                            moveLimit={puzzle.moveLimit}
                            startLevel={() => {
                                setPausePuzzle(false);
                                setStartPuzzle(true);
                            }}
                            hideModal={() => setShowGauntletIntroModal(false)}
                            isMobile={mobileView}
                        />
                    )}
                    {showLevelCompleteModal && (
                        <div>
                            <div
                                style={{
                                    zIndex: 1,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "55vw",
                                }}
                            >
                                <Player
                                    src={animationData}
                                    className="player"
                                    // loop
                                    autoplay
                                />
                            </div>
                            <div
                                style={{
                                    zIndex: 1,
                                    position: "absolute",
                                    top: 0,
                                    left: "50vw",
                                    width: "55vw",
                                }}
                            >
                                <Player
                                    src={animationData}
                                    className="player"
                                    // loop
                                    autoplay
                                />
                            </div>
                        </div>
                    )}
                    <PuzzleBoardProvider>
                        {puzzle && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    overscrollBehaviorY: "contain",
                                    width: "100vw",
                                    height: "100vh",
                                    touchAction: "none",
                                    padding: 10,
                                }}
                            >
                                <div key={"supplementalInfo"}>
                                    <PreviousBox previousLevelCallBack={previousLevel} previousBoxText="Previous Level" />
                                    <LevelBox
                                        levelType={levelType}
                                        enableRevealPuzzleButton={enableRevealPuzzleButton}
                                        timeLimit={puzzle.timeLimit ? puzzle.timeLimit : 0}
                                        moveLimit={puzzle.moveLimit ? puzzle.moveLimit : 0}
                                        boxPadding={"10px"}
                                        cacheLevelData={cacheLevelData}
                                        difficulty={puzzle.difficulty}
                                        isMobile={false}
                                        levelNumber={levelType === LevelTypes.DAILY ? currentDailyDate : currentLevelNumber}
                                        pausePuzzle={pausePuzzle}
                                        showTimer={levelType === LevelTypes.DAILY || levelType === LevelTypes.GAUNTLET ? true : false}
                                        subtitle={puzzle.subtitle}
                                        timeOutLevel={timeOutLevel}
                                        title={puzzle.title}
                                    />
                                    <HintsBox
                                        bigHintEnabled={bigHintEnabled}
                                        bigHintEngaged={bigHintEngaged}
                                        bigHintsRemaining={bigHintsRemaining}
                                        engageBigHint={toggleBigHintButton}
                                        engageLittleHint={toggleLittleHintButton}
                                        littleHintEnabled={littleHintEnabled}
                                        littleHintEngaged={littleHintEngaged}
                                        littleHintsRemaining={littleHintsRemaining}
                                        engageCheckAll={toggleCheckAllButton}
                                        checkAllEnabled={checkAllEnabled}
                                        checkAllEngaged={checkAllEngaged}
                                        checkAllsRemaining={checkAllsRemaining}
                                        engageRevealPuzzle={toggleRevealPuzzleButton}
                                        revealPuzzleEnabled={revealPuzzleEnabled}
                                        revealPuzzleEngaged={revealPuzzleEngaged}
                                        revealPuzzlesRemaining={revealPuzzlesRemaining}
                                        isMobile={mobileView}
                                    />
                                </div>
                                <div style={{ marginRight: "30px", marginLeft: "30px" }}>
                                    <Paper
                                        sx={{
                                            width: "55vh",
                                            maxWidth: "1000px",
                                            height: "55vh",
                                            maxHeight: "1000px",
                                            borderRadius: "5%",
                                            padding: "4%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            backgroundColor: colors.tertiary + "99",
                                        }}
                                    >
                                        <PuzzleBox
                                            pausePuzzle={pausePuzzle}
                                            puzzle={puzzle}
                                            startPuzzle={startPuzzle}
                                            bigHintEngaged={bigHintEngaged}
                                            cachedPuzzleBoard={cachedPuzzleBoard}
                                            checkAllEngaged={checkAllEngaged}
                                            revealPuzzleEngaged={revealPuzzleEngaged}
                                            toggleBigHintButton={toggleBigHintButton}
                                            littleHintEngaged={littleHintEngaged}
                                            toggleLittleHintButton={toggleLittleHintButton}
                                            toggleCheckAllButton={toggleCheckAllButton}
                                            toggleRevealPuzzleButton={toggleRevealPuzzleButton}
                                            completeLevel={completeLevel}
                                            isMobile={false}
                                            isTutorial={isTutorialPuzzle}
                                            updateTutorialPrompt={updateTutorialPrompt}
                                        />
                                    </Paper>
                                    <Paper
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            backgroundColor: colors.primary,
                                        }}
                                    >
                                        <TimerBox
                                            cacheLevelData={cacheLevelData}
                                            enableRevealPuzzleButton={enableRevealPuzzleButton}
                                            isMobile={false}
                                            pausePuzzle={pausePuzzle}
                                            showTimer={levelType === LevelTypes.DAILY || levelType === LevelTypes.GAUNTLET ? true : false}
                                            levelType={levelType}
                                            timeOutLevel={timeOutLevel}
                                            timeLimit={puzzle.timeLimit ? puzzle.timeLimit : 0}
                                        />
                                        <MoveBox boxPadding={"10px"} isMobile={false} height={4} levelType={levelType} moveLimit={puzzle.moveLimit ? puzzle.moveLimit : 0} />
                                    </Paper>
                                </div>
                                <div>
                                    <NextBox nextLevelCallBack={nextLevel} nextBoxText="Next Level" />
                                    <WordBox isMobile={false} vWheight={20} />
                                </div>
                            </div>
                        )}{" "}
                        {noMoreLevels && (
                            <div>
                                <div>Dang! You made it to the end of our puzzle list for now.</div>
                                <div>Hopefully you enjoyed it! Go yell at us to make more levels.</div>
                            </div>
                        )}
                    </PuzzleBoardProvider>
                </div>
            );
        }
    } else {
        return <LoadingWheel />;
    }
}
