"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const degrees = "180deg"; //180   //223
const firstColorPercentage = "67.71%"; //68.75   //13.3
const secondColorPercentage = "100%"; //100   //100
const switchBackgroundColors = false;
const switchAccentColor = false;
const colorOptions = [
    [
        "#176DD2",
        "#01237B",
        "#9BB7FF",
        "#09336D",
        "invert(66%) sepia(38%) saturate(679%) hue-rotate(193deg) brightness(102%) contrast(101%)",
        "invert(10%) sepia(56%) saturate(5363%) hue-rotate(216deg) brightness(97%) contrast(113%)",
    ],
    [
        "#34CBBC",
        "#207575",
        "#99FFF5",
        "#09524A",
        "invert(65%) sepia(90%) saturate(308%) hue-rotate(124deg) brightness(87%) contrast(98%)",
        "invert(28%) sepia(16%) saturate(4091%) hue-rotate(137deg) brightness(95%) contrast(101%)",
    ],
    [
        "#D7295C",
        "#9A002E",
        "#FF96B5",
        "#690D28",
        "invert(25%) sepia(61%) saturate(3072%) hue-rotate(324deg) brightness(87%) contrast(93%)",
        "invert(6%) sepia(89%) saturate(6748%) hue-rotate(335deg) brightness(97%) contrast(102%)",
    ],
    [
        "#ED9200",
        "#B87200",
        "#FFD695",
        "#794B03",
        "invert(32%) sepia(59%) saturate(4644%) hue-rotate(11deg) brightness(109%) contrast(92%)",
        "invert(41%) sepia(40%) saturate(1829%) hue-rotate(14deg) brightness(98%) contrast(101%)",
    ],
    [
        "#B121C5",
        "#700080",
        "#EF82FF",
        "#560C61",
        "invert(17%) sepia(88%) saturate(3397%) hue-rotate(285deg) brightness(100%) contrast(94%)",
        "invert(13%) sepia(51%) saturate(5996%) hue-rotate(286deg) brightness(84%) contrast(121%)",
    ],
    [
        "#F0530A",
        "#892B00",
        "#FFBEA0",
        "#772905",
        "invert(32%) sepia(59%) saturate(4644%) hue-rotate(11deg) brightness(109%) contrast(92%)",
        "invert(10%) sepia(98%) saturate(5765%) hue-rotate(42deg) brightness(98%) contrast(101%)",
    ],
    [
        "#03966A",
        "#00461C",
        "#95FEBF",
        "#025537",
        "invert(38%) sepia(81%) saturate(808%) hue-rotate(123deg) brightness(93%) contrast(98%)",
        "invert(15%) sepia(66%) saturate(2047%) hue-rotate(127deg) brightness(90%) contrast(101%)",
    ],
    [
        "#4F18ED",
        "#3A0073",
        "#C387FF",
        "#1F0756",
        "invert(12%) sepia(78%) saturate(6995%) hue-rotate(259deg) brightness(90%) contrast(108%)",
        "invert(8%) sepia(39%) saturate(7499%) hue-rotate(267deg) brightness(114%) contrast(117%)",
    ],
];
//Backup Using the main color pallete
// const colorOptions: string[][] = [
//   ["#F3638E", "#C0305B"],
//   ["#F03C72", "#C0305B"],
//   ["#4E96B1", "#1B637E"],
//   ["#227C9D", "#1B637E"],
//   ["#FFD592", "#FFCB77"],
//   ["#FFCB77", "#CCA25F"],
//   ["#5DD5C9", "#10897D"],
//   ["#C6C7C4", "#4F4F4F"],
// ];
//   background: "linear-gradient(223deg, #255ff7 13.3%, #648fff 88.66%)",
const tileColors = {
    //* Maybe try it out with 223deg or change the degrees*/
    tile0_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[0][1] : colorOptions[0][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[0][0] : colorOptions[0][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile0_accent: switchAccentColor ? colorOptions[0][0] : colorOptions[0][1],
    tile0_coreColor: colorOptions[0][0],
    tile0_terminalAccent: colorOptions[0][2],
    tile0_lockedAccent: colorOptions[0][3],
    tile0_cssFilterBackground: colorOptions[0][4],
    tile0_cssFilterAccent: colorOptions[0][5],
    tile1_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[1][1] : colorOptions[1][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[1][0] : colorOptions[1][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile1_accent: switchAccentColor ? colorOptions[1][0] : colorOptions[1][1],
    tile1_coreColor: colorOptions[1][0],
    tile1_terminalAccent: colorOptions[1][2],
    tile1_lockedAccent: colorOptions[1][3],
    tile1_cssFilterBackground: colorOptions[1][4],
    tile1_cssFilterAccent: colorOptions[1][5],
    tile2_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[2][1] : colorOptions[2][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[2][0] : colorOptions[2][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile2_accent: switchAccentColor ? colorOptions[2][0] : colorOptions[2][1],
    tile2_coreColor: colorOptions[2][0],
    tile2_terminalAccent: colorOptions[2][2],
    tile2_lockedAccent: colorOptions[2][3],
    tile2_cssFilterBackground: colorOptions[2][4],
    tile2_cssFilterAccent: colorOptions[2][5],
    tile3_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[3][1] : colorOptions[3][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[3][0] : colorOptions[3][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile3_accent: switchAccentColor ? colorOptions[3][0] : colorOptions[3][1],
    tile3_terminalAccent: colorOptions[3][2],
    tile3_coreColor: colorOptions[3][0],
    tile3_lockedAccent: colorOptions[3][3],
    tile3_hexBackground: colorOptions[3][0],
    tile3_hexAccent: colorOptions[3][1],
    tile3_cssFilterBackground: colorOptions[3][4],
    tile3_cssFilterAccent: colorOptions[3][5],
    tile4_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[4][1] : colorOptions[4][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[4][0] : colorOptions[4][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile4_accent: switchAccentColor ? colorOptions[4][0] : colorOptions[4][1],
    tile4_terminalAccent: colorOptions[4][2],
    tile4_coreColor: colorOptions[4][0],
    tile4_lockedAccent: colorOptions[4][3],
    tile4_hexBackground: colorOptions[4][0],
    tile4_hexAccent: colorOptions[4][1],
    tile4_cssFilterBackground: colorOptions[4][4],
    tile4_cssFilterAccent: colorOptions[4][5],
    tile5_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[5][1] : colorOptions[5][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[5][0] : colorOptions[5][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile5_accent: switchAccentColor ? colorOptions[5][0] : colorOptions[5][1],
    tile5_terminalAccent: colorOptions[5][2],
    tile5_coreColor: colorOptions[5][0],
    tile5_lockedAccent: colorOptions[5][3],
    tile5_cssFilterBackground: colorOptions[5][4],
    tile5_cssFilterAccent: colorOptions[5][5],
    tile6_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[6][1] : colorOptions[6][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[6][0] : colorOptions[6][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile6_accent: switchAccentColor ? colorOptions[6][0] : colorOptions[6][1],
    tile6_coreColor: colorOptions[6][0],
    tile6_terminalAccent: colorOptions[6][2],
    tile6_lockedAccent: colorOptions[6][3],
    tile6_cssFilterBackground: colorOptions[6][4],
    tile6_cssFilterAccent: colorOptions[6][5],
    tile7_background: "linear-gradient(" +
        degrees +
        ", " +
        (switchBackgroundColors ? colorOptions[7][1] : colorOptions[7][0]) +
        " " +
        firstColorPercentage +
        ", " +
        (switchBackgroundColors ? colorOptions[7][0] : colorOptions[7][1]) +
        " " +
        secondColorPercentage +
        ")",
    tile7_accent: switchAccentColor ? colorOptions[7][0] : colorOptions[7][1],
    tile7_coreColor: colorOptions[7][0],
    tile7_terminalAccent: colorOptions[7][2],
    tile7_lockedAccent: colorOptions[7][3],
    tile7_cssFilterBackground: colorOptions[7][4],
    tile7_cssFilterAccent: colorOptions[7][5],
};
exports.default = tileColors;
