"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFullDailyStats = void 0;
class UserFullDailyStats {
    constructor(bigHintsUsed, blanksLeft, checkAllsUsed, currentStreak, currentStreakPoints, littleHintsUsed, moveCount, score, timeTaken, userID, username) {
        this.bigHintsUsed = bigHintsUsed;
        this.blanksLeft = blanksLeft;
        this.checkAllsUsed = checkAllsUsed;
        this.currentStreak = currentStreak;
        this.currentStreakPoints = currentStreakPoints;
        this.littleHintsUsed = littleHintsUsed;
        this.moveCount = moveCount;
        this.score = score;
        this.timeTaken = timeTaken;
        this.userID = userID;
        this.username = username;
    }
}
exports.UserFullDailyStats = UserFullDailyStats;
