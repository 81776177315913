import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConvertSecondsToTimerDisplay, UserCareerDailyStreakStats, UserCareerTopDailyStats, UserPartialDailyStats } from "@summitrhode/shared";
import {
  DateRange,
  EventAvailable,
  LocalFireDepartment,
  MoveUp,
  Scoreboard,
  Tag,
  Timer,
  WorkspacePremium,
} from "@mui/icons-material";

interface Props {
  dailyStats: UserPartialDailyStats;
  careerDailyStreakStats: UserCareerDailyStreakStats;
  careerDailyStats: UserCareerTopDailyStats
  careerExpansionPackStats: {
    levelsComplete: number;
    packsComplete: number;
  };
  profileData: {
    firstName: string;
    lastName: string;
    username: string;
  };
}

export function ProfilePageOverview({
  dailyStats,
  careerDailyStats,
  careerDailyStreakStats,
  careerExpansionPackStats,
  profileData,
}: Props) {
  const [bestDailyDate, setBestDailyDate] = useState<string>("");

  useEffect(() => {
    setBestDailyDate(new Date(careerDailyStats.date).toLocaleDateString());
  }, []);

  return (
    <Box>
      <Box>
        <List>
          <ListSubheader>Today's Daily</ListSubheader>
          {dailyStats.score > 0 && (
            <>
              <ListItem>
                <ListItemIcon>
                  <Timer />
                  <Typography>
                    {"Time: " +
                      ConvertSecondsToTimerDisplay(dailyStats.timeTaken)}
                  </Typography>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <MoveUp />
                  <Typography>{"Moves: " + dailyStats.moveCount}</Typography>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Scoreboard />
                  <Typography>{"Score: " + dailyStats.score}</Typography>
                </ListItemIcon>
              </ListItem>
            </>
          )}
          {dailyStats.score <= 0 && (
            <Typography>
              {profileData.username} hasn't done today's puzzle yet!
            </Typography>
          )}
        </List>

        <Box>
          <List>
            <ListSubheader>All Time Stats</ListSubheader>
            <ListItem>
              <ListItemIcon>
                <EventAvailable />
                <Typography>
                  {"Current Streak: " +
                    careerDailyStreakStats.currentStreak +
                    " Days"}
                </Typography>
              </ListItemIcon>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DateRange />
                <Typography>
                  {"Longest Streak: " +
                    careerDailyStreakStats.longestStreak +
                    " Days"}
                </Typography>
              </ListItemIcon>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WorkspacePremium />
                {careerDailyStats.ranking && (
                  <Typography>
                    {
                      "Best Daily Ranking: #" +
                        careerDailyStats.ranking +
                        " of " +
                        careerDailyStats.totalPlayers +
                        " on " +
                        bestDailyDate
                      // + "(" +
                      // careerDailyStats.percentile +
                      // " percentile)"
                    }
                  </Typography>
                )}
                {careerDailyStats.ranking === undefined && (
                  <Typography>{"Haven't played a Daily puzzle yet"}</Typography>
                )}
              </ListItemIcon>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Tag />
                <Typography>
                  {careerExpansionPackStats.levelsComplete +
                    " Levels & " +
                    careerExpansionPackStats.packsComplete +
                    " Packs Completed"}
                </Typography>
              </ListItemIcon>
            </ListItem>

            {/* 
            TODO: Implement the Gauntlet leaderboard
            <ListItem sx={{ flexDirection: "column" }}>
              <ListItemIcon>
                <LocalFireDepartment />
                <Typography>
                  {"Best Gauntlet: 7 levels in 12:24 & 38 moves"}
                </Typography>
              </ListItemIcon>
              <Button variant="contained">Try for yourself</Button>
            </ListItem> */}
          </List>
        </Box>
      </Box>
    </Box>
  );
}
