import { Box } from "@mui/material";
import { Oval } from "react-loader-spinner";

import {colors} from "@summitrhode/shared/";

interface Props {}

export function LoadingWheel({}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Oval
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={1}
        color={colors.primary}
        secondaryColor="white"
      />
    </Box>
  );
}
