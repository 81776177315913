"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordGuess = void 0;
const models_1 = require("./");
const styles_1 = require("../styles");
class WordGuess extends models_1.Word {
    constructor(startingPiece, backgroundColor, accentColor, coreColor, terminalAccentColor, lockedAccentColor, textColor, cssFilterBackground, cssFilterAccent, wordIndex, time = "", wordState = models_1.WordState.SEARCHING, wordIcon = "Search") {
        super(startingPiece, wordIndex);
        this.startingPiece = startingPiece;
        this.backgroundColor = backgroundColor;
        this.accentColor = accentColor;
        this.coreColor = coreColor;
        this.terminalAccentColor = terminalAccentColor;
        this.lockedAccentColor = lockedAccentColor;
        this.textColor = textColor;
        this.cssFilterBackground = cssFilterBackground;
        this.cssFilterAccent = cssFilterAccent;
        this.time = time;
        this.wordState = wordState;
        this.wordIcon = wordIcon;
        this.isLocked = false;
        this.isClearable = true;
        this.wordGuessKey = wordIndex.toString();
        startingPiece.addToWordGuess(this);
    }
    /**
     * Update piece to match the wordguess color and index
     * Push the piece to the PieceArray
     * @param piece
     */
    addPiece(piece) {
        this.pieceArray.push(piece);
        piece.addToWordGuess(this);
        this.setLastPieceAnchorColor();
    }
    /**
     * Sets the final piece of wordguess to be a standout color
     * to signify the anchor
     */
    setLastPieceAnchorColor() {
        this.pieceArray.forEach((piece, index) => {
            if (this.isLocked || piece.isLocked) {
                piece.accentColor = this.lockedAccentColor;
                piece.textColor = styles_1.colors.grayscaleShade;
                piece.arrowFillColor = styles_1.colors.grayscaleShade;
                piece.setIsClickable(false);
                this.isClearable = false;
            }
            else if (index === this.pieceArray.length - 1) {
                piece.accentColor = this.terminalAccentColor;
            }
            else {
                piece.accentColor = this.accentColor;
            }
        });
    }
    /**
     * Sets the wordguess to be locked from user interaction
     * Should be when the word is correct on easier levels or
     * the hints were used
     */
    setIsLocked(isLocked) {
        this.isLocked = isLocked;
    }
    /**
     * Sets the wordguess ability to be cleared from
     * the word bank
     */
    setIsClearable(isClearable) {
        this.isClearable = isClearable;
    }
    /**
     * Sets the state of the word to identify if it matches the answers and
     * which icon to show next to the word
     */
    setState(state) {
        switch (state) {
            case models_1.WordState.SEARCHING:
                this.wordState = models_1.WordState.SEARCHING;
                break;
            case models_1.WordState.RIGHT_WORD_WRONG_LETTERS:
                this.wordIcon =
                    "PriorityHighIcon style={{ color: " + this.accentColor + "}}";
                this.wordState = models_1.WordState.RIGHT_WORD_WRONG_LETTERS;
                break;
            case models_1.WordState.RIGHT_WORD_WRONG_BLANKS:
                this.wordIcon = "CheckIcon style={{ color: " + this.accentColor + "}}";
                this.wordState = models_1.WordState.RIGHT_WORD_WRONG_BLANKS;
                break;
            case models_1.WordState.RIGHT_WORD:
                this.wordIcon = "CheckIcon style={{ color: " + this.accentColor + "}}";
                this.wordState = models_1.WordState.RIGHT_WORD;
                break;
            case models_1.WordState.RIGHT_WORD_EXACT_MATCH:
                this.wordIcon =
                    "VerifiedIcon style={{ color: " + this.accentColor + "}}";
                this.wordState = models_1.WordState.RIGHT_WORD_EXACT_MATCH;
                break;
            default:
                throw new Error(`WordState ${state} does not exist`);
        }
    }
    /**
     * Pops the end of the piece array up to the given index
     * EX: piece array is [d,e,m,o,c,r,i,t,u,s]
     * Slicing at piece 'm' would lead to [d,e,m]
     * This is because m is piece index 2. Slicing at 3 (index + 1)
     * turns all letters after m into Nodes
     * @param index
     * @returns
     */
    slicePieceArray(index) {
        for (let piece of this.pieceArray.slice(index + 1)) {
            piece.setState(models_1.PieceState.NODE);
        }
        this.pieceArray = this.pieceArray.slice(0, index + 1);
        this.setLastPieceAnchorColor();
        return;
    }
    /**
     * Turn every piece in the array back into a node
     * cleanPieceArray() should be called in conjunction with a function
     * that removes this wordGuess from the wordGuess[]
     */
    cleanPieceArray() {
        for (let piece of this.pieceArray) {
            piece.setState(models_1.PieceState.NODE);
            piece.setIsClickable(true);
        }
    }
}
exports.WordGuess = WordGuess;
