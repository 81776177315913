"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cognito = void 0;
const RDS_1 = require("../RDS");
const amazon_cognito_identity_js_1 = require("../../node_modules/amazon-cognito-identity-js");
// import path from "path";
// import dotenv from "dotenv"
// let dotenvPath = path.join(__dirname, '../../../.config/.env')
// dotenv.config({ path: dotenvPath });
const userPoolId = "us-east-1_M4ChwHdzo";
const clientId = "process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID";
const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
};
const userPool = new amazon_cognito_identity_js_1.CognitoUserPool(poolData);
class Cognito {
    static getCurrentUser() {
        (0, RDS_1.configureAmplify)();
        let currentUser = userPool.getCurrentUser();
        if (currentUser == null)
            throw Error("no current user");
        return currentUser;
    }
    static getCognitoUser(username) {
        const userData = {
            Username: username,
            Pool: userPool,
        };
        return new amazon_cognito_identity_js_1.CognitoUser(userData);
    }
    static async getSession() {
        return new Promise(function (resolve, reject) {
            let currentUser = Cognito.getCurrentUser();
            currentUser.getSession(function (err, session) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(session);
                }
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async signUpUserWithEmail(username, email, password) {
        return new Promise(function (resolve, reject) {
            const attributeList = [
                new amazon_cognito_identity_js_1.CognitoUserAttribute({
                    Name: "email",
                    Value: email,
                }),
            ];
            userPool.signUp(username, password, attributeList, [], function (err, res) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(res);
                }
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async verifyCode(username, code) {
        return new Promise(function (resolve, reject) {
            const cognitoUser = Cognito.getCognitoUser(username);
            cognitoUser.confirmRegistration(code, true, function (err, result) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(result);
                }
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async signInWithEmail(username, password) {
        return new Promise(function (resolve, reject) {
            const authenticationData = {
                Username: username,
                Password: password,
            };
            const authenticationDetails = new amazon_cognito_identity_js_1.AuthenticationDetails(authenticationData);
            let currentUser = Cognito.getCognitoUser(username);
            currentUser.authenticateUser(authenticationDetails, {
                onSuccess: function (res) {
                    resolve(res);
                },
                onFailure: function (err) {
                    reject(err);
                },
            });
        }).catch((err) => {
            throw err;
        });
    }
    static signOut() {
        let currentUser = this.getCurrentUser();
        if (currentUser) {
            currentUser.signOut();
        }
    }
    static async getAttributes() {
        return new Promise(function (resolve, reject) {
            let currentUser = Cognito.getCurrentUser();
            currentUser.getUserAttributes(function (err, attributes) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(attributes);
                }
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async setAttribute(attribute) {
        return new Promise(function (resolve, reject) {
            const attributeList = [];
            const res = new amazon_cognito_identity_js_1.CognitoUserAttribute(attribute);
            attributeList.push(res);
            let currentUser = Cognito.getCurrentUser();
            currentUser.updateAttributes(attributeList, (err, res) => {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(res);
                }
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async sendCode(username) {
        return new Promise(function (resolve, reject) {
            const cognitoUser = Cognito.getCognitoUser(username);
            if (!cognitoUser) {
                reject(`could not find ${username}`);
                return;
            }
            cognitoUser.forgotPassword({
                onSuccess: function (res) {
                    resolve(res);
                },
                onFailure: function (err) {
                    reject(err);
                },
            });
        }).catch((err) => {
            throw err;
        });
    }
    static async forgotPassword(username, code, password) {
        return new Promise(function (resolve, reject) {
            const cognitoUser = Cognito.getCognitoUser(username);
            if (!cognitoUser) {
                reject(`could not find ${username}`);
                return;
            }
            cognitoUser.confirmPassword(code, password, {
                onSuccess: function () {
                    resolve("password updated");
                },
                onFailure: function (err) {
                    reject(err);
                },
            });
        });
    }
    static async changePassword(oldPassword, newPassword) {
        return new Promise(function (resolve, reject) {
            let currentUser = Cognito.getCurrentUser();
            currentUser.changePassword(oldPassword, newPassword, function (err, res) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(res);
                }
            });
        });
    }
}
exports.Cognito = Cognito;
