"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = void 0;
class Level {
    constructor(campaignId, levelId, levelNumber, name, subtitle) {
        this.campaignId = campaignId;
        this.levelId = levelId;
        this.levelNumber = levelNumber;
        this.name = name;
        this.subtitle = subtitle;
    }
}
exports.Level = Level;
