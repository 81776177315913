"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    primary: "#F03C72",
    primaryTint: "#fca9c2",
    primaryShade: "#C0305B",
    accent: "#227C9D",
    accentTint: "#4E96B1",
    accentShade: "#1B637E",
    warning: "#FFCB77",
    waningTint: "#FFD592",
    warningShade: "#CCA25F",
    grayscale: "#1F232B",
    grayscaleTint: "#E6E7E7",
    grayscaleShade: "#CCCCCC",
    secondary: "#17C3B2",
    secondaryTint: "#5DD5C9",
    secondaryShade: "#10897D",
    tertiary: "#FEF9EF",
    tertiaryTint: "#FEFAF2",
    tertiaryShade: "#CBC7BF",
    typographyBorder: "#F2F0F0",
    typographyText: "#1F6782",
    typographyTextShadow: "rgba(233, 233, 233, 0.75)",
    black: "#000000",
    white: "#FFFFFF",
};
