"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminWord = void 0;
class AdminWord {
    constructor(wordId, name, wcx_id, color, nameWithCount, checked, duplicateLevels) {
        this.wordId = wordId;
        this.name = name;
        this.wcx_id = wcx_id;
        this.color = color;
        this.nameWithCount = nameWithCount;
        this.checked = checked;
        this.duplicateLevels = duplicateLevels;
    }
}
exports.AdminWord = AdminWord;
