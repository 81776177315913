"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const models_1 = require("../../models");
function CheckWordsFilled(activeWords, puzzleWords, subCategoryWords, dictionary) {
    let inAllPuzzleWords = true;
    //Check if the words are in the puzzle words
    for (let activeWord of activeWords) {
        if (puzzleWords.find((w) => w !== activeWord.getWord())) {
            inAllPuzzleWords = false;
        }
        if (inAllPuzzleWords)
            return models_1.GameState.COMPLETE;
        return models_1.GameState.PLAYING;
    }
}
exports.default = CheckWordsFilled;
