interface Props {
  fillColor: string;
  accentColor: string;
  outterWidth: string;
}

export function RightArrow({ fillColor, accentColor, outterWidth }: Props) {
  return (
    <svg
      width={outterWidth}
      height={outterWidth}
      viewBox="0 0 604 663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
    d="M601.104 266.747C628.584 282.5 628.584 322.135 601.104 337.887L268.545 528.527C241.212 544.195 207.154 524.462 207.154 492.957L207.154 111.678C207.154 80.1719 241.212 60.4389 268.545 76.1076L601.104 266.747Z"
    fill={accentColor}
      />

      <rect
        x="359.154"
        y="213.477"
        width="177"
        height="358.628"
        rx="62"
        transform="rotate(90 359.154 213.477)"
        fill={accentColor}
      />
      <path
    d="M579.671 271.29C603.8 285.122 603.8 319.923 579.671 333.755L275.558 508.087C251.558 521.845 221.654 504.518 221.654 476.855V128.19C221.654 100.527 251.558 83.2002 275.558 96.9581L579.671 271.29Z"
    fill={fillColor}
      />
      <rect
         x="316.154"
         y="229.477"
         width="146.584"
         height="297"
         rx="45"
         transform="rotate(90 316.154 229.477)"
        fill={fillColor}
      />
    </svg>
  );
}

