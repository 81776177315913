"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserGauntletInstance = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
async function GetUserGauntletInstance() {
    const user = await (0, auth_1.fetchAuthSession)();
    let path = "/items/getUserGauntletInstance?sub=" + user.userSub;
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
    const response = await restOperation.response;
    return JSON.parse(await response.body.text());
}
exports.GetUserGauntletInstance = GetUserGauntletInstance;
