import ApiIcon from "@mui/icons-material/Api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { Badge, Box, Paper, Tooltip } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import StartIcon from "@mui/icons-material/Start";

import { colors, componentColors } from "@summitrhode/shared";
import { Sos } from "@mui/icons-material";

interface Props {
    engageBigHint: (hintWasUsed: boolean) => void;
    bigHintEnabled: boolean;
    bigHintEngaged: boolean;
    bigHintsRemaining: number;
    engageLittleHint: (hintWasUsed: boolean) => void;
    littleHintEnabled: boolean;
    littleHintEngaged: boolean;
    littleHintsRemaining: number;
    engageCheckAll: (hintWasUsed: boolean) => void;
    checkAllEnabled: boolean;
    checkAllEngaged: boolean;
    checkAllsRemaining: number;
    engageRevealPuzzle: (hintWasUsed: boolean) => void;
    revealPuzzleEnabled: boolean;
    revealPuzzleEngaged: boolean;
    revealPuzzlesRemaining: number;
    isMobile: boolean;
}

export function HintsBox({
    engageBigHint,
    bigHintEnabled,
    bigHintEngaged,
    bigHintsRemaining,
    engageLittleHint,
    littleHintEnabled,
    littleHintEngaged,
    littleHintsRemaining,
    engageCheckAll,
    checkAllEnabled,
    checkAllEngaged,
    checkAllsRemaining,
    engageRevealPuzzle,
    revealPuzzleEnabled,
    revealPuzzleEngaged,
    revealPuzzlesRemaining,
    isMobile,
}: Props) {
    const enabledColor = colors.primary;
    const disabledColor = colors.accent;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
                justifyContent: "center",
                margin: isMobile ? ".75vh" : "1vh",
                paddingY: isMobile ? "2vw" : "",
                paddingX: isMobile ? "2vw" : "",
                maxWidth: isMobile ? "4vh" : "50px",
                // width: "100%",
            }}
        >
            <Tooltip title={"Click on any of the tiles to reveal the first or next letter in the PathWord!"} open={littleHintEngaged}>
                <Paper
                    elevation={5}
                    sx={{
                        // backgroundColor: props.littleHintEnabled
                        //   ? enabledColor
                        //   : disabledColor,
                        height: isMobile ? "5vh" : "50px",
                        fontSize: isMobile ? "1.4vh" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        marginY: isMobile ? "" : ".5vh",
                        marginX: isMobile ? "2vw" : "",
                        // maxWidth: isMobile ? "4vh" : "50px",
                        // paddingX: isMobile ? "2vw" : "",
                    }}
                >
                    <Badge badgeContent={littleHintEnabled ? littleHintsRemaining : "0"} color="primary">
                        <Button
                            sx={{
                                background: littleHintEngaged ? componentColors.primary.backgroundColor : componentColors.accent.backgroundColor,
                            }}
                            // color={littleHintEngaged ? "primary" : "secondary"}
                            disabled={!littleHintEnabled}
                            onClick={() => engageLittleHint(false)}
                            variant="contained"
                        >
                            <StartIcon
                                sx={{
                                    fontSize: isMobile ? "3vh" : "40px",
                                    marginRight: isMobile ? ".4vw" : "5px",
                                }}
                            />
                            {/* <div>Next Tile ({littleHintsRemaining})</div> */}
                        </Button>
                    </Badge>
                </Paper>
            </Tooltip>
            <Tooltip title={"Click on any of the tiles to reveal the whole PathWord it's in!"} open={bigHintEngaged} sx={{ textAlign: "center" }}>
                <Paper
                    elevation={5}
                    sx={{
                        // backgroundColor: props.littleHintEnabled
                        //   ? enabledColor
                        //   : disabledColor,
                        height: isMobile ? "5vh" : "50px",
                        fontSize: isMobile ? "1.4vh" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        marginY: isMobile ? "" : ".5vh",
                        marginX: isMobile ? "2vw" : "",
                        // paddingX: isMobile ? "2vw" : "",
                    }}
                >
                    <Badge badgeContent={bigHintEnabled ? bigHintsRemaining : "0"} color="primary">
                        <Button
                            // sx={{
                            //   // backgroundColor: props.bigHintEnabled ? enabledColor : disabledColor,

                            //   height: isMobile ? "5vh" : "50px",
                            //   fontSize: isMobile ? "1.4vh" : "15px",
                            //   display: "flex",
                            //   justifyContent: "center",
                            //   marginY: isMobile ? "" : ".5vh",
                            //   marginX: isMobile ? ".5vw" : "",
                            // }}
                            sx={{
                                background: bigHintEngaged ? componentColors.primary.backgroundColor : componentColors.accent.backgroundColor,
                            }}
                            //color={bigHintEngaged ? "primary" : "secondary"}
                            disabled={!bigHintEnabled}
                            onClick={() => engageBigHint(false)}
                            variant="contained"
                        >
                            <VisibilityIcon
                                sx={{
                                    fontSize: isMobile ? "3vh" : "40px",
                                    marginRight: isMobile ? ".4vw" : "5px",
                                }}
                            />
                            {/* <div>Whole PathWord ({bigHintsRemaining})</div> */}
                        </Button>
                    </Badge>
                </Paper>
            </Tooltip>
            <Tooltip title={"Click on any tile and it'll check all your PathWords to clean up anything not headed in the right direction!"} open={checkAllEngaged} sx={{ textAlign: "center" }}>
                <Paper
                    elevation={5}
                    sx={{
                        // backgroundColor: props.littleHintEnabled
                        //   ? enabledColor
                        //   : disabledColor,
                        height: isMobile ? "5vh" : "50px",
                        fontSize: isMobile ? "1.4vh" : "15px",
                        display: "flex",
                        justifyContent: "center",
                        marginY: isMobile ? "" : ".5vh",
                        marginX: isMobile ? "2vw" : "",
                        // paddingX: isMobile ? "2vw" : "",
                    }}
                >
                    <Badge badgeContent={checkAllEnabled ? checkAllsRemaining : "0"} color="primary">
                        <Button
                            // sx={{
                            //   // backgroundColor: props.bigHintEnabled ? enabledColor : disabledColor,

                            //   height: isMobile ? "5vh" : "50px",
                            //   fontSize: isMobile ? "1.4vh" : "15px",
                            //   display: "flex",
                            //   justifyContent: "center",
                            //   marginY: isMobile ? "" : ".5vh",
                            //   marginX: isMobile ? ".5vw" : "",
                            // }}
                            sx={{
                                background: checkAllEngaged ? componentColors.primary.backgroundColor : componentColors.accent.backgroundColor,
                            }}
                            //color={checkAllEngaged ? "primary" : "secondary"}
                            disabled={!checkAllEnabled}
                            onClick={() => engageCheckAll(false)}
                            variant="contained"
                        >
                            <DoneAllIcon
                                sx={{
                                    fontSize: isMobile ? "3vh" : "40px",
                                    marginRight: isMobile ? ".4vw" : "5px",
                                }}
                            />
                            {/* <div> ({checkAllsRemaining})</div> */}
                        </Button>
                    </Badge>
                </Paper>
            </Tooltip>
            {revealPuzzleEnabled && (
                <Tooltip title={"Click on any tile if you want to give up on today's daily to see the answers! Tap this again to turn it off!"} open={revealPuzzleEngaged} sx={{ textAlign: "center" }}>
                    <Paper
                        elevation={5}
                        sx={{
                            // backgroundColor: props.littleHintEnabled
                            //   ? enabledColor
                            //   : disabledColor,
                            height: isMobile ? "5vh" : "50px",
                            fontSize: isMobile ? "1.4vh" : "15px",
                            display: "flex",
                            justifyContent: "center",
                            marginY: isMobile ? "" : ".5vh",
                            marginX: isMobile ? "2vw" : "",
                            // paddingX: isMobile ? "2vw" : "",
                        }}
                    >
                        <Badge badgeContent={revealPuzzleEnabled ? revealPuzzlesRemaining : "0"} color="primary">
                            <Button
                                // sx={{
                                //   // backgroundColor: props.bigHintEnabled ? enabledColor : disabledColor,

                                //   height: isMobile ? "5vh" : "50px",
                                //   fontSize: isMobile ? "1.4vh" : "15px",
                                //   display: "flex",
                                //   justifyContent: "center",
                                //   marginY: isMobile ? "" : ".5vh",
                                //   marginX: isMobile ? ".5vw" : "",
                                // }}
                                sx={{
                                    background: revealPuzzleEngaged ? componentColors.primary.backgroundColor : componentColors.accent.backgroundColor,
                                }}
                                //color={revealPuzzleEngaged ? "primary" : "secondary"}
                                disabled={!revealPuzzleEnabled}
                                onClick={() => engageRevealPuzzle(false)}
                                variant="contained"
                            >
                                <Sos
                                    sx={{
                                        fontSize: isMobile ? "3vh" : "40px",
                                        marginRight: isMobile ? ".4vw" : "5px",
                                    }}
                                />
                                {/* <div> ({checkAllsRemaining})</div> */}
                            </Button>
                        </Badge>
                    </Paper>
                </Tooltip>
            )}
        </Box>
    );
}
