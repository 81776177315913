"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerCoordinateDetail = void 0;
class AnswerCoordinateDetail {
    constructor(answerCoordinate, answerCoordinateIndexId) {
        this.answerCoordinate = answerCoordinate;
        this.answerCoordinateIndexId = answerCoordinateIndexId;
    }
}
exports.AnswerCoordinateDetail = AnswerCoordinateDetail;
