"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const styles_1 = require("../../node_modules/@mui/material/styles");
const _1 = require("./");
const componentColors_1 = require("./componentColors");
const { palette } = (0, styles_1.createTheme)();
exports.theme = (0, styles_1.createTheme)({
    palette: {
        primary: {
            main: componentColors_1.componentColors.primary.coreColor,
        },
        secondary: { main: componentColors_1.componentColors.accent.coreColor },
        warning: { main: _1.colors.warning },
        tertiary: palette.augmentColor({ color: { main: _1.colors.tertiaryShade } }),
        grayscale: palette.augmentColor({ color: { main: _1.colors.grayscale } }),
    },
    typography: {
        fontFamily: "sans-serif",
        button: {
            textTransform: "none",
        },
    },
    components: {
    // MuiTypography: {
    //   styleOverrides: {
    //     h1: {
    //       fontSize: "30vh",
    //     },
    //     h4: { fontSize: "2.5vh" },
    //     h5: { fontSize: "2vh" },
    //     body1: { fontSize: "2vh" },
    // },
    // },
    },
});
