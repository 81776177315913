"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFriendRequest = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
async function handleFriendRequest(requesterID, addresseeID, status) {
    let path = "/items/handleFriendRequest";
    const user = await (0, auth_1.fetchAuthSession)();
    let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
        userID: user.userSub,
        requesterID,
        addresseeID,
        status,
    }));
    const response = await restOperation.response;
    console.debug("Friend Request handled with result: ", response);
    return;
}
exports.handleFriendRequest = handleFriendRequest;
