"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderExpansionPacks = void 0;
function OrderExpansionPacks(expansionPacks) {
    const returnArray = [];
    // Put Easy, Medium, Hard, Expert up front
    let index = expansionPacks.findIndex((e) => e.id === 11);
    returnArray.push(expansionPacks.splice(index, 1)[0]);
    index = expansionPacks.findIndex((e) => e.id === 12);
    returnArray.push(expansionPacks.splice(index, 1)[0]);
    index = expansionPacks.findIndex((e) => e.id === 3);
    returnArray.push(expansionPacks.splice(index, 1)[0]);
    index = expansionPacks.findIndex((e) => e.id === 13);
    returnArray.push(expansionPacks.splice(index, 1)[0]);
    // Order remaining packs alphabetically
    expansionPacks.sort((a, b) => a.name.localeCompare(b.name));
    expansionPacks.forEach((e) => returnArray.push(e));
    return returnArray;
}
exports.OrderExpansionPacks = OrderExpansionPacks;
