export const cleanStringEntry = (stringEntry: string) => {
  stringEntry = stringEntry.replaceAll(" ", "");
  stringEntry = stringEntry.replaceAll("'", "");
  stringEntry = stringEntry.replaceAll("@", "");
  stringEntry = stringEntry.replaceAll("=", "");
  stringEntry = stringEntry.replaceAll(".", "");
  // stringEntry = stringEntry.replaceAll("/", "");
  stringEntry = stringEntry.replaceAll("\\", "");
  stringEntry = stringEntry.replaceAll("#", "");
  stringEntry = stringEntry.replaceAll("?", "");
  stringEntry = stringEntry.replaceAll("(", "");
  stringEntry = stringEntry.replaceAll(")", "");
  stringEntry = stringEntry.replaceAll("[", "");
  stringEntry = stringEntry.replaceAll("]", "");
  stringEntry = stringEntry.replaceAll("!", "");
  stringEntry = stringEntry.replaceAll("&", "");
  stringEntry = stringEntry.replaceAll("*", "");
  stringEntry = stringEntry.replaceAll("$", "");
  stringEntry = stringEntry.replaceAll("%", "");
  stringEntry = stringEntry.replaceAll("^", "");
  stringEntry = stringEntry.replaceAll(";", "");
  return stringEntry;
};

export const cleanUserEntry = (stringEntry: string) => {
  stringEntry = stringEntry.replaceAll("'", "");
  stringEntry = stringEntry.replaceAll("@", "");
  stringEntry = stringEntry.replaceAll("=", "");
  stringEntry = stringEntry.replaceAll(".", "");
  // stringEntry = stringEntry.replaceAll("/", "");
  stringEntry = stringEntry.replaceAll("\\", "");
  stringEntry = stringEntry.replaceAll("#", "");
  stringEntry = stringEntry.replaceAll("?", "");
  stringEntry = stringEntry.replaceAll("(", "");
  stringEntry = stringEntry.replaceAll(")", "");
  stringEntry = stringEntry.replaceAll("[", "");
  stringEntry = stringEntry.replaceAll("]", "");
  stringEntry = stringEntry.replaceAll("!", "");
  stringEntry = stringEntry.replaceAll("&", "");
  stringEntry = stringEntry.replaceAll("*", "");
  stringEntry = stringEntry.replaceAll("$", "");
  stringEntry = stringEntry.replaceAll("%", "");
  stringEntry = stringEntry.replaceAll("^", "");
  stringEntry = stringEntry.replaceAll(";", "");
  return stringEntry;
};

export const cleanPuzzleNameEntry = (stringEntry: string) => {
  stringEntry = stringEntry.replaceAll('"', "'");
  stringEntry = stringEntry.replaceAll(";", "");
  // stringEntry = stringEntry.replaceAll("@", "");
  // stringEntry = stringEntry.replaceAll("=", "");
  // stringEntry = stringEntry.replaceAll(".", "");
  // // stringEntry = stringEntry.replaceAll("/", "");
  // stringEntry = stringEntry.replaceAll("\\", "");
  // stringEntry = stringEntry.replaceAll("#", "");
  // stringEntry = stringEntry.replaceAll("?", "");
  // stringEntry = stringEntry.replaceAll("(", "");
  // stringEntry = stringEntry.replaceAll(")", "");
  // stringEntry = stringEntry.replaceAll("[", "");
  // stringEntry = stringEntry.replaceAll("]", "");
  // stringEntry = stringEntry.replaceAll("!", "");
  // stringEntry = stringEntry.replaceAll("&", "");
  // stringEntry = stringEntry.replaceAll("*", "");
  // stringEntry = stringEntry.replaceAll("$", "");
  // stringEntry = stringEntry.replaceAll("%", "");
  // stringEntry = stringEntry.replaceAll("^", "");
  return stringEntry;
};

export const cleanExpansionPackDetails = (stringEntry: string) => {
  stringEntry = stringEntry.replaceAll('"', "'");
  stringEntry = stringEntry.replaceAll(";", "");
  // stringEntry = stringEntry.replaceAll("@", "");
  // stringEntry = stringEntry.replaceAll("=", "");
  // stringEntry = stringEntry.replaceAll(".", "");
  // // stringEntry = stringEntry.replaceAll("/", "");
  // stringEntry = stringEntry.replaceAll("\\", "");
  // stringEntry = stringEntry.replaceAll("#", "");
  // stringEntry = stringEntry.replaceAll("?", "");
  // stringEntry = stringEntry.replaceAll("(", "");
  // stringEntry = stringEntry.replaceAll(")", "");
  // stringEntry = stringEntry.replaceAll("[", "");
  // stringEntry = stringEntry.replaceAll("]", "");
  // stringEntry = stringEntry.replaceAll("!", "");
  // stringEntry = stringEntry.replaceAll("&", "");
  // stringEntry = stringEntry.replaceAll("*", "");
  // stringEntry = stringEntry.replaceAll("$", "");
  // stringEntry = stringEntry.replaceAll("%", "");
  // stringEntry = stringEntry.replaceAll("^", "");
  return stringEntry;
};
