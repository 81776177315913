"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPuzzleByLevelNumber = void 0;
const models_1 = require("../../models");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const LevelDetails_1 = require("../../models/LevelDetails/LevelDetails");
const LevelWord_1 = require("../../models/Puzzle/LevelWord");
async function GetPuzzleByLevelNumber(levelType, levelNumber, date, expansionPackId, gauntletPackId) {
    let additionalString = "";
    let levelDetails;
    const tempLevelType = levelType === models_1.LevelTypes.TUTORIAL ? models_1.LevelTypes.EXPANSION : levelType;
    if (levelNumber) {
        levelNumber = levelNumber > 1 ? levelNumber : 1;
        additionalString += "&levelNumber=" + levelNumber;
    }
    if (date)
        additionalString += "&date=" + date;
    if (expansionPackId)
        additionalString += "&expansionPackId=" + expansionPackId;
    if (gauntletPackId)
        additionalString += "&gauntletPackId=" + gauntletPackId;
    let url = "/items/getLevel?levelType=" + models_1.LevelTypes[tempLevelType] + additionalString;
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(url));
    const response = await restOperation.response;
    const jsonBody = await response.body.text();
    if (jsonBody == undefined)
        throw new Error("No puzzle found");
    try {
        let json = JSON.parse(jsonBody).levelDetails;
        levelDetails = new LevelDetails_1.LevelDetails(json.answerCoordinateDetails, json.letterDetails, json.levelDetails[0], json.wordDetails);
    }
    catch {
        throw new Error(`Could not deserialize: ${jsonBody}`);
    }
    let letters = levelDetails.letterDetails.map((x) => x.letterValue);
    let answerCoordinates = levelDetails.answerCoordinateDetails.map((x) => x.answerCoordinate);
    let level = levelDetails.levelMetaData;
    let words = levelDetails.wordDetails.map((x) => new LevelWord_1.LevelWord(x.wordId, x.name));
    let levelName = level.levelName ? level.levelName : "";
    let levelSubtitle = level.levelSubtitle ? level.levelSubtitle : "";
    let timeLimit = undefined;
    let moveLimit = undefined;
    if (levelType === models_1.LevelTypes.GAUNTLET) {
        levelName = level.parentCategory;
        levelSubtitle = level.subCategory;
        timeLimit = level.timeLimit;
        moveLimit = level.moveLimit;
    }
    let levelNumberString;
    if (levelType === models_1.LevelTypes.DAILY) {
        let dateFormat = level.date.slice(0, level.date.indexOf("T"));
        levelNumberString = dateFormat.replaceAll("-", "/");
    }
    else {
        levelNumberString = levelNumber;
    }
    return new models_1.Puzzle(letters, answerCoordinates, level.parentCategory, level.subCategory, level.numRows, level.numColumns, level.difficulty, false, levelName, levelSubtitle, words, level.parentCategoryId, level.subCategoryId, levelNumberString, level.levelId, levelType, timeLimit, moveLimit);
}
exports.GetPuzzleByLevelNumber = GetPuzzleByLevelNumber;
