"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpansionPack = void 0;
class ExpansionPack {
    constructor(id, name, description, levelNumber, numberOfLevels, levels, isAvailableToUsers, isAvailableToAdmins) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.levelNumber = levelNumber;
        this.numberOfLevels = numberOfLevels;
        this.levels = levels;
        this.isAvailableToUsers = isAvailableToUsers;
        this.isAvailableToAdmins = isAvailableToAdmins;
    }
}
exports.ExpansionPack = ExpansionPack;
