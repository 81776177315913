import { Box } from "@mui/material";

import { ModuleCard } from "./ModuleCard";
// import MyPacksImage from "../../assets/HomePage/MyPacks.jpg";
import { AccountBox, Collections, Games, Group, Settings, Store } from "@mui/icons-material";

interface Props {
    isMobile: boolean;
    pendingFriendRequests: number;
}

enum Sizes {
    Full,
    Half,
    Quarter,
}

export function ModuleCards({ isMobile, pendingFriendRequests }: Props) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: "2%",
                width: isMobile ? "auto" : "100%",
                maxWidth: isMobile ? "auto" : "800px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "2%",
                    //   width: "100%",
                }}
            >
                <ModuleCard
                    enabled={true}
                    key={"packs"}
                    title="My Packs"
                    icon={<Collections fontSize="medium" />}
                    isMobile={isMobile}
                    // image={MyPacksImage}
                    path="/packs"
                    size={Sizes.Full}
                />
                <ModuleCard enabled={false} key={"store"} title="Store" icon={<Store fontSize="small" />} isMobile={isMobile} image={"coming soon"} path="/" size={Sizes.Full} />
            </Box>
            <Box
                sx={{
                    display: "flex",

                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "2%",
                    // width: "100%",
                }}
            >
                <ModuleCard enabled={true} key={"gauntlet"} title="Gauntlet" icon={<Games fontSize="small" />} isMobile={isMobile} image={"coming soon"} path="/gauntlet" size={Sizes.Full} />

                <Box
                    sx={{
                        flexDirection: "column",
                        width: "49%",
                        maxHeight: "150px",
                        justifyItems: "space-between",
                    }}
                >
                    <ModuleCard enabled={true} key={"profile"} icon={<AccountBox fontSize="small" />} title="My Profile" isMobile={isMobile} path="/profile" size={Sizes.Half} />

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            margin: "2%",
                        }}
                    >
                        <ModuleCard enabled={true} key={"friends"} icon={<Group fontSize="small" />} indicatorCount={pendingFriendRequests} isMobile={isMobile} path="/friends" size={Sizes.Quarter} />
                        <ModuleCard enabled={false} key={"settings"} icon={<Settings fontSize="small" />} isMobile={isMobile} path="/" size={Sizes.Quarter} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
