import React, { useEffect, useState } from "react";

import { NextBox } from ".";
import { ConvertSecondsToTimerDisplay, Difficulty } from "@summitrhode/shared";
import { Box, Button, Fade, Modal, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "@summitrhode/shared";
import { useLevelContext } from "../../context/levelContext";

interface Props {
    parentCategory: string;
    subCategory: string;
    timeLimit: number;
    moveLimit: number;
    difficulty: Difficulty;
    startLevel: () => void;
    hideModal: () => void;
    isMobile: boolean;
}

export default function GauntletIntroModal({ parentCategory, subCategory, timeLimit, moveLimit, difficulty, startLevel, hideModal, isMobile }: Props) {
    const navigate = useNavigate();
    const { currentGauntletLevel } = useLevelContext();

    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("Level Complete!");

    const mobileLabelSize = "2.5vh";
    const mobileValueSize = "3vh";
    const webLabelSize = "20px";
    const webValueSize = "25px";

    useEffect(() => {
        if (currentGauntletLevel) {
            setModalTitle("Gauntlet Pack: " + currentGauntletLevel.gauntletPackId + " / Level: " + currentGauntletLevel.levelNumber);
        }
        handleShow();
    }, []);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const startLevelClicked = () => {
        hideModal();
        handleClose();
        startLevel();
    };

    const goHomeClicked = () => {
        hideModal();
        navigate("/");
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={show}>
            <Fade in={show}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "3px",
                            borderBottom: "1px solid grey",
                        }}
                    >
                        <Typography id="spring-modal-title" variant="h6" component="h2">
                            {modalTitle}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderBottom: "1px solid grey",
                        }}
                    >
                        <Paper key={"info"} elevation={2} sx={{ justifyContent: "center", minWidth: "150px" }}>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? mobileLabelSize : webLabelSize,
                                }}
                                textAlign={"center"}
                            >
                                Category: {parentCategory}
                            </Typography>
                            {subCategory && (
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? mobileValueSize : webValueSize,
                                    }}
                                    textAlign={"center"}
                                >
                                    SubCategory: {subCategory}
                                </Typography>
                            )}
                            <Typography
                                sx={{
                                    fontSize: isMobile ? mobileLabelSize : webLabelSize,
                                }}
                                textAlign={"center"}
                            >
                                Difficulty: {Difficulty[difficulty]}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? mobileLabelSize : webLabelSize,
                                }}
                                textAlign={"center"}
                            >
                                Time Limit: {ConvertSecondsToTimerDisplay(timeLimit)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? mobileLabelSize : webLabelSize,
                                }}
                                textAlign={"center"}
                            >
                                Move Limit: {moveLimit}
                            </Typography>
                        </Paper>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button variant="contained" sx={{ margin: "1vh", backgroundColor: colors.accent }} onClick={goHomeClicked}>
                            Not Right Now
                        </Button>
                        <NextBox nextLevelCallBack={startLevelClicked} nextBoxText="Let's Go!" />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
