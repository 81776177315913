import { Table, TableCell, TableHead, TableRow } from "@mui/material";

import { Difficulty } from "@summitrhode/shared";

interface Props {
  profileData: {
    firstName: string;
    lastName: string;
    username: string;
  };
  statsByDifficulty: {
    averageScore: number;
    averageTime: number;
    difficulty: Difficulty;
    levelsPlayed: number;
  }[];
}

export function ProfilePageStats({ profileData, statsByDifficulty }: Props) {
  return (
    <Table style={{ flex: 2 }}>
      <TableHead>
        <TableRow>
          <TableCell>Difficulty</TableCell>
          <TableCell>Levels Played</TableCell>
          {/* <TableCell>Avg Time</TableCell> */}
          <TableCell>Avg Score</TableCell>
        </TableRow>
      </TableHead>
      {statsByDifficulty.map((item, index) => (
        <TableRow key={index}>
          <TableCell>{Difficulty[item.difficulty]}</TableCell>
          <TableCell>{item.levelsPlayed}</TableCell>
          {/* <TableCell>{item.averageTime}</TableCell> */}
          <TableCell>{Math.round(item.averageScore * 10) / 10}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
}
