"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAmountOfCorrectWordsGuessed = void 0;
function GetAmountOfCorrectWordsGuessed(WordGuesses, correctWords) {
    let count = 0;
    //Check how many of the words are in the puzzle words
    for (let id in WordGuesses) {
        if (correctWords.some((correctWord) => correctWord.getWord() === WordGuesses[id].getWord())) {
            count++;
        }
    }
    return count;
}
exports.GetAmountOfCorrectWordsGuessed = GetAmountOfCorrectWordsGuessed;
