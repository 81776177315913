"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPageFunctions = void 0;
class AdminPageFunctions {
    static async getParentCategories() {
        return await fetch("/posts/getEntries/", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
            .then((jsonRes) => {
            return jsonRes.parents;
        });
    }
    static async getSubCategoriesFromParent(parentId) {
        return await fetch("/posts/getEntries/" + parentId, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
            .then((jsonRes) => {
            return jsonRes.subs;
        });
    }
    static async getWordsFromParentSub(parentId, subCatId) {
        return await fetch("/posts/getEntries/" + parentId + "/" + subCatId, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
            .then((jsonRes) => {
            return jsonRes.words;
        });
    }
    static async updateParentCategory(parentId, updateString) {
        return await fetch("/posts/Update/UpdateParentCat/" + parentId + "/" + updateString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async updateSubCategory(parentId, subId, updateString) {
        return await fetch("/posts/Update/UpdateSubCat/" +
            parentId +
            "/" +
            subId +
            "/" +
            updateString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async updateWord(parentId, subId, wordId, updateString) {
        return await fetch("/posts/Update/UpdateWord/" +
            parentId +
            "/" +
            subId +
            "/" +
            wordId +
            "/" +
            updateString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async addNewParentCategory(insertString) {
        await fetch("/posts/Update/InsertNewParentCategory/" + insertString, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async addNewSubCategory(parentId, insertString) {
        await fetch("/posts/Update/InsertNewSubCategory/" + parentId + "/" + insertString, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async addNewWord(parentId, subId, insertString) {
        await fetch("/posts/Update/InsertNewWord/" +
            parentId +
            "/" +
            subId +
            "/" +
            insertString, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async makeNewLevel(parentCategoryId, subCategoryId, difficulty, percentFilled, rows, columns, words) {
        return await fetch(`/posts/LevelMaker/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                parentCategoryId: parentCategoryId,
                subCategoryId: subCategoryId,
                difficulty: difficulty,
                percentFilled: percentFilled,
                rows: rows,
                columns: columns,
                words: words,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
    static async saveNewLevel(answerCoordinates, columns, difficulty, letterArray, levelType, parentCategoryId, rows, subCategoryId, wordIdArray) {
        return await fetch(`/posts/SaveNewLevel/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                answerCoordinates: answerCoordinates,
                columns: columns,
                difficulty: difficulty,
                letterArray: letterArray,
                levelType: levelType,
                parentCategoryId: parentCategoryId,
                rows: rows,
                subCategoryId: subCategoryId,
                wordIdArray: wordIdArray,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        });
    }
}
exports.AdminPageFunctions = AdminPageFunctions;
