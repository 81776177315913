"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCorrectWords = void 0;
const models_1 = require("../../models");
function CreateCorrectWords(board, answerCoordinates) {
    let correctWords = [];
    for (let answerIndex = 0; answerIndex < answerCoordinates.length; answerIndex++) {
        for (let pieceIndex = 0; pieceIndex < answerCoordinates[answerIndex].length; pieceIndex++) {
            let piece = board.find((piece) => piece.boardIndex === answerCoordinates[answerIndex][pieceIndex]);
            if (piece === undefined) {
                console.error(`PieceIndex ${pieceIndex} of index ${answerIndex} in answerArray not found`);
                continue;
            }
            switch (pieceIndex) {
                case 0:
                    correctWords.push(new models_1.Word(piece, answerIndex));
                    break;
                default:
                    correctWords[answerIndex].pieceArray.push(piece);
                    break;
            }
        }
    }
    return correctWords;
}
exports.CreateCorrectWords = CreateCorrectWords;
