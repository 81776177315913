"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Levels = void 0;
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const models_1 = require("../../models");
class Levels {
    constructor(authenticatedUserGroups) {
        this.authenticatedUserGroups = authenticatedUserGroups;
    }
    /**
     * Take current puzzle variables and generate a new
     * level.
     *
     */
    async makeNewLevel(parentCategoryId, subCategoryId, difficulty, percentFilled, rows, columns, words, userInputWords, onlyUseUserWords, levelType, expansionPackId) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/LevelMaker/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "MakeNewLevel",
                    parentCategoryId: parentCategoryId,
                    subCategoryId: subCategoryId,
                    difficulty: difficulty,
                    percentFilled: percentFilled,
                    rows: rows,
                    columns: columns,
                    words: words,
                    userInputWords: userInputWords,
                    onlyUseUserWords: onlyUseUserWords,
                    levelType: levelType,
                    expansionPackId,
                }));
                const response = await restOperation.response;
                const level = JSON.parse(await response.body.text()).level;
                const puzzleWordsRelationships = JSON.parse(await response.body.text()).puzzleWordsRelationships;
                const duplicateWordsInPuzzles = JSON.parse(await response.body.text()).duplicateWordsInPuzzles;
                return { level, puzzleWordsRelationships, duplicateWordsInPuzzles };
            }
            catch (e) {
                console.error("Error making level: ", e);
            }
        }
        return;
    }
    /**
     * Commit the current level to the database
     *
     */
    async saveNewLevel(answerCoordinates, columns, difficulty, letterArray, levelType, parentCategoryId, rows, subCategoryId, wordIdArray, puzzleName, levelDate, expansionPackId, puzzleSubtitle) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/LevelMaker/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "SaveNewLevel",
                    answerCoordinates: answerCoordinates,
                    columns: columns,
                    difficulty: difficulty,
                    letterArray: letterArray,
                    levelType: levelType,
                    parentCategoryId: parentCategoryId,
                    rows: rows,
                    subCategoryId: subCategoryId,
                    wordIdArray: wordIdArray,
                    puzzleName: puzzleName,
                    levelDate: levelDate,
                    expansionPackId,
                    puzzleSubtitle,
                }));
                const response = await restOperation.response;
                console.debug("Level saved: ", response);
            }
            catch (e) {
                console.error("Error saving level: ", e);
            }
        }
        return;
    }
    async updateLevel(levelId, answerCoordinates, columns, difficulty, letterArray, levelType, parentCategoryId, rows, subCategoryId, wordIdArray, puzzleName, expansionPackId, puzzleSubtitle) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/LevelMaker/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateLevel",
                    levelId: levelId,
                    answerCoordinates: answerCoordinates,
                    columns: columns,
                    difficulty: difficulty,
                    letterArray: letterArray,
                    levelType: levelType,
                    parentCategoryId: parentCategoryId,
                    rows: rows,
                    subCategoryId: subCategoryId,
                    wordIdArray: wordIdArray,
                    puzzleName: puzzleName,
                    expansionPackId,
                    puzzleSubtitle,
                }));
                const response = await restOperation.response;
                console.debug("Level updated: ", response);
            }
            catch (e) {
                console.error("Error updating level: ", e);
            }
        }
        return;
    }
    async getExpansionPackLevels(levelType, monthNumber) {
        if (this.authenticatedUserGroups?.isAdmin) {
            let additionalString = "";
            if (monthNumber) {
                additionalString = "&monthNumber=" + monthNumber;
            }
            let path = "/items/adminGetLevel?levelType=" + models_1.LevelTypes[levelType] + additionalString;
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            if (levelType === models_1.LevelTypes.EXPANSION) {
                return JSON.parse(await response.body.text()).expansionPacks.returnArray;
            }
        }
        return [];
    }
    async getCampaignLevels(levelType, monthNumber) {
        if (this.authenticatedUserGroups?.isAdmin) {
            let additionalString = "";
            if (monthNumber) {
                additionalString = "&monthNumber=" + monthNumber;
            }
            let path = "/items/adminGetLevel?levelType=" + models_1.LevelTypes[levelType] + additionalString;
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            return JSON.parse(await response.body.text()).levels;
        }
        return [];
    }
    async getLevelDetails(levelType, levelNumber, date, expansionPackId // TODO Use level details in other branch
    ) {
        if (this.authenticatedUserGroups?.isAdmin) {
            let additionalString = "";
            if (levelNumber > 0) {
                additionalString += "&levelNumber=" + levelNumber;
            }
            if (date) {
                additionalString += "&date=" + date;
            }
            if (expansionPackId) {
                additionalString += "&expansionPackId=" + expansionPackId;
            }
            let path = "/items/adminGetLevel?levelType=" + models_1.LevelTypes[levelType] + additionalString;
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            return JSON.parse(await response.body.text()).levelDetails;
        }
        return [];
    }
}
exports.Levels = Levels;
