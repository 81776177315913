"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromptType = void 0;
/**
 * Each stage in a prompt will have an initial state,
 * potentially a secondary state,
 * and additional displays if the user doesn't follow the intended direction
 * or completes the propt as expected
 * INITIAL,
 * SECONDARY,
 * MISTAKE,
 * PARTIAL,
 * SUCCESS
 */
var PromptType;
(function (PromptType) {
    PromptType[PromptType["INITIAL"] = 0] = "INITIAL";
    PromptType[PromptType["SECONDARY"] = 1] = "SECONDARY";
    PromptType[PromptType["MISTAKE"] = 2] = "MISTAKE";
    PromptType[PromptType["PARTIAL"] = 3] = "PARTIAL";
    PromptType[PromptType["SUCCESS"] = 4] = "SUCCESS";
})(PromptType || (exports.PromptType = PromptType = {}));
