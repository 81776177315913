"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScoringMetricsByDifficulty = void 0;
const models_1 = require("../../models");
function getScoringMetricsByDifficulty(difficulty) {
    if (difficulty === models_1.Difficulty.EASY) {
        return {
            minimumTime: 0,
            timeScalingFactor: 500,
            timePoints: 350,
            movesScalingFactor: 20,
            maximumExtraMovesPerAnswer: 1,
            movePoints: 450,
            blanksLeftScalingFactor: 5,
            maximumBlanksAllowed: 2,
            blankPoints: 200,
            minimumMoveScore: 100,
            minimumTimeScore: 75,
            minimumBlankScore: 40,
            threeStarMin: 950,
            twoStarMin: 800,
            oneStarMin: 700,
            movePointsWithoutTimer: 600,
            blankPointsWithoutTimer: 400,
        };
    }
    else if (difficulty === models_1.Difficulty.MEDIUM) {
        return {
            minimumTime: 5,
            timeScalingFactor: 400,
            timePoints: 350,
            movesScalingFactor: 15,
            maximumExtraMovesPerAnswer: 1,
            movePoints: 450,
            blanksLeftScalingFactor: 5,
            maximumBlanksAllowed: 1,
            blankPoints: 200,
            minimumMoveScore: 80,
            minimumTimeScore: 60,
            minimumBlankScore: 30,
            threeStarMin: 950,
            twoStarMin: 800,
            oneStarMin: 700,
            movePointsWithoutTimer: 600,
            blankPointsWithoutTimer: 400,
        };
    }
    else if (difficulty === models_1.Difficulty.HARD) {
        return {
            minimumTime: 15,
            timeScalingFactor: 300,
            timePoints: 350,
            movesScalingFactor: 10,
            maximumExtraMovesPerAnswer: 1,
            movePoints: 450,
            blanksLeftScalingFactor: 5,
            maximumBlanksAllowed: 0,
            blankPoints: 200,
            minimumMoveScore: 60,
            minimumTimeScore: 45,
            minimumBlankScore: 20,
            threeStarMin: 950,
            twoStarMin: 800,
            oneStarMin: 700,
            movePointsWithoutTimer: 600,
            blankPointsWithoutTimer: 400,
        };
    }
    else if (difficulty === models_1.Difficulty.EXPERT) {
        return {
            minimumTime: 25,
            timeScalingFactor: 200,
            timePoints: 350,
            movesScalingFactor: 8,
            maximumExtraMovesPerAnswer: 1,
            movePoints: 450,
            blanksLeftScalingFactor: 5,
            maximumBlanksAllowed: 0,
            blankPoints: 200,
            minimumMoveScore: 40,
            minimumTimeScore: 30,
            minimumBlankScore: 10,
            threeStarMin: 950,
            twoStarMin: 800,
            oneStarMin: 700,
            movePointsWithoutTimer: 600,
            blankPointsWithoutTimer: 400,
        };
    }
    else if (difficulty === models_1.Difficulty.GOD) {
        return {
            minimumTime: 35,
            timeScalingFactor: 100,
            timePoints: 350,
            movesScalingFactor: 6,
            maximumExtraMovesPerAnswer: 1,
            movePoints: 450,
            blanksLeftScalingFactor: 5,
            maximumBlanksAllowed: 0,
            blankPoints: 200,
            minimumMoveScore: 20,
            minimumTimeScore: 15,
            minimumBlankScore: 0,
            threeStarMin: 950,
            twoStarMin: 800,
            oneStarMin: 700,
            movePointsWithoutTimer: 600,
            blankPointsWithoutTimer: 400,
        };
    }
    return {
        minimumTime: -1,
        timeScalingFactor: -1,
        timePoints: -1,
        movesScalingFactor: -1,
        maximumExtraMovesPerAnswer: -1,
        movePoints: -1,
        blanksLeftScalingFactor: -1,
        maximumBlanksAllowed: -1,
        blankPoints: -1,
        minimumMoveScore: -1,
        minimumTimeScore: -1,
        minimumBlankScore: -1,
        threeStarMin: -1,
        twoStarMin: -1,
        oneStarMin: -1,
        movePointsWithoutTimer: -1,
        blankPointsWithoutTimer: -1,
    };
}
exports.getScoringMetricsByDifficulty = getScoringMetricsByDifficulty;
