"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpansionPacks = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
class ExpansionPacks {
    constructor(authenticatedUserGroups) {
        this.authenticatedUserGroups = authenticatedUserGroups;
    }
    async addNewExpansionPack(name, description) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateExpansionPacks/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "InsertNewExpansionPack",
                    name,
                    description,
                }));
                const response = await restOperation.response;
                console.debug("New expansion pack added with ID: ", response);
            }
            catch (e) {
                console.error("Error adding expansion pack: ", e);
                return;
            }
        }
        return;
    }
    async updateExpansionPack(id, name, description, isAvailableToUsers, isAvailableToAdmins // Need to link to Expansion pack object you made in other branch
    ) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateExpansionPacks/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateExpansionPack",
                    id,
                    name,
                    description,
                    isAvailableToUsers,
                    isAvailableToAdmins,
                }));
                const response = await restOperation.response;
                console.debug("Updated expansion pack: ", response);
            }
            catch (e) {
                console.error("Error updating expansion pack: ", e);
            }
        }
        return;
    }
    /**
     * Resets the level numbers based on the admin levelmaker
     * rack and stack of level order
     *
     */
    async updateExpansionPackLevelNumberArrangement(packDetails) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/updatePackLevelOrder/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    packDetails,
                }));
                const response = await restOperation.response;
                console.debug("Updated expansion pack level number arrangment: ", response);
            }
            catch (e) {
                console.error("Error updating expansion pack level number arrangement: ", e);
            }
        }
        return;
    }
}
exports.ExpansionPacks = ExpansionPacks;
