"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserGroups = void 0;
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
async function getUserGroups() {
    const user = await (0, auth_1.fetchAuthSession)();
    if (user.tokens == undefined)
        throw Error("no token found");
    let cognitoGroups = user.tokens.accessToken.payload["cognito:groups"];
    if (cognitoGroups == undefined)
        return { isAdmin: false, isBetaTester: false, isUser: false };
    return {
        isAdmin: cognitoGroups.includes("Admin"),
        isBetaTester: cognitoGroups.includes("BetaTester"),
        isUser: cognitoGroups.includes("User"),
    };
}
exports.getUserGroups = getUserGroups;
