"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveUserLevelComplete = void 0;
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
const models_1 = require("../../models");
async function SaveUserLevelComplete(completionStats, levelType, gauntletPackId, levelNumber) {
    if (completionStats?.score === -1) {
        return;
    }
    let path = "/items/userLevelComplete";
    const user = await (0, auth_1.fetchAuthSession)();
    let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
        userID: user.userSub,
        completionStats: completionStats,
        levelType: models_1.LevelTypes[levelType],
        gauntletPackID: gauntletPackId,
        levelNumber: levelNumber,
    }));
    const response = await restOperation.response;
    console.debug("Document written with ID: ", response);
    return;
}
exports.SaveUserLevelComplete = SaveUserLevelComplete;
