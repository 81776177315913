import { Button, Paper, Typography } from "@mui/material";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import { Search, Check, Verified, ScreenRotationAlt } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors, Difficulty, WordState, componentColors } from "@summitrhode/shared";
import { useEffect, useState } from "react";

interface Props {
    isMobile: boolean;
    vWheight: number;
}

export function WordBox({ isMobile, vWheight }: Props) {
    const { wordGuesses, puzzleBoard, updateWordGuesses, wordGuessCount } = usePuzzleBoardContext();
    const [fontSize, setFontSize] = useState<number>(vWheight / wordGuessCount / 2);
    const [mobileIconSize, setMobileIconSize] = useState<number>((vWheight / wordGuessCount / 2) * 1.25);

    useEffect(() => {
        if (puzzleBoard) {
            setFontSize(0);
            setMobileIconSize(0);
            setValues();
        }
    }, [puzzleBoard, wordGuesses]);

    const setValues = () => {
        //How many characters requires a change in size
        const characterLimitForSize = 4;
        const minimumCount = 5;
        let numberOfWords = wordGuessCount;
        let characterCount = 0;

        Object.entries(wordGuesses).forEach(([id, wordGuess]) => {
            characterCount += wordGuess.getWord().length;
            numberOfWords += wordGuess.getWord().length / characterLimitForSize; //Math.ceil(
            // );
        });

        const equivalentNumberOfWords = characterCount / characterLimitForSize;

        numberOfWords = equivalentNumberOfWords > numberOfWords ? equivalentNumberOfWords : numberOfWords;

        if (numberOfWords < minimumCount) {
            numberOfWords = minimumCount;
        }

        setFontSize(vWheight / Math.log(numberOfWords) / 3.25);

        setMobileIconSize((vWheight / Math.log(numberOfWords) / 3.25) * 1.25);
    };

    return (
        <Paper
            elevation={5}
            sx={{
                alignItems: "center",
                background: componentColors.tertiary.backgroundColorFade,
                width: isMobile ? "95vw" : "25vh",
                // border: isMobile
                //   ? `${mobileIconSize / 10}vh solid ${
                //       componentColors.tertiary.borderColor + "CC"
                //     }`
                //   : `3px solid ${componentColors.tertiary.borderColor}`,
                borderRadius: "15px",
                // border: "1px solid black",
                paddingY: isMobile ? ".5vh" : "2%",
                paddingX: isMobile ? "2vw" : "2%",
                height: isMobile ? vWheight + "vh" : vWheight * 2 + "vh",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: isMobile ? "row" : "column",
                marginTop: isMobile ? ".5vh" : "1.5vh",
                marginBottom: isMobile ? ".5vh" : "1.5vh",
                justifyContent: isMobile ? "space-evenly" : "",
            }}
        >
            {isMobile ? null : (
                <Paper
                    elevation={5}
                    sx={{
                        border: isMobile ? `${mobileIconSize / 10}vh solid ${colors.grayscaleShade + "CC"}` : `3px solid ${colors.grayscaleShade}`,
                        borderRadius: "15px",
                        // border: "1px solid black",
                        paddingY: isMobile ? ".5vh" : "2%",
                        paddingX: isMobile ? "2vw" : "2%",
                        width: "100%",
                        background: componentColors.grayscale.backgroundColorTint,
                        marginBottom: "10px",
                    }}
                >
                    <Typography variant="h4" color="grayscale" align="center" sx={{ fontWeight: "bold" }}>
                        Words
                    </Typography>
                </Paper>
            )}
            {wordGuesses &&
                Object.entries(wordGuesses).map(([id, wordGuess]) => {
                    return (
                        <Paper
                            elevation={3}
                            sx={{
                                background: wordGuess.backgroundColor,

                                border: isMobile ? `${mobileIconSize / 10}vh solid ${wordGuess.accentColor}` : `2px solid ${wordGuess.accentColor}`,
                                borderRadius: isMobile ? `${mobileIconSize / 3}vh` : `10px`,

                                // borderColor: wordGuess.backgroundColor,
                                display: "flex",
                                flexDirection: "row",
                                // flex: 1,
                                justifyContent: "center",
                                height: isMobile ? mobileIconSize * 1.15 + "vh" : "35px",
                                // width: "100%",
                                // width: isMobile ? "30vw" : "25vh",
                                // flexBasis: isMobile ? "30vw" : "3vh",
                                padding: isMobile ? fontSize : "1px",
                                alignItems: "center",
                                textAlign: "center",
                                margin: isMobile ? `${mobileIconSize / 10}vh` : "4px",
                            }}
                            key={id}
                        >
                            {/* {true ? null : <Checkbox />} */}
                            {wordGuess.isLocked && (
                                <LockIcon
                                    style={{
                                        color: wordGuess.textColor,
                                        fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                />
                            )}
                            {wordGuess.wordState === WordState.SEARCHING ? (
                                <Search
                                    style={{
                                        color: wordGuess.textColor,
                                        fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                />
                            ) : null}
                            {wordGuess.wordState === WordState.RIGHT_WORD_WRONG_BLANKS || wordGuess.wordState === WordState.RIGHT_WORD ? (
                                <Check
                                    style={{
                                        color: wordGuess.textColor,
                                        fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                />
                            ) : null}
                            {wordGuess.wordState === WordState.RIGHT_WORD_WRONG_LETTERS ? (
                                <ScreenRotationAlt
                                    style={{
                                        color: wordGuess.textColor,
                                        fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                />
                            ) : null}
                            {wordGuess.wordState === WordState.RIGHT_WORD_EXACT_MATCH ? (
                                <Verified
                                    style={{
                                        color: wordGuess.textColor,
                                        fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                />
                            ) : null}
                            <Typography
                                sx={{
                                    color: wordGuess.textColor,
                                    fontSize: isMobile ? fontSize + "vh" : "25px",
                                }}
                            >
                                {wordGuess.getWord().toUpperCase()}
                            </Typography>

                            {wordGuess.isClearable && (
                                <Button
                                    sx={{
                                        height: isMobile ? mobileIconSize + "vh" : "25px",
                                        // width: isMobile
                                        //   ? fontSize / 4 + "vw"
                                        //   : fontSize / 4 + "vh",
                                        maxWidth: isMobile ? mobileIconSize + "vh" : "25px",
                                        minWidth: isMobile ? mobileIconSize + "vh" : "25px",
                                    }}
                                    size="small"
                                    onClick={() => {
                                        puzzleBoard?.removeWordGuess(id);
                                        updateWordGuesses();
                                    }}
                                >
                                    <DeleteIcon
                                        sx={{
                                            color: wordGuess.textColor,
                                            fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                        }}
                                    />
                                </Button>
                            )}
                        </Paper>
                    );
                })}
            {puzzleBoard?.difficulty === Difficulty.HARD &&
                puzzleBoard.remainingWordCount.map((word, index) => {
                    return (
                        <Paper
                            elevation={3}
                            style={{
                                background: componentColors.grayscale.backgroundColorTint,

                                // border: isMobile
                                //   ? `${mobileIconSize / 10}vh solid ${wordGuess.accentColor}`
                                //   : `2px solid ${wordGuess.accentColor}`,
                                borderRadius: isMobile ? `${mobileIconSize / 3}vh` : `10px`,

                                // borderColor: wordGuess.backgroundColor,
                                display: "flex",
                                flexDirection: "row",
                                // flex: 1,
                                justifyContent: "center",
                                height: isMobile ? mobileIconSize * 1.15 + "vh" : "35px",
                                width: isMobile ? mobileIconSize * 1.75 + "vh" : "35px",
                                // width: isMobile ? "30vw" : "25vh",
                                // flexBasis: isMobile ? "30vw" : "3vh",
                                padding: isMobile ? fontSize : "1px",
                                alignItems: "center",
                                textAlign: "center",
                                margin: isMobile ? `${mobileIconSize / 10}vh` : "4px",
                            }}
                            key={index + "pending"}
                        >
                            <Search
                                style={{
                                    color: colors.grayscaleShade,
                                    fontSize: isMobile ? mobileIconSize + "vh" : "25px",
                                }}
                            />
                        </Paper>
                    );
                })}
        </Paper>
    );
}
