"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.componentColors = void 0;
const _1 = require("./");
const degrees = "180deg"; //180   //223
const firstColorPercentage = "67.71%"; //68.75   //13.3
const secondColorPercentage = "100%"; //100   //100
const thirdColorPercentage = "0%";
exports.componentColors = {
    primary: {
        accentColor: _1.colors.primaryTint,
        backgroundColor: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.primary +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.primaryShade +
            " " +
            secondColorPercentage +
            ")",
        backgroundColorFade: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.primary +
            "DD " +
            firstColorPercentage +
            ", " +
            _1.colors.primaryShade +
            "DD " +
            secondColorPercentage +
            ")",
        backgroundColorTint: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.primaryTint +
            " " +
            thirdColorPercentage +
            ", " +
            _1.colors.primaryTint +
            "AA " +
            firstColorPercentage +
            ", " +
            _1.colors.primaryTint +
            " " +
            secondColorPercentage +
            ")",
        borderColor: _1.colors.primaryShade,
        coreColor: _1.colors.primary,
    },
    accent: {
        accentColor: _1.colors.accentTint,
        backgroundColor: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.accent +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.accentShade +
            " " +
            secondColorPercentage +
            ")",
        backgroundColorFade: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.accent +
            "DD " +
            firstColorPercentage +
            ", " +
            _1.colors.accentShade +
            "DD " +
            secondColorPercentage +
            ")",
        backgroundColorTint: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.accentTint +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.accent +
            " " +
            secondColorPercentage +
            ")",
        borderColor: _1.colors.accentShade,
        coreColor: _1.colors.accent,
    },
    grayscale: {
        accentColor: _1.colors.grayscaleTint,
        backgroundColor: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.grayscale +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.grayscaleShade +
            " " +
            secondColorPercentage +
            ")",
        backgroundColorFade: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.grayscale +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.grayscaleShade +
            " " +
            secondColorPercentage +
            ")",
        backgroundColorTint: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.grayscaleTint +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.grayscaleShade +
            " " +
            secondColorPercentage +
            ")",
        borderColor: _1.colors.grayscaleShade,
        coreColor: _1.colors.grayscale,
    },
    tertiary: {
        accentColor: _1.colors.tertiaryTint,
        backgroundColor: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.tertiary +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.tertiaryShade +
            " " +
            secondColorPercentage +
            ")",
        backgroundColorFade: "linear-gradient(" +
            _1.colors.tertiary +
            "99 " +
            firstColorPercentage +
            ", " +
            _1.colors.tertiaryShade +
            "99 " +
            secondColorPercentage +
            ")",
        backgroundColorTint: "linear-gradient(" +
            degrees +
            ", " +
            _1.colors.tertiaryTint +
            " " +
            firstColorPercentage +
            ", " +
            _1.colors.tertiary +
            " " +
            secondColorPercentage +
            ")",
        borderColor: _1.colors.tertiary,
        coreColor: _1.colors.tertiary,
    },
    //   primaryTint: "#fca9c2",
    //   primaryShade: "#C0305B",
    //   accent: "#227C9D",
    //   accentTint: "#4E96B1",
    //   accentShade: "#1B637E",
    //   warning: "#FFCB77",
    //   waningTint: "#FFD592",
    //   warningShade: "#CCA25F",
    //   grayscale: "#1F232B",
    //   grayscaleTint: "#E6E7E7",
    //   grayscaleShade: "#CCCCCC",
    //   secondary: "#17C3B2",
    //   secondaryTint: "#5DD5C9",
    //   secondaryShade: "#10897D",
    //   tertiary: "#FEF9EF",
    //   tertiaryTint: "#FEFAF2",
    //   tertiaryShade: "#CBC7BF",
    //   typographyBorder: "#F2F0F0",
    //   typographyText: "#1F6782",
    //   typographyTextShadow: "rgba(233, 233, 233, 0.75)",
    //   black: "#000000",
    //   white: "#FFFFFF",
};
