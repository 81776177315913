import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ConvertSecondsToTimerDisplay, UserPartialDailyStats, colors } from "@summitrhode/shared";

interface Props {
  isMobile: boolean;
  friendsTopDailys: {
    username: string;
    userID: string;
    moveCount: number;
    timeTaken: number;
    score: number;
  }[];
  globalTopDailys: {
    username: string;
    userID: string;
    moveCount: number;
    timeTaken: number;
    score: number;
  }[];
  userDailyStats: UserPartialDailyStats | undefined;
  userStreakStats: any;
}

const podiumColors: string[] = ["gold", "silver", "#CD7F32"];

export function DailyPuzzleMenuBox({
  isMobile,
  userDailyStats,
  friendsTopDailys,
  globalTopDailys,
  userStreakStats,
}: Props) {
  const [displayGroup, setDisplayGroup] = useState("global");

  const navigate = useNavigate();

  const playDailyPuzzleClicked = () => {
    navigate("/daily");
  };

  const handleUsernameClicked = (userID: string) => {
    navigate("/profile", { state: { profileToView: userID } });
  };

  const showFullLeaderboardClicked = () => {
    navigate("/leaderboard");
  };

  const handleDisplayGroupChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value) {
      setDisplayGroup(value);
    }
  };

  return (
    <Paper
      sx={{
        width: "95%",
        maxWidth: "800px",
        // height: "30vh",
        // border: "1px solid black",
        // padding: "1%",
        display: "flex",
        flexDirection: "column",
        margin: "2.5%",
        // background: "rgb(217,217,217,75%)",
        flexBasis: "40%",
        // borderColor: "white",
        // border: '2px solid white',
        // borderRadius: "1.5%",
        backgroundColor: colors.accentShade + "CC",
      }}
      elevation={5}
    >
      <TableContainer component={Paper} style={{ maxHeight: 200 }}>
        <Table sx={{}} aria-label="sticky table" size="small">
          <TableHead
            sx={{
              backgroundColor: colors.accentShade,
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
            title="Today's Daily"
          >
            <TableRow>
              <TableCell sx={{ color: colors.white }} align="center">
                Top Players
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Moves
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Time
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayGroup === "friends" &&
              friendsTopDailys.map((entry, index) => (
                <TableRow
                  key={entry.username}
                >
                  <TableCell // component="th"
                    scope="row"
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        handleUsernameClicked(entry.userID);
                      }}
                    >
                      {index < 3 && (
                        <EmojiEventsIcon sx={{ color: podiumColors[index] }} />
                      )}
                      {index >= 3 && index + 1}
                      {"  "}
                      {entry.username}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{entry.moveCount}</TableCell>
                  <TableCell align="center">
                    {ConvertSecondsToTimerDisplay(entry.timeTaken)}
                  </TableCell>
                  <TableCell align="center">{entry.score}</TableCell>
                </TableRow>
              ))}
            {displayGroup === "global" &&
              globalTopDailys.map((entry, index) => (
                <TableRow
                  key={entry.username}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell //component="th"
                    scope="row"
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        handleUsernameClicked(entry.userID);
                      }}
                    >
                      {index < 3 && (
                        <EmojiEventsIcon sx={{ color: podiumColors[index] }} />
                      )}
                      {index >= 3 && index + 1}
                      {"  "}
                      {entry.username}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{entry.moveCount}</TableCell>
                  <TableCell align="center">
                    {ConvertSecondsToTimerDisplay(entry.timeTaken)}
                  </TableCell>
                  <TableCell align="center">{entry.score}</TableCell>
                </TableRow>
              ))}
            <TableRow onClick={() => showFullLeaderboardClicked()}>
              <TableCell
                // component="th"
                scope="row"
                sx={{ color: colors.accent, fontStyle: "italic" }}
              >
                See All {">>>"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ToggleButtonGroup
        color="secondary"
        fullWidth
        value={displayGroup}
        exclusive
        onChange={(event, value) => handleDisplayGroupChange(event, value)}
        // aria-label="Platform"
        sx={{ backgroundColor: colors.grayscaleTint, justifyContent: "center" }}
      >
        <ToggleButton value="friends">Friends</ToggleButton>
        <ToggleButton value="global">Global</ToggleButton>
      </ToggleButtonGroup>

      <Button
        variant="contained"
        onClick={playDailyPuzzleClicked}
        disabled={userDailyStats !== undefined}
        sx={{
          fontSize: "2.5vh",
          fontWeight: "bold",
          height: "7vh",
        }}
      >
        <Typography
          variant={"h5"}
          color={colors.white}
          // sx={{ fontStyle: "italic" }}
        >
          {userDailyStats !== undefined ? "Already Played" : "Play the Daily"}
        </Typography>
      </Button>

      {userDailyStats !== undefined && (
        <Typography
          color={colors.white}
          variant="body1"
          sx={{ alignSelf: "center" }}
        >
          <b>Your Score:</b> {userDailyStats.score}
        </Typography>
      )}
    </Paper>
  );
}
