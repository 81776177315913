import { Paper } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { StarTwoTone } from "@mui/icons-material";
import { colors, GameState } from "@summitrhode/shared";

interface Props {
    starSize: string;
    winState: GameState | undefined;
}

export function StarBox({ starSize, winState }: Props) {
    const goldColor = colors.primary;
    const blackColor = colors.black;

    return (
        <Paper
            sx={{
                maxWidth: 300,
                borderRadius: "15%",
                border: "1px solid black",
                padding: "2%",
            }}
        >
            {winState === GameState.THREE_STAR && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                </div>
            )}
            {winState === GameState.TWO_STAR && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                </div>
            )}
            {winState === GameState.ONE_STAR && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <StarTwoTone style={{ fontSize: starSize, color: goldColor }} />
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                </div>
            )}
            {winState === GameState.PLAYING && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                    <StarBorderOutlinedIcon style={{ fontSize: starSize, color: blackColor }} />
                </div>
            )}
        </Paper>
    );
}
