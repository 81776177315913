import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import { Tile } from "./Tile";
import { colors, GameState, PromptType, LevelCompleteStats, Puzzle, PuzzleBoard, Piece, LevelTypes } from "@summitrhode/shared";

interface Props {
    bigHintEngaged: boolean;
    cachedPuzzleBoard: PuzzleBoard | undefined;
    checkAllEngaged: boolean;
    completeLevel: (completionStats: LevelCompleteStats) => void;
    isMobile: boolean;
    isTutorial?: boolean;
    littleHintEngaged: boolean;
    pausePuzzle: boolean;
    puzzle: Puzzle;
    revealPuzzleEngaged: boolean;
    startPuzzle: boolean;
    toggleLittleHintButton: (hintWasUsed: boolean) => void;
    toggleCheckAllButton: (hintWasUsed: boolean) => void;
    toggleBigHintButton: (hintWasUsed: boolean) => void;
    toggleRevealPuzzleButton: (hintWasUsed: boolean) => void;
    updateTutorialPrompt?: (promptType: PromptType) => void;
}

const _blankWarningTimeLimit = 0.25;

/**
 * Component that displays and controls
 * the tiles inside the puzzle board
 * to form the puzzle box space
 */
export function PuzzleBox({
    bigHintEngaged,
    cachedPuzzleBoard,
    checkAllEngaged,
    completeLevel,
    isMobile,
    isTutorial,
    littleHintEngaged,
    pausePuzzle,
    puzzle,
    revealPuzzleEngaged,
    startPuzzle,
    toggleBigHintButton,
    toggleCheckAllButton,
    toggleLittleHintButton,
    toggleRevealPuzzleButton,
    updateTutorialPrompt,
}: Props) {
    // const [puzzleBoardInstance, setPuzzleBoardInstance] = useState<PuzzleBoard>();
    const { puzzleBoard, updatePuzzleBoard, updateWordGuesses } = usePuzzleBoardContext();
    const [shouldPausePuzzle, setShouldPausePuzzle] = useState<boolean>(true);

    const [blankWarningTimer, setBlankWarningTimer] = useState<number>(0);

    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        if (puzzleBoard && !shouldPausePuzzle) {
            puzzleBoard.setClockRunning(false);
            setShouldPausePuzzle(true);
            setBlankWarningTimer(0);
            console.debug("blur means user is away");
        }
    };

    // User has switched back to the tab
    const onFocus = () => {
        if (puzzleBoard && shouldPausePuzzle) {
            puzzleBoard.setClockRunning(true);
            setShouldPausePuzzle(false);
            setBlankWarningTimer(0);
            console.debug("focus means user is here");
        }
    };

    useEffect(() => {
        pausePuzzle ? onBlur() : onFocus();
    }, [pausePuzzle]);

    // Allows to keep puzzle paused until user accepts the Gauntlet Prompt
    useEffect(() => {
        setShouldPausePuzzle(!startPuzzle);
    }, [startPuzzle]);

    useEffect(() => {
        // exit early when we reach 0
        if (!blankWarningTimer) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setBlankWarningTimer(blankWarningTimer - 0.25);
        }, 250);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [blankWarningTimer]);

    useEffect(() => {
        if (puzzle) {
            let tempPuzzleBoard = new PuzzleBoard(puzzle, cachedPuzzleBoard);
            updatePuzzleBoard(tempPuzzleBoard);
            // setPuzzleBoardInstance(tempPuzzleBoard);
        }
    }, [puzzle, cachedPuzzleBoard]);

    useEffect(() => {
        if (puzzleBoard && puzzleBoard.puzzleComplete && puzzleBoard.gameState !== GameState.PLAYING && puzzle.RDSLevelID) {
            completeLevel({
                levelID: puzzle.RDSLevelID,
                gameState: puzzleBoard.gameState,
                moveCount: puzzleBoard.moveCount,
                timeTaken: puzzleBoard.timeTaken,
                blanksLeft: puzzleBoard.blanksLeft,
                littleHintsUsed: puzzleBoard.littleHintsUsed,
                bigHintsUsed: puzzleBoard.bigHintsUsed,
                checkAllsUsed: puzzleBoard.checkAllsUsed,
                score: puzzleBoard.finalScore,
                moveCapReached: puzzleBoard.moveCountReached,
                timeCapReached: puzzleBoard.timeCapReached,
            });
        }
    }, [puzzleBoard?.puzzleComplete]); //puzzleBoard?.gameState,

    const grabNode = (piece: Piece) => {
        console.debug("grabNode");
        if (puzzleBoard === undefined || puzzle.isAdminPuzzle || !piece.isClickable || (piece.letter === " " && !bigHintEngaged && !littleHintEngaged && !checkAllEngaged && !revealPuzzleEngaged)) {
            if (piece.letter === " " && !puzzle.isAdminPuzzle) {
                puzzleBoard?.setGrabBlanksToolTip();
                setBlankWarningTimer(_blankWarningTimeLimit);
            }
            return;
        }
        if (bigHintEngaged) {
            handleTutorialPromptUpdate(puzzleBoard.revealWholeAnswer(piece));
            updateWordGuesses();
            toggleBigHintButton(true);
        } else if (littleHintEngaged) {
            handleTutorialPromptUpdate(puzzleBoard.revealLittleHintAnswer(piece, puzzleBoard.difficulty));
            updateWordGuesses();
            toggleLittleHintButton(true);
        } else if (checkAllEngaged) {
            handleTutorialPromptUpdate(puzzleBoard.checkAllWordGuesses(piece));
            updateWordGuesses();
            toggleCheckAllButton(true);
        } else if (revealPuzzleEngaged) {
            //TODO Add reveal All to tutorial
            // handleTutorialPromptUpdate(puzzleBoard.revealAllAnswers());
            puzzleBoard.giveUpOnPuzzle(true);
            // puzzleBoard.setGameState();
            // updateWordGuesses();
            toggleRevealPuzzleButton(true);
        } else {
            if (puzzleBoard.tryAddPieceAsAnchor(piece)) {
                // setPuzzleBoard(puzzleBoard.updateBoard(piece));
                puzzleBoard.updateBoard(piece);

                updateWordGuesses();
            }
            puzzleBoard.setActiveWordGuess(piece);
            puzzleBoard.setInitialActiveWordGuess(piece);
            if (isTutorial) {
                puzzleBoard.flashNextPiece(piece);
                updateWordGuesses();
            }
        }
    };

    /**
     * When the mouse enters a piece for the first time it will try to add that piece to the activeWord
     * Or it will slice the active word
     * @param piece
     */
    const moveNode = (piece: Piece) => {
        if (puzzleBoard === undefined || puzzle.isAdminPuzzle || piece.isLocked) return;
        if (puzzleBoard.tryAddPieceToActiveWord(piece) || puzzleBoard.trySliceActiveWord(piece)) {
            if (isTutorial && piece.wordGuessPieceIndex) {
                puzzleBoard.flashNextPiece(piece);
            }
            puzzleBoard.updateBoard(piece);

            updateWordGuesses();
        }
    };

    const releaseNode = (piece: Piece) => {
        console.debug("releasingNode");
        handleTutorialPromptUpdate(puzzleBoard?.releaseActiveWordGuess(piece));
        setBlankWarningTimer(_blankWarningTimeLimit);
        updateWordGuesses();
    };

    const handleTutorialPromptUpdate = (tutorialPromptTypeState: PromptType | undefined) => {
        if (tutorialPromptTypeState !== undefined && updateTutorialPrompt && isTutorial) {
            puzzleBoard?.turnOffFlashOnAllTiles();
            if (tutorialPromptTypeState !== undefined) updateTutorialPrompt(tutorialPromptTypeState);
        }
    };

    /**
     * Touch events need to be handled differently because there is no support for touchenter
     * TODO: Not the most efficient way but works
     */
    const touchMoveNode = (e: React.TouchEvent<HTMLDivElement>) => {
        let touchX = e.touches[0].clientX || e.changedTouches[0].clientX;
        let touchY = e.touches[0].clientY || e.changedTouches[0].clientY;
        let puzzleBoardElement = document.getElementById("puzzleBoard");
        if (puzzleBoardElement === null) return;
        for (let i = 0; i < puzzleBoardElement.children.length; i++) {
            let child = puzzleBoardElement.children[i]?.getBoundingClientRect();
            if (touchX > child.left && touchX < child.right && touchY < child.bottom && touchY > child.top) {
                let dataBoardIndex = puzzleBoardElement.children[i].getAttribute("data-board-index");
                if (dataBoardIndex === null) return;
                moveNode(puzzleBoard?.board[parseInt(dataBoardIndex)] as Piece);
                return;
            }
        }
    };

    if (shouldPausePuzzle) {
        return (
            <div
                // onBlur={(e) => onBlur(e)}
                // onFocus={(e) => onFocus(e)}
                style={{
                    alignItems: "center",
                    display: "flex",
                    height: puzzle.rows > puzzle.columns ? (puzzle.columns / puzzle.rows) * 89 + "vw" : (puzzle.rows / puzzle.columns) * 89 + "vw",
                    width: puzzle.rows < puzzle.columns ? (puzzle.columns / puzzle.rows) * 89 + "vw" : (puzzle.rows / puzzle.columns) * 89 + "vw",
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Typography
                    sx={{
                        color: colors.grayscaleShade,
                        fontSize: mobileView ? "10vh" : "30px",
                    }}
                >
                    Paused
                </Typography>
            </div>
        );
    } else {
        return (
            <div
                // onBlur={(e) => onBlur(e)}
                // onFocus={(e) => onFocus(e)}
                onClick={() => {
                    if (puzzleBoard?.blanksToolTipEnabled && blankWarningTimer <= 0) {
                        puzzleBoard.hideBlanksToolTip();
                        updateWordGuesses();
                        // puzzleBoard?.clearBlanksToolTip();
                    }
                }}
            >
                {puzzleBoard && (
                    <Tooltip title={puzzleBoard.blanksToolTipTitle} open={puzzleBoard.blanksToolTipEnabled} sx={{ textAlign: "center" }}>
                        <div
                            id="puzzleBoard"
                            onTouchMove={(e) => touchMoveNode(e)}
                            style={{
                                display: "grid",
                                gridTemplateColumns: `repeat(${puzzleBoard.columns}, 1fr)`,
                                gridAutoRows: `1fr`,
                                justifyContent: "center",
                                alignItems: "center",
                                // touchAction: "none",

                                // width: `${props.puzzle.columnRatio * 100}%`,
                                // height: `${props.puzzle.rowRatio * 100}%`,
                            }}
                        >
                            {puzzleBoard.board.map((piece: Piece) => {
                                return (
                                    <Tile
                                        piece={piece}
                                        key={piece.id()}
                                        grabNodeCallBack={grabNode}
                                        moveNodeCallBack={moveNode}
                                        releaseNodeCallBack={releaseNode}
                                        columnRowRatio={puzzle.rows > puzzle.columns ? puzzle.rows : puzzle.columns}
                                        isMobile={isMobile}
                                    />
                                );
                            })}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    }
}
