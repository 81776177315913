"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPartialDailyStats = void 0;
class UserPartialDailyStats {
    constructor(moveCount, score, timeTaken, userID) {
        this.moveCount = moveCount;
        this.score = score;
        this.timeTaken = timeTaken;
        this.userID = userID;
    }
}
exports.UserPartialDailyStats = UserPartialDailyStats;
