"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelWord = void 0;
class LevelWord {
    constructor(wordId, name) {
        this.wordId = wordId;
        this.name = name;
    }
}
exports.LevelWord = LevelWord;
