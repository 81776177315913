"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentUserAuthTokenString = void 0;
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
async function getCurrentUserAuthTokenString() {
    const user = await (0, auth_1.fetchAuthSession)();
    const token = user.tokens?.idToken;
    if (token == undefined) {
        throw new Error('No auth token found');
    }
    return token.toString();
}
exports.getCurrentUserAuthTokenString = getCurrentUserAuthTokenString;
