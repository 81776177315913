import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { AuthProvider, useAuth } from "./context/authContext";
import { LevelProvider } from "./context/levelContext";

import ResponsiveAppBar from "./components/Navbar/Header";
import GuestPage from "./components/GuestPages/GuestPage";
// import AdminDictionaryPage from "./components/Admin/AdminDictionaryPage";
// import AdminLevelMakerPage from "./components/Admin/AdminLevelMakerPage";
// import LevelsPage from "./components/LevelsMap/LevelsPage";
import { HomePage } from "./components/HomePage/HomePage";
import { ThemeProvider } from "@mui/material";
import { ProfilePage } from "./components/Profile/ProfilePage";
// import { GroupsPage } from "./components/Groups/GroupsPage";
import { DailyLeaderboardPage } from "./components/DailyLeaderboardPage/DailyLeaderboardPage";
// import { PuzzlePacksPage } from "./components/PuzzlePacksPage/PuzzlePacksPage";

import { LoadingWheel } from "./components/UtilityComponents/LoadingWheel";
import { componentColors, LevelTypes, theme, getUserGroups, IAuthenticatedUserGroups, configureAmplify, getUser } from "@summitrhode/shared";
import { PuzzlePage } from "./components/PuzzleScreen/PuzzlePage";
import AdminDictionaryPage from "./components/Admin/AdminDictionaryPage";
import AdminLevelMakerPage from "./components/Admin/AdminLevelMakerPage";

configureAmplify(window.location.origin);

function App() {
    const [authenticatedUserGroups, setAuthenticatedUserGroups] = useState<
        | {
              isAdmin: boolean;
              isBetaTester: boolean;
              isUser: boolean;
          }
        | undefined
    >();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);

    useEffect(() => {
        async function asyncFunctions() {
            await getUserGroups().then((userGroups: IAuthenticatedUserGroups) => {
                setAuthenticatedUserGroups(userGroups);
                setPageLoaded(true);
            });
        }

        asyncFunctions();
        setPageLoaded(false);
    }, []);

    if (pageLoaded) {
        return (
            <AuthProvider>
                <div
                    style={{
                        background: componentColors.primary.accentColor,
                        height: "100%",
                        minHeight: "100vh",
                    }}
                >
                    <meta name="mobile-web-app-capable" content="yes"></meta>
                    <ThemeProvider theme={theme}>
                        {authenticatedUserGroups?.isBetaTester && (
                            <Router>
                                <LevelProvider>
                                    <ResponsiveAppBar />
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        {/* <Route
                      path="/friends"
                      element={<ProfilePage defaultNavigationIndex={2} />}
                    /> */}
                                        {/* <Route path="/groups" element={<GroupsPage />} /> */}
                                        <Route path="/leaderboard" element={<DailyLeaderboardPage />} />
                                        <Route path="/daily" element={<PuzzlePage levelType={LevelTypes.DAILY} />} />
                                        <Route path="/gauntlet" element={<PuzzlePage levelType={LevelTypes.GAUNTLET} />} />
                                        <Route path="/campaign" element={<PuzzlePage levelType={LevelTypes.CAMPAIGN} />} />
                                        <Route path="/expansion" element={<PuzzlePage levelType={LevelTypes.EXPANSION} />} />
                                        <Route path="/profile" element={<ProfilePage defaultNavigationIndex={0} />} />
                                        {/* <Route path="/levels" element={<LevelsPage />} /> */}
                                        {/* <Route path="/packs" element={<PuzzlePacksPage />} /> */}
                                        <Route path="/dictionary" element={<AdminDictionaryPage />} />
                                        <Route path="/levelMaker" element={<AdminLevelMakerPage />} />
                                        /* <Route path="/tutorial" element={<PuzzlePage levelType={LevelTypes.TUTORIAL} />} />
                                    </Routes>
                                </LevelProvider>
                            </Router>
                        )}

                        {!authenticatedUserGroups?.isBetaTester && (
                            <Router>
                                <LevelProvider>
                                    <ResponsiveAppBar />
                                    <Routes>
                                        <Route path="/" element={<GuestPage />} />
                                    </Routes>
                                </LevelProvider>
                            </Router>
                        )}
                    </ThemeProvider>
                </div>
            </AuthProvider>
        );
    } else {
        return <LoadingWheel />;
    }
}

export default withAuthenticator(App);
