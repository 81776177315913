"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RdsLevel = void 0;
const _1 = require("./");
class RdsLevel {
    constructor(levelId = "", levelNumber = 0, levelType = _1.LevelTypes.CAMPAIGN, expansionPackId = undefined, expansionPackName = undefined, gauntletPackId = undefined, gauntletPackName = undefined, title = "", subtitle = "", difficulty = _1.Difficulty.EASY // public levelId: number = -1, // public levelNumber: number = 0,
    ) {
        this.levelId = levelId;
        this.levelNumber = levelNumber;
        this.levelType = levelType;
        this.expansionPackId = expansionPackId;
        this.expansionPackName = expansionPackName;
        this.gauntletPackId = gauntletPackId;
        this.gauntletPackName = gauntletPackName;
        this.title = title;
        this.subtitle = subtitle;
        this.difficulty = difficulty;
    }
}
exports.RdsLevel = RdsLevel;
