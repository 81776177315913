interface Props {
  fillColor: string;
  accentColor: string;
  outterWidth: string;
}

export function DownArrow({ fillColor, accentColor, outterWidth }: Props) {
  return (
    <svg
      width={outterWidth}
      height={outterWidth}
      viewBox="0 0 604 663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M337.411 600.581C321.659 628.06 282.024 628.06 266.271 600.581L75.6316 268.021C59.9629 240.688 79.696 206.631 111.202 206.631L492.481 206.631C523.986 206.631 543.719 240.688 528.051 268.021L337.411 600.581Z"
        fill={accentColor}
      />

      <rect
        x="390.682"
        y="358.631"
        width="177"
        height="358.628"
        rx="62"
        transform="rotate(180 390.682 358.631)"
        fill={accentColor}
      />
      <path
        d="M332.868 579.148C319.036 603.276 284.235 603.276 270.403 579.148L96.0711 275.035C82.3133 251.035 99.6398 221.131 127.303 221.131L475.968 221.131C503.631 221.131 520.958 251.035 507.2 275.035L332.868 579.148Z"
        fill={fillColor}
      />
      <rect
        x="374.682"
        y="315.631"
        width="146.584"
        height="297"
        rx="45"
        transform="rotate(180 374.682 315.631)"
        fill={fillColor}
      />
    </svg>
  );
}
