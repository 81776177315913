import React, { useEffect, useState } from "react";
let Fireworks = require("@summitrhode/shared/assets/Fireworks.png");
let OneStarWin = require("@summitrhode/shared/assets/OneStarWin.svg");
let TwoStarWin = require("@summitrhode/shared/assets/TwoStarWin.svg");
let ThreeStarWin = require("@summitrhode/shared/assets/ThreeStarWin.svg");
import { GameState, LevelCompleteStats, LevelTypes, SaveUserLevelComplete, SubmitUserLevelFeedback } from "@summitrhode/shared";
import { NextBox, StarBox } from ".";
import { Box, Button, Fade, Modal, Paper, Rating, TextField, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
let animationData = require("@summitrhode/shared/assets/animations/puzzleComplete.json");
import { useNavigate } from "react-router-dom";
import { colors } from "@summitrhode/shared";
import { useLevelContext } from "../../context/levelContext";

interface Props {
    puzzleComplete: boolean;
    completionStats: LevelCompleteStats | undefined;
    levelType: LevelTypes;
    nextLevel: () => void;
    hideModal: () => void;
    isMobile: boolean;
}

export default function LevelCompleteModal({ puzzleComplete, completionStats, levelType, nextLevel, hideModal, isMobile }: Props) {
    const navigate = useNavigate();
    const { currentGauntletLevel } = useLevelContext();

    const [show, setShow] = useState(false);
    const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("Level Complete!");
    const [successfullyCompletedLevel, setSuccessfullyCompletedLevel] = useState<boolean>(true);
    const [starValue, setStarValue] = useState<number | null>(0);
    const [puzzleFeedbackTextField, setPuzzleFeedbackTextField] = useState<string>("");

    const mobileLabelSize = "2.5vh";
    const mobileValueSize = "3vh";
    const webLabelSize = "20px";
    const webValueSize = "25px";

    const handleClose = () => {
        saveUserFeedback();
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
        if (levelType === LevelTypes.GAUNTLET) {
            SaveUserLevelComplete(completionStats, levelType, currentGauntletLevel.gauntletPackId, currentGauntletLevel.levelNumber);
        } else {
            SaveUserLevelComplete(completionStats, levelType);
        }
    };

    useEffect(() => {
        if (puzzleComplete) {
            if (completionStats?.timeCapReached) {
                setModalTitle("Time Cap Reached!");
                setSuccessfullyCompletedLevel(false);
            }

            if (completionStats?.moveCapReached) {
                setModalTitle("Move Cap Reached!");
                setSuccessfullyCompletedLevel(false);
            }

            handleShow();
        } else handleClose();
    }, [puzzleComplete]);

    const nextLevelClick = () => {
        saveUserFeedback();
        hideModal();
        nextLevel();
    };

    const goHomeClick = () => {
        saveUserFeedback();
        hideModal();
        navigate("/");
    };

    const saveUserFeedback = () => {
        if (completionStats?.levelID && starValue && (starValue > 0 || puzzleFeedbackTextField.length > 0)) {
            SubmitUserLevelFeedback(completionStats.levelID, starValue, puzzleFeedbackTextField);
            console.debug("Saved user feedback");
        }
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        maxWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={show}>
            <Fade in={show}>
                <Box sx={style}>
                    {successfullyCompletedLevel && (
                        <Player
                            src={animationData}
                            className="player"
                            // loop
                            autoplay
                            style={{
                                zIndex: 0,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100vw",
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "3px",
                            borderBottom: "1px solid grey",
                        }}
                    >
                        <Typography id="spring-modal-title" variant="h6" component="h2">
                            {modalTitle}
                        </Typography>
                        <StarBox starSize={"100%"} winState={completionStats?.gameState} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderBottom: "1px solid grey",
                        }}
                    >
                        {completionStats?.gameState === GameState.ONE_STAR && <img src={OneStarWin} width={"40%"} />}
                        {completionStats?.gameState === GameState.TWO_STAR && <img src={TwoStarWin} width={"40%"} />}
                        {completionStats?.gameState === GameState.THREE_STAR && <img src={ThreeStarWin} width={"40%"} style={{ zIndex: 0 }} />}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Box
                                sx={{
                                    p: 2,
                                    bgcolor: "background.default",
                                    display: "grid",
                                    gridTemplateColumns: { md: "1fr" },
                                    gap: 2,
                                    width: "100%",
                                }}
                            >
                                {(levelType === LevelTypes.DAILY || levelType === LevelTypes.GAUNTLET) && (
                                    <Paper key={"time"} elevation={2} sx={{ justifyContent: "center", minWidth: "150px" }}>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? mobileLabelSize : webLabelSize,
                                            }}
                                            textAlign={"center"}
                                        >
                                            Time:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? mobileValueSize : webValueSize,
                                            }}
                                            textAlign={"center"}
                                        >
                                            {completionStats?.timeTaken}sec
                                        </Typography>
                                    </Paper>
                                )}
                                <Paper key={"score"} elevation={2} sx={{ justifyContent: "center", minWidth: "150px" }}>
                                    <Typography sx={{ fontSize: isMobile ? mobileLabelSize : webLabelSize }} textAlign={"center"}>
                                        Score:
                                    </Typography>
                                    <Typography sx={{ fontSize: isMobile ? mobileValueSize : webValueSize }} textAlign={"center"}>
                                        {completionStats?.score}
                                    </Typography>
                                </Paper>
                                <Paper key={"moves"} elevation={2} sx={{ justifyContent: "center", minWidth: "150px" }}>
                                    <Typography sx={{ fontSize: isMobile ? mobileLabelSize : webLabelSize }} textAlign={"center"}>
                                        Moves:
                                    </Typography>
                                    <Typography sx={{ fontSize: isMobile ? mobileValueSize : webValueSize }} textAlign={"center"}>
                                        {completionStats?.moveCount}
                                    </Typography>
                                </Paper>
                                <Paper key={"blanks"} elevation={2} sx={{ justifyContent: "center", minWidth: "150px" }}>
                                    <Typography sx={{ fontSize: isMobile ? mobileLabelSize : webLabelSize }} textAlign={"center"}>
                                        Blanks Left:
                                    </Typography>
                                    <Typography sx={{ fontSize: isMobile ? mobileValueSize : webValueSize }} textAlign={"center"}>
                                        {completionStats?.blanksLeft}
                                    </Typography>
                                </Paper>
                            </Box>
                        </div>
                    </Box>
                    {showFeedbackPrompt && (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                zIndex: 2,
                            }}
                        >
                            <Typography sx={{ fontSize: isMobile ? mobileLabelSize : webLabelSize }}>How was this puzzle?</Typography>
                            <Rating
                                name="simple-controlled"
                                value={starValue}
                                size="large"
                                onChange={(event, newValue) => {
                                    setStarValue(newValue);
                                }}
                            />
                            <TextField
                                id="puzzle-feedback-textfield"
                                fullWidth
                                label="Puzzle Feedback"
                                multiline
                                maxRows={4}
                                onChange={(e) => setPuzzleFeedbackTextField(e.target.value)}
                                onBlur={() => {
                                    window.scrollTo(0, 0);
                                }}
                                sx={{ fontSize: isMobile ? mobileLabelSize : webLabelSize }}
                                variant="outlined"
                                defaultValue={puzzleFeedbackTextField}
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button variant="contained" sx={{ margin: "1vh", backgroundColor: colors.accent }} onClick={hideModal}>
                            Close
                        </Button>
                        {levelType !== LevelTypes.DAILY && successfullyCompletedLevel && <NextBox nextLevelCallBack={nextLevelClick} nextBoxText="Next Level" />}
                        {levelType === LevelTypes.DAILY && <NextBox nextLevelCallBack={goHomeClick} nextBoxText="Go Home" />}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
