"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHomeScreenUserContext = void 0;
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
// Gets the main data as app inital launch to populate homepage
async function getHomeScreenUserContext() {
    const user = await (0, auth_1.fetchAuthSession)();
    let path = "/items/getHomeScreenUserContext?sub=" + user.userSub;
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
    const response = await restOperation.response;
    return JSON.parse(await response.body.text()); //TODO Need to create objects for each of the returned values in here
}
exports.getHomeScreenUserContext = getHomeScreenUserContext;
