"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminLevelPageFunctions = void 0;
class AdminLevelPageFunctions {
    static async getCampaignLevels() {
        return await fetch("/posts/GetAllCampaignLevels/", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
            .then((jsonRes) => {
            return jsonRes.campaignLevels;
        });
    }
    static async getCampaignLevelDetails(levelNumber) {
        return await fetch("/posts/GetCampaignLevelDetails/" + levelNumber, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
            .then((jsonRes) => {
            return jsonRes.campaignLevelDetails;
        });
    }
}
exports.AdminLevelPageFunctions = AdminLevelPageFunctions;
