"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMovesMadeScore = void 0;
const _1 = require("./");
//Checks to see how many stars the player gets based on moves
function getMovesMadeScore(moveCount, numberOfPuzzleWords, difficulty, timerCountsInScore) {
    const scoreMetrics = (0, _1.getScoringMetricsByDifficulty)(difficulty);
    let maxScore = scoreMetrics.movePoints;
    if (!timerCountsInScore) {
        maxScore = scoreMetrics.movePointsWithoutTimer;
    }
    let movesMadeScore = maxScore *
        (scoreMetrics.movesScalingFactor /
            (scoreMetrics.movesScalingFactor +
                moveCount -
                numberOfPuzzleWords * scoreMetrics.maximumExtraMovesPerAnswer));
    switch (true) {
        case movesMadeScore > maxScore:
            return maxScore;
        case movesMadeScore < scoreMetrics.minimumMoveScore:
            return scoreMetrics.minimumMoveScore;
        default:
            return movesMadeScore;
    }
}
exports.getMovesMadeScore = getMovesMadeScore;
