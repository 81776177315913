import React, { useEffect, useState } from "react";
import { AdminPageGeneralFunctions, AdminPageAPICalls, Difficulty, LevelTypes, Puzzle, Level, ExpansionPack, AdminWord, AdminParentCategory, AdminSubCategory } from "@summitrhode/shared";
import { useAuth } from "../../context/authContext";
import { PuzzleBoardProvider } from "../../context/puzzleBoardContext";
import { PuzzleBox } from "../PuzzleScreen/PuzzleBox";
import { cleanPuzzleNameEntry, cleanStringEntry, cleanExpansionPackDetails } from "./UtilityFunctions";
import EditExpansionPackModal from "./EditExpansionPackModal";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Slider,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import { Oval } from "react-loader-spinner";
import { colors } from "@summitrhode/shared";
import { DictionaryEntryModificationModal } from "./DictionaryEntryModificationModal";
import ExpansionPackDetailsModal from "./ExpansionPackDetailsModal";
import { SingleTextInputModal } from "../UtilityComponents/SingleTextInputModal";
import { AdminWordRelationship } from "@summitrhode/shared";

function AdminLevelMakerPage(this: any) {
    const { authenticatedUserGroups, user } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [adminPageAPICalls, setAdminPageAPICalls] = useState<AdminPageAPICalls>(new AdminPageAPICalls(authenticatedUserGroups));
    const [parentCategories, setParentCategories] = useState<AdminParentCategory[]>([]);
    const [subCategories, setSubCategories] = useState<AdminSubCategory[]>([]);
    const [activeParentId, setActiveParentId] = useState<number>(-1);
    const [activeSubId, setActiveSubId] = useState<number>(-1);

    const [makeLevelButtonEnabled, setMakeLevelButtonEnabled] = useState<boolean>(true);
    const [saveNewLevelButtonEnabled, setSaveNewLevelButtonEnabled] = useState<boolean>(false);
    const [updateExistingLevelButtonEnabled, setUpdateExistingLevelButtonEnabled] = useState<boolean>(false);

    // Tracks if the daily, campaign, or expansion level already existed and if it needs to
    // get updated or saved as new
    const [levelAlreadyMadeInDatabase, setLevelAlreadyMadeInDatabase] = useState<boolean>(false);

    const rowCountArray: number[] = [3, 4, 5, 6, 7, 8];
    const columnCountArray: number[] = [3, 4, 5, 6, 7, 8];
    const [rowCount, setRowCount] = useState<number>(-1);
    const [columnCount, setColumnCount] = useState<number>(-1);

    //  Booleans for the type of level being created that is the gatekeeper to the proper buttons
    const levelTypes: { levelType: LevelTypes; name: string }[] = [
        { levelType: LevelTypes.CAMPAIGN, name: LevelTypes[LevelTypes.CAMPAIGN] },
        { levelType: LevelTypes.DAILY, name: LevelTypes[LevelTypes.DAILY] },
        { levelType: LevelTypes.EXPANSION, name: LevelTypes[LevelTypes.EXPANSION] },
    ];

    const [validLevelTypeWasSelected, setValidLevelTypeWasSelected] = useState<boolean>(false);
    const [campaignLevelMakerEnabled, setCampaignLevelMakerEnabled] = useState<boolean>(false);
    const [expansionLevelMakerEnabled, setExpansionLevelMakerEnabled] = useState<boolean>(false);
    const [dailyLevelMakerEnabled, setDailyLevelMakerEnabled] = useState<boolean>(false);

    const [campaignLevels, setCampaignLevels] = useState<Level[]>([]);
    const [dailyLevels, setDailyLevels] = useState<{ levelDate: string; levelNumber: number; name: string }[]>([]);
    const [expansionPacks, setExpansionPacks] = useState<ExpansionPack[]>([]);
    const [selectedExpansionPack, setSelectedExpansionPack] = useState<ExpansionPack | undefined>(undefined);
    const [selectedCampaignLevel, setSelectedCampaignLevel] = useState<number>(-1);
    const [rdsLevelID, setRDSLevelID] = useState<number>(-1);

    //  All the variables for the temporarily made puzzle
    //  queued up to be saved
    const [puzzle, setPuzzle] = useState<Puzzle | undefined>();
    const [puzzleAnswerCoordinates, setPuzzleAnswerCoordinates] = useState<string[]>([]);
    const [puzzleColumns, setPuzzleColumns] = useState<number>(-1);
    const [puzzleDifficulty, setPuzzleDifficulty] = useState<Difficulty | number>(-1);
    const [puzzleLetterArray, setPuzzleLetterArray] = useState<string[]>([]);
    const [puzzleLevelType, setPuzzleLevelType] = useState<LevelTypes | undefined>();
    const [puzzleParentCategoryId, setPuzzleParentCategoryId] = useState<number>(-1);
    const [puzzleRows, setPuzzleRows] = useState<number>(-1);
    const [puzzleSubCategoryId, setPuzzleSubCategoryId] = useState<number>(-1);
    const [puzzleWordIdArray, setPuzzleWordIdArray] = useState<number[]>([]);
    const [puzzleWordArray, setPuzzleWordArray] = useState<AdminWord[]>([]);
    const [userEnteredWords, setUserEnteredWords] = useState<string[]>([]);
    const [userEnteredWordsString, setUserEnteredWordsString] = useState<string>("");
    const [onlyUseUserWords, setOnlyUseUserWords] = useState<boolean>(false);
    const [puzzleName, setPuzzleName] = useState<string>("");
    const [puzzleSubtitle, setPuzzleSubtitle] = useState<string>("");

    // Used for any words that are being kept in the puzzle making process
    // with the check box enabled
    const [retainedPuzzleWordArray, setRetainedPuzzleWordArray] = useState<AdminWord[]>([]);
    //Need this for the react updating on the checkboxes at the word list
    const [retainedPuzzleWordIdArray, setRetainedPuzzleWordIdArray] = useState<number[]>([]);

    const [puzzleWordsRelationships, setPuzzleWordsRelationships] = useState<AdminWordRelationship[]>([]);

    const [characterCountData, setCharacterCountData] = useState<{
        characterCount: number;
        blankCount: number;
        tileCount: number;
    }>({ characterCount: 0, blankCount: 0, tileCount: 0 });

    const [showDictionaryEntryModificationModal, setShowDictionaryEntryModificationModal] = useState<boolean>(false);

    const [showExpansionPackDetailsModal, setShowExpansionPackDetailsModal] = useState<boolean>(false);

    const [enableDictionaryEntryModificationModal, setEnableDictionaryEntryModificationModal] = useState<boolean>(false);

    const difficultyArray: { difficulty: Difficulty; name: string }[] = [
        { difficulty: Difficulty.EASY, name: "Easy" },
        { difficulty: Difficulty.MEDIUM, name: "Medium" },
        { difficulty: Difficulty.HARD, name: "Hard" },
        { difficulty: Difficulty.EXPERT, name: "Expert" },
        { difficulty: Difficulty.GOD, name: "God Mode" },
    ];
    const [difficultySelected, setDifficultySelected] = useState<Difficulty | number>(-1);

    const [percentFilled, setPercentFilled] = useState<number>(-1);

    const [adminPuzzleWasMade, setAdminPuzzleWasMade] = useState<boolean>(false);

    const [pickerDate, setPickerDate] = useState<Dayjs | null>();
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [nextFiveDailyDatesNeeded, setNextFiveDailyDatesNeeded] = useState<string[]>([]);

    //Expansion Pack state variables
    const [updateExpansionPackClicked, setUpdateExpansionPackClicked] = useState<boolean>(false);
    const [createNewExpansionPackClicked, setCreateNewExpansionPackClicked] = useState<boolean>(false);

    const [enablePuzzleNameToolTip, setEnablePuzzleNameToolTip] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;

    useEffect(() => {
        if (authenticatedUserGroups?.isAdmin) {
            //For All Puzzles
            getParentCategories();

            //For Campaigns
            getCampaignLevels();

            // TODO
            // Get Expansion Packs
            getExpansionPacks();

            //For Dailys
            // const nowDate = new Date();
            // const nowDateString = nowDate.toISOString().split("T")[0];
            // setSelectedDate(nowDateString);
            // setPickerDate(nowDate);
            // const month = nowDate.getFullYear + "-" + (nowDate.getMonth() + 1);
            // getDailyLevels(nowDate.toISOString().split("T")[0]);
        }
    }, [authenticatedUserGroups]);

    const getParentCategories = async () => {
        // setParentCategories(await adminPageAPICalls.getParentCategories());
        setParentCategories(await adminPageAPICalls.parentSubXrefs.getActiveParentSubXrefs());
    };

    const getCampaignLevels = async () => {
        setCampaignLevels(await adminPageAPICalls.levels.getCampaignLevels(LevelTypes.CAMPAIGN));
    };

    const getExpansionPacks = async (): Promise<ExpansionPack[]> => {
        const tempPacks = await adminPageAPICalls.levels.getExpansionPackLevels(LevelTypes.EXPANSION);
        setExpansionPacks(tempPacks);
        return tempPacks;
    };

    const getSubCategories = async (parentId: number) => {
        setActiveParentId(parentId);

        parentCategories.forEach((entry) => {
            if (entry.parentId === parentId && entry.subCategories) {
                setSubCategories(entry.subCategories);
            }
        });
    };

    const handleRowCountChange = (e: SelectChangeEvent<number>) => {
        const numRows = e.target.value;
        if (numRows === "----") {
            setRowCount(-1);
            // setPuzzleRows(-1);
            setCharacterCountData({
                characterCount: characterCountData.characterCount,
                blankCount: 0 - characterCountData.characterCount,
                tileCount: 0,
            });
        } else {
            setRowCount(Number(numRows));
            setCharacterCountData({
                characterCount: characterCountData.characterCount,
                blankCount: Number(numRows) * columnCount - characterCountData.characterCount,
                tileCount: Number(numRows) * columnCount,
            });
            // setPuzzleRows(Number(numRows));
        }
    };

    const handleColumnCountChange = (e: SelectChangeEvent<number>) => {
        const numCols = e.target.value;
        if (numCols === "----") {
            setColumnCount(-1);
            // setPuzzleColumns(-1);
            setCharacterCountData({
                characterCount: characterCountData.characterCount,
                blankCount: 0 - characterCountData.characterCount,
                tileCount: 0,
            });
        } else {
            setColumnCount(Number(numCols));
            // setPuzzleColumns(Number(numCols));
            setCharacterCountData({
                characterCount: characterCountData.characterCount,
                blankCount: Number(numCols) * rowCount - characterCountData.characterCount,
                tileCount: Number(numCols) * rowCount,
            });
        }
    };

    const handleDifficultyChange = (e: SelectChangeEvent<number>) => {
        const difficulty = e.target.value;
        if (difficulty === "----") {
            setDifficultySelected(-1);
            setPuzzleDifficulty(-1);
        } else {
            setDifficultySelected(Number(difficulty));
            setPuzzleDifficulty(Number(difficulty));
        }

        if (rdsLevelID > 0) {
            setUpdateExistingLevelButtonEnabled(true);
        }
    };

    const handlePercentFilledChange = (e: Event) => {
        const percent = Number((e.target as HTMLInputElement).value);
        if (percent >= 15 && percent <= 100) {
            setPercentFilled(percent);
        } else {
            setPercentFilled(-1);
        }
    };

    const handleParentChange = (e: SelectChangeEvent<number>) => {
        const parentId = Number(e.target.value);

        // Check if parent is the base entry
        if (parentId) {
            getSubCategories(parentId);
        } else {
            setActiveParentId(-1);
        }

        if (rdsLevelID > 0) {
            setUpdateExistingLevelButtonEnabled(true);
        }

        setMakeLevelButtonEnabled(true);
    };

    const handleAddNewParentCategory = (textInput: string) => {
        adminPageAPICalls.parentCategories.addNewParentCategory(textInput).then(() => {
            getParentCategories();
        });
    };

    const handleAddNewSubCategory = (parentId: number, textInput: string) => {
        adminPageAPICalls.subCategories.addNewSubCategory(parentId, textInput).then(() => {
            getParentCategories().then(() => {
                getSubCategories(parentId);
            });
        });
    };

    const handleSubChange = (e: SelectChangeEvent<number>) => {
        const subId = Number(e.target.value);

        if (subId) {
            setActiveSubId(subId);
        } else {
            setActiveSubId(-1);
        }

        if (rdsLevelID > 0) {
            setUpdateExistingLevelButtonEnabled(true);
        }

        setMakeLevelButtonEnabled(true);
    };

    const handleLevelTypeChange = (e: SelectChangeEvent<unknown>) => {
        const selectedType = e.target.value;

        if (selectedType === LevelTypes.CAMPAIGN) {
            //Check for Campaign
            setPuzzleLevelType(LevelTypes.CAMPAIGN);
            setCampaignLevelMakerEnabled(true);
            setExpansionLevelMakerEnabled(false);
            setDailyLevelMakerEnabled(false);
            setValidLevelTypeWasSelected(true);
        } else if (selectedType === LevelTypes.EXPANSION) {
            //for Expansion
            setPuzzleLevelType(LevelTypes.EXPANSION);
            setCampaignLevelMakerEnabled(false);
            setExpansionLevelMakerEnabled(true);
            setDailyLevelMakerEnabled(false);
            setValidLevelTypeWasSelected(false);
        } else if (selectedType === LevelTypes.DAILY) {
            //for Daily
            setPuzzleLevelType(LevelTypes.DAILY);
            setCampaignLevelMakerEnabled(false);
            setExpansionLevelMakerEnabled(false);
            setDailyLevelMakerEnabled(true);
            setValidLevelTypeWasSelected(true);
            handleLevelSelectChange(e, LevelTypes.DAILY, "TOMORROW");
        } else {
            setPuzzleLevelType(undefined);
            setCampaignLevelMakerEnabled(false);
            setExpansionLevelMakerEnabled(false);
            setDailyLevelMakerEnabled(false);
            setValidLevelTypeWasSelected(false);
        }
        resetPuzzleVariables();
        resetDropDowns();
    };

    const handleExpansionPackChange = (e: React.ChangeEvent<HTMLSelectElement> | undefined, selectedPack: number | undefined = undefined) => {
        if (selectedPack === undefined && e !== undefined) {
            selectedPack = e.target.value as unknown as number;
        }

        resetPuzzleVariables();
        resetDropDowns();

        if (selectedPack === undefined) {
            setSelectedExpansionPack(undefined);
            setValidLevelTypeWasSelected(false);
        } else {
            const packId = selectedPack;
            expansionPacks.forEach((pack) => {
                if (pack.id === packId) {
                    setSelectedExpansionPack(pack);
                    setValidLevelTypeWasSelected(true);

                    // return;
                }
            });
        }
    };

    const CleanUserEnteredWords = () => {
        const cleanWordArray: string[] = [];
        userEnteredWords.forEach((word) => {
            if (word.length > 0) {
                cleanWordArray.push(word);
            }
        });

        return cleanWordArray;
    };

    const handleMakeLevel = async () => {
        if (authenticatedUserGroups?.isAdmin && puzzleLevelType !== undefined) {
            const cleanedUserEnteredWords = CleanUserEnteredWords();
            setUserEnteredWords(cleanedUserEnteredWords);

            const newLevel = await adminPageAPICalls.levels.makeNewLevel(
                activeParentId,
                activeSubId,
                difficultySelected,
                percentFilled,
                rowCount,
                columnCount,
                retainedPuzzleWordArray,
                cleanedUserEnteredWords,
                onlyUseUserWords,
                LevelTypes[puzzleLevelType],
                selectedExpansionPack?.id ? selectedExpansionPack?.id : -1
            );

            if (newLevel === undefined || newLevel.level === undefined || newLevel.level.letters === undefined || newLevel.level.words === undefined || newLevel.level.letters.length === 0) {
                setErrorMessage("There was an error making the puzzle. Maybe there aren't any words in this category?");
                resetPuzzleVariables();
                setShowErrorMessage(true);
                setMakeLevelButtonEnabled(false);
                setLoading(false);
            } else {
                setErrorMessage("");
                setShowErrorMessage(false);
                const wordIdArray: number[] = newLevel.level.words.map((k: { wordId: number; name: string }) => {
                    return k.wordId;
                });

                setPuzzleWordsRelationships(newLevel.puzzleWordsRelationships);

                setEnableDictionaryEntryModificationModal(true);
                newLevel.puzzleWordsRelationships.forEach((entry: { matchingEntries: string | any[] }) => {
                    if (entry.matchingEntries === undefined || entry.matchingEntries.length === 0) {
                        setShowDictionaryEntryModificationModal(true);
                    }
                });

                setPuzzleAnswerCoordinates(newLevel.level.answerCoordinates);
                setPuzzleColumns(newLevel.level.columns);
                setPuzzleDifficulty(newLevel.level.difficulty);
                setPuzzleLetterArray(newLevel.level.letters);
                setPuzzleParentCategoryId(newLevel.level.parentCategoryId ? newLevel.level.parentCategoryId : -1);
                setPuzzleRows(newLevel.level.rows);
                setPuzzleSubCategoryId(newLevel.level.subCategoryId ? newLevel.level.subCategoryId : -1);
                // setPuzzleWordArray(newLevel.level.words);
                setPuzzleWordArray(newLevel.duplicateWordsInPuzzles);
                setPuzzleWordIdArray(wordIdArray);

                // To handle the drop downs on the user page
                setRowCount(newLevel.level.rows);
                setColumnCount(newLevel.level.columns);

                setCharacterCountData({
                    characterCount: characterCountData.characterCount,
                    blankCount: newLevel.level.rows * newLevel.level.columns - characterCountData.characterCount,
                    tileCount: newLevel.level.rows * newLevel.level.columns,
                });

                setAdminPuzzleWasMade(true);

                const tempPuzzle = new Puzzle(
                    newLevel.level.letters,
                    newLevel.level.answerCoordinates,
                    // newLevel.level.parentCategoryId,
                    "FIX ME In AdminLevelMakerPage",
                    // newLevel.level.subCategoryId,
                    "FIX ME In AdminLevelMakerPage",
                    newLevel.level.rows,
                    newLevel.level.columns,
                    newLevel.level.difficulty,
                    true,
                    puzzleName,
                    puzzleSubtitle,
                    newLevel.level.words
                );

                setPuzzle(tempPuzzle);

                if (selectedCampaignLevel > -1 || levelAlreadyMadeInDatabase) {
                    setSaveNewLevelButtonEnabled(false);
                    setUpdateExistingLevelButtonEnabled(true);
                } else {
                    setSaveNewLevelButtonEnabled(validLevelTypeWasSelected);
                    setUpdateExistingLevelButtonEnabled(false);
                }
            }
        }
        setLoading(false);
    };

    const handleSaveLevel = async () => {
        if (authenticatedUserGroups?.isAdmin && puzzleLevelType !== undefined) {
            if (puzzleName.length <= 0) {
                setEnablePuzzleNameToolTip(true);
                return;
            }
            const saveLevel = await adminPageAPICalls.levels.saveNewLevel(
                puzzleAnswerCoordinates,
                puzzleColumns,
                puzzleDifficulty,
                puzzleLetterArray,
                LevelTypes[puzzleLevelType],
                puzzleParentCategoryId,
                puzzleRows,
                puzzleSubCategoryId,
                puzzleWordIdArray,
                puzzleName,
                selectedDate,
                selectedExpansionPack?.id ? selectedExpansionPack?.id : -1,
                puzzleSubtitle
            );
            resetPuzzleVariables();
            resetDropDowns();

            // TODO
            // Change this to be only for campaign levels
            switch (puzzleLevelType) {
                case LevelTypes.CAMPAIGN:
                    getCampaignLevels();
                    break;
                case LevelTypes.DAILY:
                    if (nextFiveDailyDatesNeeded.length > 0) {
                        handleDatePickerChange(
                            nextFiveDailyDatesNeeded.find((d) => d !== selectedDate),
                            false
                        );
                    } else {
                        handleLevelSelectChange(undefined, LevelTypes.DAILY, "TOMORROW");
                    }
                    break;
                case LevelTypes.EXPANSION:
                    updateSelectedExpansionPackOnUpdate();
                    break;
                default:
                    break;
            }
            setSaveNewLevelButtonEnabled(false);
            setUpdateExistingLevelButtonEnabled(false);
        }
    };

    const updateSelectedExpansionPackOnUpdate = async () => {
        // const tempPacks = await getExpansionPacks();
        const pack = expansionPacks.find((pack) => pack.id === selectedExpansionPack?.id);
        setSelectedExpansionPack(pack);
    };

    const handleUpdateLevel = async () => {
        if (authenticatedUserGroups?.isAdmin) {
            const updateLevel = await adminPageAPICalls.levels.updateLevel(
                rdsLevelID,
                puzzleAnswerCoordinates,
                puzzleColumns,
                puzzleDifficulty,
                puzzleLetterArray,
                puzzleLevelType ? LevelTypes[puzzleLevelType] : "CAMPAIGN",
                puzzleParentCategoryId,
                puzzleRows,
                puzzleSubCategoryId,
                puzzleWordIdArray,
                puzzleName,
                selectedExpansionPack?.id ? selectedExpansionPack?.id : -1,
                puzzleSubtitle
            );

            setSaveNewLevelButtonEnabled(false);
            setUpdateExistingLevelButtonEnabled(false);
        }
    };

    const handleExpansionPackUpdate = async (isUpdate: boolean, packName: string, packDescription: string, isAvailableToUsers: boolean, isAvailableToAdmins: boolean) => {
        if (authenticatedUserGroups?.isAdmin) {
            packName = cleanExpansionPackDetails(packName);
            packDescription = cleanExpansionPackDetails(packDescription);

            if (isUpdate && selectedExpansionPack) {
                const updatePack = await adminPageAPICalls.expansionPacks.updateExpansionPack(selectedExpansionPack.id, packName, packDescription, isAvailableToUsers, isAvailableToAdmins);
            }

            if (!isUpdate) {
                const updatePack = await adminPageAPICalls.expansionPacks.addNewExpansionPack(packName, packDescription);
            }
        }
        setUpdateExpansionPackClicked(false);
        setCreateNewExpansionPackClicked(false);
        updateSelectedExpansionPackOnUpdate();
    };

    const handleCheckboxChange = (wordId: number, name: string) => {
        const tempRetainedPuzzleWordArray: { wordId: number; name: string }[] = retainedPuzzleWordArray;
        const tempArray: { wordId: number; name: string; checked?: boolean }[] = puzzleWordArray;

        //Update puzzleWordArray for checkboxes to keep words
        const index = tempArray.findIndex((e) => e.wordId === wordId);
        if (tempArray[index].checked ? tempArray[index].checked : false) {
            tempArray[index].checked = false;
        } else {
            tempArray[index].checked = true;
        }

        //Retained array that goes out to the level maker
        const retainedIndex = tempRetainedPuzzleWordArray.findIndex((e) => e.wordId === wordId);
        if (retainedIndex === -1) {
            tempRetainedPuzzleWordArray.push({
                wordId: wordId,
                name: name,
            });
            // setRetainedPuzzleWordArray(tempRetainedPuzzleWordArray);
        } else {
            tempRetainedPuzzleWordArray.splice(
                tempRetainedPuzzleWordArray.findIndex((e) => e.wordId === wordId),
                1
            );
        }
        setRetainedPuzzleWordArray(tempRetainedPuzzleWordArray);

        setPuzzleWordArray(tempArray);
    };

    const handleUserInputWordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleanWords = cleanStringEntry(e.target.value);
        setUserEnteredWordsString(cleanWords);
        let characterCount = 0;
        const splitArray = cleanWords.split(",");
        splitArray.forEach((word, index, theArray) => {
            word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            theArray[index] = word;
            characterCount += word.length;
        });

        setCharacterCountData({
            characterCount: characterCount,
            blankCount: characterCountData.tileCount - characterCount,
            tileCount: characterCountData.tileCount,
        });
        setUserEnteredWords(splitArray);
        setMakeLevelButtonEnabled(true);
    };

    const handlePuzzleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleanWord = cleanPuzzleNameEntry(e.target.value);
        setPuzzleName(cleanWord);
        setEnablePuzzleNameToolTip(false);
        if (rdsLevelID > 0) {
            setUpdateExistingLevelButtonEnabled(true);
        }
    };

    const handlePuzzleSubtitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleanWord = cleanPuzzleNameEntry(e.target.value);
        setPuzzleSubtitle(cleanWord);
        if (rdsLevelID > 0) {
            setUpdateExistingLevelButtonEnabled(true);
        }
    };

    const handleUserInputWordsCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const wasChecked = e.target.checked;
        setOnlyUseUserWords(wasChecked);
    };

    const resetPuzzleVariables = () => {
        setEnableDictionaryEntryModificationModal(false);
        setLevelAlreadyMadeInDatabase(false);
        setOnlyUseUserWords(false);
        setPuzzleWordsRelationships([]);
        setPuzzleAnswerCoordinates([]);
        setPuzzleColumns(-1);
        setPuzzleDifficulty(puzzleLevelType === LevelTypes.DAILY ? Difficulty.HARD : -1);
        setPuzzleLetterArray([]);
        setPuzzleParentCategoryId(-1);
        setPuzzleRows(-1);
        setPuzzleSubCategoryId(-1);
        setPuzzleWordArray([]);
        setRetainedPuzzleWordArray([]);
        setPuzzle(undefined);
        setPuzzleName("");
        setPuzzleSubtitle("");
        setUserEnteredWords([]);
        setUserEnteredWordsString("");

        setAdminPuzzleWasMade(false);
    };

    const resetDropDowns = () => {
        setColumnCount(-1);
        setRowCount(-1);
        setDifficultySelected(puzzleLevelType === LevelTypes.DAILY ? Difficulty.HARD : -1);
        // setParentCategory("");
        setActiveParentId(-1);
        setActiveSubId(-1);

        setSelectedCampaignLevel(-1);
        setRDSLevelID(-1);
        setSaveNewLevelButtonEnabled(false);
        setUpdateExistingLevelButtonEnabled(false);
    };

    const handleLevelSelectChange = async (e: SelectChangeEvent<unknown> | undefined, levelType: LevelTypes, date: string | undefined = undefined) => {
        resetPuzzleVariables();
        resetDropDowns();

        if (levelType !== LevelTypes.DAILY && e && e.target.value === undefined) {
            return;
        }
        const levelNumber: number = levelType !== LevelTypes.DAILY ? Number(e?.target.value) : -1;
        const tempLevelDetails = await adminPageAPICalls.levels.getLevelDetails(levelType, levelNumber, date, levelType === LevelTypes.EXPANSION ? selectedExpansionPack?.id : undefined);

        if (tempLevelDetails === undefined || tempLevelDetails.answerCoordinateDetails.length === 0) {
            resetPuzzleVariables();
            resetDropDowns();

            return;
        }

        const answerCoordinates = AdminPageGeneralFunctions.convertAnswerCoordinatesToArray(tempLevelDetails.answerCoordinateDetails);

        const levelDetails = tempLevelDetails.levelDetails[0];

        const letterArray = AdminPageGeneralFunctions.convertLetterDetailsToArray(tempLevelDetails.letterDetails);

        const wordArray = AdminPageGeneralFunctions.convertWordDetailsToWordArray(tempLevelDetails.wordDetails);

        if (levelDetails.date) {
            const tempDate = levelDetails.date.split("T")[0];
            setSelectedDate(tempDate);
            setPickerDate(dayjs(tempDate));
        }

        if (tempLevelDetails.nextFiveDailysNeeded) {
            const tempFive = tempLevelDetails.nextFiveDailysNeeded.map((date: { date: string }) => {
                return date.date.split("T")[0];
            });

            setNextFiveDailyDatesNeeded(tempFive);
        }

        setSelectedCampaignLevel(levelNumber);
        setRDSLevelID(levelDetails.levelId);
        setLevelAlreadyMadeInDatabase(true);

        setPuzzleAnswerCoordinates(answerCoordinates);
        setPuzzleColumns(levelDetails.numColumns);
        setPuzzleDifficulty(levelDetails.difficulty);
        setPuzzleLetterArray(letterArray);
        setPuzzleParentCategoryId(levelDetails.parentCategoryId);
        setPuzzleRows(levelDetails.numRows);
        setPuzzleSubCategoryId(levelDetails.subCategoryId);
        setPuzzleWordArray(wordArray);
        setPuzzleName(levelDetails.levelName ? levelDetails.levelName : "");
        setPuzzleSubtitle(levelDetails.levelSubtitle ? levelDetails.levelSubtitle : "");

        setAdminPuzzleWasMade(true);

        //Set the values that populate the drop downs
        setColumnCount(levelDetails.numColumns);
        setRowCount(levelDetails.numRows);
        setDifficultySelected(levelDetails.difficulty);
        const tempParentCategoryId = levelDetails.parentCategoryId;
        const tempParentCategory = parentCategories.find((p) => p.parentId === tempParentCategoryId);
        // setParentCategory(tempParentCategory?.name ? tempParentCategory.name : "");
        setActiveParentId(tempParentCategoryId);
        await getSubCategories(tempParentCategoryId).then(() => {
            setActiveSubId(levelDetails.subCategoryId);
        });

        const tempPuzzle = new Puzzle(
            letterArray,
            answerCoordinates,
            // levelDetails.parentCategoryId,
            "FIX ME In AdminLevelMakerPage",
            // levelDetails.subCategoryId,
            "FIX ME In AdminLevelMakerPage",
            levelDetails.numRows,
            levelDetails.numColumns,
            levelDetails.difficulty,
            true,
            "",
            "",
            wordArray
        );

        setPuzzle(tempPuzzle);

        setSaveNewLevelButtonEnabled(false);
        setUpdateExistingLevelButtonEnabled(false);
        setRetainedPuzzleWordArray([]);
        setUserEnteredWordsString("");
        setUserEnteredWords([]);
    };

    const handleDatePickerChange = (date: any, fromDatePicker: boolean = true) => {
        if (date === null) return;
        // if (date.getMonth() !== pickerDate.getMonth()) {
        //   const month = date.getFullYear + "-" + (date.getMonth() + 1);
        //   getDailyLevels(month);
        // }
        // setSelectedDate(date.toISOString().split("T")[0]);
        // setPickerDate(date);
        if (fromDatePicker) {
            let day = date.$D;
            if (day < 10) {
                day = "0" + day;
            }
            let month = date.$M + 1;
            if (month < 10) {
                month = "0" + month;
            }
            const year = date.$y;
            date = "" + year + "-" + month + "-" + day;
        }
        setPickerDate(dayjs(date));
        setSelectedDate(date);
        handleLevelSelectChange(date, LevelTypes.DAILY, date);
    };

    if (authenticatedUserGroups?.isAdmin) {
        return (
            <div style={{ backgroundColor: colors.white }}>
                <div>
                    <FormControl sx={{ minWidth: "200px", maxWidth: "400px", margin: "10px" }}>
                        <InputLabel id="Level-Type-Select-Label">Level Type</InputLabel>
                        <Select defaultValue={""} id="Level-Type-Select" label="Level Type" labelId="Level-Type-Select-Label" onChange={(e) => handleLevelTypeChange(e)}>
                            <MenuItem>----</MenuItem>
                            {levelTypes.map((option) => (
                                <MenuItem key={option.levelType} value={option.levelType}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {campaignLevelMakerEnabled && (
                        <FormControl
                            sx={{
                                minWidth: "100px",
                                maxWidth: "400px",
                                margin: "10px",
                            }}
                        >
                            <InputLabel id="Campaign-Level-Select-Label">Level #</InputLabel>
                            <Select
                                defaultValue={""}
                                id="Campaign-Level-Select"
                                label="Campaign Level"
                                labelId="Campaign-Level-Select-Label"
                                onChange={(e) => handleLevelSelectChange(e, LevelTypes.CAMPAIGN)}
                            >
                                <MenuItem>----</MenuItem>
                                {campaignLevels.map((option) => (
                                    <MenuItem key={option.campaignId} value={option.levelNumber}>
                                        {option.levelNumber}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
                {expansionLevelMakerEnabled && (
                    <div>
                        <Button
                            key="UpdateExpansionPack"
                            disabled={!validLevelTypeWasSelected}
                            onClick={() => setUpdateExpansionPackClicked(true)}
                            variant="contained"
                            sx={{ minWidth: "50px", maxWidth: "350px", margin: "10px" }}
                        >
                            Update {selectedExpansionPack?.name}
                        </Button>
                        <Button key="AddNewExpansionPack" onClick={() => setCreateNewExpansionPackClicked(true)} variant="contained" sx={{ minWidth: "50px", maxWidth: "350px", margin: "10px" }}>
                            Create New Expansion Pack
                        </Button>
                        <Box sx={{ maxWidth: 500 }}>
                            <FormControl
                                // fullWidth
                                sx={{
                                    minWidth: "200px",
                                    maxWidth: "400px",
                                    marginTop: "10px",
                                }}
                            >
                                <InputLabel id="Expansion-Pack-Select-Label">Expansion Pack</InputLabel>
                                <Select defaultValue={""} id="Expansion-Pack-Select" label="Expansion Pack" labelId="Expansion-Pack-Select-Label" onChange={(e: any) => handleExpansionPackChange(e)}>
                                    <MenuItem>----</MenuItem>
                                    {expansionPacks.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {selectedExpansionPack && (
                                <>
                                    <FormControl
                                        sx={{
                                            minWidth: "100px",
                                            maxWidth: "400px",
                                            margin: "10px",
                                        }}
                                    >
                                        <InputLabel id="Expansion-Pack-Level-Select-Label">Level #</InputLabel>
                                        <Select
                                            defaultValue={""}
                                            id="Expansion-Pack-Level-Select"
                                            label="Expansion-Pack-Level"
                                            labelId="Expansion-Pack-Level-Select-Label"
                                            onChange={(e) => handleLevelSelectChange(e, LevelTypes.EXPANSION)}
                                        >
                                            <MenuItem>----</MenuItem>
                                            {selectedExpansionPack?.levels?.map((option) => (
                                                <MenuItem key={option.id} value={option.levelNumber}>
                                                    {option.levelNumber}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <ExpansionPackDetailsModal
                                        open={showExpansionPackDetailsModal}
                                        packDetails={selectedExpansionPack.levels ? selectedExpansionPack.levels : []}
                                        handleClose={() => setShowExpansionPackDetailsModal(false)}
                                        handleSave={(packDetails) => {
                                            setLoading(true);
                                            const tempSelectedPack = selectedExpansionPack;
                                            setSelectedExpansionPack(undefined);
                                            adminPageAPICalls.expansionPacks.updateExpansionPackLevelNumberArrangement(packDetails).then(() =>
                                                getExpansionPacks().then((packs) =>
                                                    packs.forEach((pack) => {
                                                        if (pack.id === tempSelectedPack.id) {
                                                            setSelectedExpansionPack(pack);
                                                        }
                                                    })
                                                )
                                            );

                                            handleLevelSelectChange(undefined, LevelTypes.EXPANSION, undefined);
                                            setLoading(false);
                                            setShowExpansionPackDetailsModal(false);
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            setShowExpansionPackDetailsModal(true);
                                        }}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Show Pack Details
                                    </Button>
                                </>
                            )}
                        </Box>

                        <EditExpansionPackModal
                            createExpansionPackClicked={createNewExpansionPackClicked}
                            updateExpansionPackClicked={updateExpansionPackClicked}
                            currentPackDetails={selectedExpansionPack}
                            handleClose={(saved: boolean) => {
                                if (saved) {
                                    getExpansionPacks();
                                }
                                setCreateNewExpansionPackClicked(false);
                                setUpdateExpansionPackClicked(false);
                            }}
                            handleSave={handleExpansionPackUpdate}
                        />
                    </div>
                )}
                {validLevelTypeWasSelected && (
                    <div>
                        {dailyLevelMakerEnabled && (
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={pickerDate}
                                        onChange={(date: any) => {
                                            handleDatePickerChange(date);
                                        }}
                                        // name="DateSelected"
                                        // format="yyyy-MM-dd"
                                    />
                                </LocalizationProvider>
                                {nextFiveDailyDatesNeeded.map((entry) => (
                                    <Button variant="contained" disabled={entry === selectedDate} onClick={() => handleDatePickerChange(entry, false)} sx={{ margin: "5px" }}>
                                        {entry}
                                    </Button>
                                ))}
                            </>
                        )}
                        <div>
                            <FormControl sx={{ minWidth: "100px", maxWidth: "400px", margin: "10px" }}>
                                <InputLabel id="Row-Count-Select-Label">Rows</InputLabel>
                                <Select id="Row-Count-Select" label="Rows" labelId="Row-Count-Select-Label" onChange={(e) => handleRowCountChange(e)} value={rowCount > -1 ? rowCount : ""}>
                                    <MenuItem>----</MenuItem>
                                    {rowCountArray.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: "105px", maxWidth: "400px", margin: "10px" }}>
                                <InputLabel id="Column-Count-Select-Label">Columns</InputLabel>
                                <Select
                                    id="Column-Count-Select"
                                    label="Columns"
                                    labelId="Column-Count-Select-Label"
                                    onChange={(e) => handleColumnCountChange(e)}
                                    value={columnCount > -1 ? columnCount : ""}
                                >
                                    <MenuItem>----</MenuItem>
                                    {columnCountArray.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* </div>
              <div> */}
                            <FormControl sx={{ minWidth: "100px", maxWidth: "400px", margin: "10px" }}>
                                <InputLabel id="Difficulty-Select-Label">Difficulty</InputLabel>
                                <Select
                                    id="Difficulty-Select"
                                    label="Difficulty"
                                    labelId="Difficulty-Select-Label"
                                    onChange={(e) => handleDifficultyChange(e)}
                                    value={puzzleDifficulty > -1 ? puzzleDifficulty : ""}
                                >
                                    <MenuItem>----</MenuItem>
                                    {difficultyArray.map((option) => (
                                        <MenuItem key={option.difficulty} value={option.difficulty}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: "100px", maxWidth: "400px", margin: "10px" }}>
                                <Typography gutterBottom>Percent Filled</Typography>
                                <Slider
                                    valueLabelDisplay="auto"
                                    // slots={{
                                    //   valueLabel: ValueLabelComponent,
                                    // }}
                                    aria-label="custom thumb label"
                                    defaultValue={50}
                                    min={15}
                                    max={100}
                                    onChange={(e) => handlePercentFilledChange(e)}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl sx={{ minWidth: "175px", maxWidth: "400px", margin: "10px" }}>
                                <InputLabel id="Parent-Category-Select-Label">Parent Category</InputLabel>
                                <Select
                                    id="Parent-Category-Select"
                                    label="Parent-Category"
                                    labelId="Parent-Category-Select-Label"
                                    onChange={(e) => handleParentChange(e)}
                                    value={activeParentId > -1 ? activeParentId : ""}
                                >
                                    <MenuItem>----</MenuItem>
                                    {parentCategories.map((option) => (
                                        <MenuItem key={option.parentId} value={option.parentId}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* <SingleTextInputModal
                  buttonText="Add New Parent"
                  enabled={true}
                  handleSave={(textInput) => {
                    handleAddNewParentCategory(textInput);
                  }}
                  label="Parent Category"
                  title="New Parent Category"
                /> */}
                            </FormControl>
                            {/* </div>
              <div> */}
                            <FormControl sx={{ minWidth: "175px", maxWidth: "400px", margin: "10px" }}>
                                <InputLabel id="Sub-Category-Select-Label">Sub Category</InputLabel>
                                <Select
                                    id="Sub-Category-Select"
                                    label="Sub-Category"
                                    labelId="Sub-Category-Select-Label"
                                    onChange={(e) => handleSubChange(e)}
                                    value={activeSubId > -1 ? activeSubId : ""}
                                >
                                    <MenuItem>----</MenuItem>
                                    {subCategories.map((option) => (
                                        <MenuItem key={option.subId} value={option.subId}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* <SingleTextInputModal
                  buttonText="Add New Sub"
                  enabled={true}
                  handleSave={(textInput) => {
                    handleAddNewSubCategory(activeParentId, textInput);
                  }}
                  label="Sub Category"
                  title="New Sub Category"
                /> */}
                            </FormControl>
                        </div>
                        <div>
                            <TextField
                                // defaultValue={""}
                                id="Word-Input"
                                label="Words (comma seperated)"
                                onChange={handleUserInputWordsChange}
                                value={userEnteredWordsString}
                                sx={{ minWidth: "250px", maxWidth: "400px", margin: "10px" }}
                            />
                            <Checkbox
                                checked={onlyUseUserWords}
                                id={"onlyUseUserWordsCheckbox"}
                                onChange={(e) => handleUserInputWordsCheckboxChange(e)}
                                sx={{ marginTop: "15px", marginLeft: "-7px" }}
                            />
                            <div>
                                C: {characterCountData.characterCount} / B: {characterCountData.blankCount} / T: {characterCountData.tileCount}
                            </div>
                        </div>
                        <div>
                            <Tooltip title={"Make sure you have a puzzle name!"} open={enablePuzzleNameToolTip} sx={{ textAlign: "center" }}>
                                <TextField
                                    id="PuzzleName-Input"
                                    label="Puzzle Name"
                                    onChange={handlePuzzleNameInputChange}
                                    value={puzzleName}
                                    sx={{ minWidth: "150px", maxWidth: "400px", margin: "10px" }}
                                />
                            </Tooltip>
                            {/* </div>
              <div> */}
                            <TextField
                                id="PuzzleSubtitle-Input"
                                label="Puzzle Subtitle"
                                onChange={handlePuzzleSubtitleInputChange}
                                value={puzzleSubtitle}
                                sx={{ minWidth: "150px", maxWidth: "400px", margin: "10px" }}
                            />
                        </div>
                        <div>
                            <Button
                                key="MakeLevelButton"
                                onClick={() => {
                                    setLoading(true);
                                    handleMakeLevel();
                                }}
                                disabled={!makeLevelButtonEnabled || loading}
                                variant="contained"
                                sx={{ minWidth: "50px", maxWidth: "200px", margin: "10px" }}
                            >
                                Generate Level
                            </Button>
                            {/* </div>
              <div> */}
                            <Button
                                key="UpdateLevelButton"
                                onClick={() => handleUpdateLevel()}
                                disabled={!updateExistingLevelButtonEnabled || loading}
                                variant="contained"
                                sx={{ minWidth: "50px", maxWidth: "200px", margin: "10px" }}
                            >
                                Update Level {selectedCampaignLevel > -1 ? selectedCampaignLevel : ""}
                            </Button>
                            {/* </div>
              <div> */}
                            <Button
                                key="SaveLevelButton"
                                onClick={() => handleSaveLevel()}
                                disabled={!saveNewLevelButtonEnabled || loading}
                                variant="contained"
                                sx={{ minWidth: "50px", maxWidth: "200px", margin: "10px" }}
                            >
                                Save New Level
                            </Button>
                        </div>
                        {loading && <Oval ariaLabel="loading-indicator" height={100} width={100} strokeWidth={5} strokeWidthSecondary={1} color={colors.primary} secondaryColor="white" />}

                        {!loading && (
                            <div>
                                {showErrorMessage && <Typography>{errorMessage}</Typography>}
                                <PuzzleBoardProvider>
                                    {puzzle && (
                                        <div>
                                            {retainedPuzzleWordArray && (
                                                <>
                                                    {enableDictionaryEntryModificationModal && (
                                                        <>
                                                            <DictionaryEntryModificationModal
                                                                addNewParentCategory={(textInput) => handleAddNewParentCategory(textInput)}
                                                                addNewSubCategory={(parentId, textInput) => handleAddNewSubCategory(parentId, textInput)}
                                                                defaultWordIndex={0}
                                                                loading={loading}
                                                                open={showDictionaryEntryModificationModal}
                                                                onClose={() => setShowDictionaryEntryModificationModal(false)}
                                                                onSave={(changedWordRelationships) => {
                                                                    setLoading(true);
                                                                    adminPageAPICalls.parentSubXrefs.saveWordRelationshipChanges(changedWordRelationships).then(() => {
                                                                        setLoading(false);
                                                                    });
                                                                    setEnableDictionaryEntryModificationModal(false);
                                                                }}
                                                                list={["test1", "test2", "test3"]}
                                                                parentCategories={parentCategories}
                                                                puzzleWordsRelationships={puzzleWordsRelationships}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    setShowDictionaryEntryModificationModal(true);
                                                                }}
                                                                variant="contained"
                                                                color="secondary"
                                                            >
                                                                Show Word Dictionary Relationships
                                                            </Button>
                                                        </>
                                                    )}
                                                    <List
                                                        sx={{
                                                            // width: "100%",
                                                            maxWidth: mobileView ? "100vw" : "50vw",
                                                            // bgcolor: "beige",
                                                            // margin: "5px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            // justifyContent: "center",
                                                        }}
                                                    >
                                                        {puzzleWordArray.map((word) => (
                                                            <ListItem
                                                                key={word.wordId}
                                                                // disablePadding
                                                                sx={{
                                                                    // minWidth: mobileView ? "30vw" : "10vw",
                                                                    width: "auto",
                                                                }}
                                                            >
                                                                <Paper
                                                                    sx={{
                                                                        alignItems: "center",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "center",
                                                                        minWidth: mobileView ? "35vw" : "15vw",
                                                                        // maxWidth: mobileView ? "40vw" : "10vw",
                                                                    }}
                                                                >
                                                                    <ListItemButton
                                                                        // role={undefined}
                                                                        id={word.wordId + ""}
                                                                        dense
                                                                        disableRipple
                                                                    >
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                defaultChecked={retainedPuzzleWordArray.findIndex((e) => e.wordId === word.wordId) > -1}
                                                                                checked={word.checked}
                                                                                // disableRipple
                                                                                tabIndex={-1}
                                                                                onChange={() => handleCheckboxChange(word.wordId, word.name)}
                                                                                sx={{ margin: 0 }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText id={word.wordId + ""} primary={word.nameWithCount ? word.nameWithCount : word.name} unselectable="on" />
                                                                    </ListItemButton>
                                                                </Paper>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </>
                                            )}
                                            {mobileView && (
                                                <Paper
                                                    sx={{
                                                        width: "95vw",
                                                        maxWidth: "1000px",
                                                        height: "100vw",
                                                        maxHeight: "105vw",
                                                        borderRadius: "5%",
                                                        // border: "1px solid black",
                                                        // padding: "2vw",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        // boxSizing:"border-box",
                                                        backgroundColor: colors.tertiary + "99",
                                                    }}
                                                >
                                                    <PuzzleBox
                                                        pausePuzzle={false}
                                                        puzzle={puzzle}
                                                        startPuzzle={true}
                                                        cachedPuzzleBoard={undefined}
                                                        bigHintEngaged={false}
                                                        checkAllEngaged={false}
                                                        revealPuzzleEngaged={false}
                                                        toggleBigHintButton={() => null}
                                                        littleHintEngaged={false}
                                                        toggleLittleHintButton={() => null}
                                                        toggleCheckAllButton={() => null}
                                                        toggleRevealPuzzleButton={() => null}
                                                        completeLevel={() => {}}
                                                        isMobile={mobileView}
                                                    />
                                                </Paper>
                                            )}
                                            {!mobileView && (
                                                <Paper
                                                    sx={{
                                                        width: "55vh",
                                                        maxWidth: "1000px",
                                                        height: "55vh",
                                                        maxHeight: "1000px",
                                                        borderRadius: "5%",
                                                        // border: "1px solid black",
                                                        padding: "4%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        backgroundColor: colors.tertiary + "99",
                                                    }}
                                                >
                                                    <PuzzleBox
                                                        pausePuzzle={false}
                                                        puzzle={puzzle}
                                                        startPuzzle={true}
                                                        bigHintEngaged={false}
                                                        cachedPuzzleBoard={undefined}
                                                        checkAllEngaged={false}
                                                        revealPuzzleEngaged={false}
                                                        toggleBigHintButton={() => null}
                                                        littleHintEngaged={false}
                                                        toggleLittleHintButton={() => null}
                                                        toggleCheckAllButton={() => null}
                                                        toggleRevealPuzzleButton={() => null}
                                                        completeLevel={() => {}}
                                                        isMobile={mobileView}
                                                    />
                                                </Paper>
                                            )}
                                        </div>
                                    )}
                                </PuzzleBoardProvider>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        return <div>You shouldn't be here!</div>;
    }
}

export default AdminLevelMakerPage;
