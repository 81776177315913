import { Box, Button, Typography, Card, CardMedia, CardContent, Icon, Paper, Badge } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AccountBox, People, Settings } from "@mui/icons-material";
import { colors } from "@summitrhode/shared";
import { useAuth } from "../../context/authContext";

interface Props {
    enabled: boolean;
    icon?: any;
    image?: string;
    isMobile: boolean;
    path: string;
    size: Sizes;
    title?: string;
    indicatorCount?: number;
}

enum Sizes {
    Full,
    Half,
    Quarter,
}

export function ModuleCard({ enabled, icon, image, indicatorCount = 0, isMobile, path, size, title }: Props) {
    const navigate = useNavigate();
    const { sessionInfo } = useAuth();

    const moduleCardClicked = () => {
        if (path.length > 1) {
            if (path === "/profile") {
                navigate("/profile", { state: { profileToView: sessionInfo?.sub } });
            } else if (path === "/friends") {
                navigate("/friends", { state: { profileToView: sessionInfo?.sub } });
            } else {
                navigate(path);
            }
        }
    };

    if (size === Sizes.Full) {
        return (
            // <Button
            //   className="container"
            //   sx={{ width: "49%", maxHeight: "150px", borderRadius: "5%" }}
            //   onClick={moduleCardClicked}
            // >
            //   <Card
            //     sx={{
            //       width: "100%",
            //       height: "100%",
            //       pointerEvents: "none",
            //     }}
            //     elevation={5}
            //   >
            //     {image && image !== "coming soon" && (
            //       <CardMedia
            //         component="img"
            //         // alt="green iguana"
            //         height="60%"
            //         image={image}
            //         sx={{ maxHeight: `${150 * 0.6}px` }}
            //       />
            //     )}
            //     {image && image === "coming soon" && (
            //       <CardMedia
            //         component="img"
            //         alt="COMING SOON"
            //         height="60%"
            //         sx={{
            //           alignItems: "center",
            //           backgroundColor: colors.grayscaleShade,
            //           // color: colors.white,
            //           justifyContent: "center",
            //           textAlign: "center",
            //           minHeight: "60%",
            //         }}
            //         // image={image}
            //       />
            //     )}
            //     <CardContent>
            //       <Typography variant="body1" sx={{ textAlign: "center" }}>
            //         {title}
            //       </Typography>
            //     </CardContent>
            //   </Card>
            // </Button>
            <Button
                variant="contained"
                disabled={!enabled}
                startIcon={icon}
                color="secondary"
                onClick={moduleCardClicked}
                sx={{
                    fontSize: "125%",
                    width: "49%",
                    height: "10vh",
                    maxHeight: "150px",
                    borderRadius: 3,
                    border: 2,
                    borderColor: colors.accentShade,
                    boxShadow: 5,
                }}
            >
                {title}
            </Button>
        );
    } else if (size === Sizes.Half) {
        return (
            // <Button sx={{ width: "100%" }} onClick={moduleCardClicked}>
            //   <Card
            //     sx={{
            //       width: "100%",
            //       maxHeight: "65px",
            //       pointerEvents: "none",
            //     }}
            //     elevation={5}
            //   >
            //     <CardContent
            //       sx={{
            //         alignItems: "center",
            //         display: "flex",
            //         flexDirection: "row",
            //         justifyContent: "center",
            //       }}
            //     >
            //       {icon === "Profile" && <AccountBox />}
            //       <Icon
            //         baseClassName="fas"
            //         className="fa-plus-circle"
            //         fontSize="small"
            //       />
            //       <Typography variant="body2" sx={{ textAlign: "center" }}>
            //         {title}
            //       </Typography>
            //     </CardContent>
            //   </Card>
            // </Button>
            <Button
                variant="contained"
                disabled={!enabled}
                color="secondary"
                onClick={moduleCardClicked}
                sx={{
                    width: "100%",
                    height: "5vh",
                    maxHeight: "65px",
                    borderRadius:2.5,
                    border: 2,
                    borderColor: colors.accentShade,
                    boxShadow: 5,
                }}
            >
                {icon}
                {title}
            </Button>
        );
    } else {
        return (
            <>
                {indicatorCount > 0 && (
                    <Badge badgeContent={indicatorCount} color="primary">
                        <Button
                            variant="contained"
                            disabled={!enabled}
                            color="secondary"
                            sx={{
                                width: "45%",
                                border: 2,
                                borderColor: colors.accentShade,
                                boxShadow: 5,
                            }}
                            onClick={moduleCardClicked}
                        >
                            {icon}
                        </Button>
                    </Badge>
                )}
                {indicatorCount === 0 && (
                    <Button
                        variant="contained"
                        disabled={!enabled}
                        color="secondary"
                        sx={{
                            width: "45%",
                            border: 2,
                            borderColor: colors.accentShade,
                            boxShadow: 5,
                        }}
                        onClick={moduleCardClicked}
                    >
                        {icon}
                    </Button>
                )}
            </>
        );
    }
}
