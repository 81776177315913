"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminParentSubXref = void 0;
class AdminParentSubXref {
    constructor(parentID, parentName, subCategories) {
        this.parentID = parentID;
        this.parentName = parentName;
        this.subCategories = subCategories;
    }
}
exports.AdminParentSubXref = AdminParentSubXref;
