"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParentCategories = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
class ParentCategories {
    constructor(authenticatedUserGroups) {
        this.authenticatedUserGroups = authenticatedUserGroups;
    }
    async getParentCategories() {
        if (this.authenticatedUserGroups?.isAdmin) {
            let path = "/items/getEntries";
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            return JSON.parse(await response.body.text()).parents;
        }
        return [];
    }
    async addNewParentCategory(insertString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "InsertNewParentCategory",
                    insertString: insertString,
                }));
                const response = await restOperation.response;
                console.debug("Parent inserted with ID: ", response);
                return JSON.parse(await response.body.text()).parentList;
            }
            catch (e) {
                console.error("Error adding parent: ", e);
            }
        }
        return [];
    }
    async updateParentCategory(parentId, updateString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateParentCategory",
                    parentCategoryId: parentId,
                    updateString: updateString,
                }));
                const response = await restOperation.response;
                console.debug("Parent updated with ID: ", response);
                return;
            }
            catch (e) {
                console.error("Error updating parent: ", e);
                return;
            }
        }
        return;
    }
}
exports.ParentCategories = ParentCategories;
