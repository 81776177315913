"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameState = void 0;
var GameState;
(function (GameState) {
    GameState[GameState["PLAYING"] = 0] = "PLAYING";
    GameState[GameState["COMPLETE"] = 1] = "COMPLETE";
    GameState[GameState["ONE_STAR"] = 2] = "ONE_STAR";
    GameState[GameState["TWO_STAR"] = 3] = "TWO_STAR";
    GameState[GameState["THREE_STAR"] = 4] = "THREE_STAR";
    GameState[GameState["NO_STAR"] = 5] = "NO_STAR";
})(GameState || (exports.GameState = GameState = {}));
