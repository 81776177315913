interface Props {
  fillColor: string;
  accentColor: string;
  outterWidth: string;
}

export function UpArrow({
  fillColor,
  accentColor,
  outterWidth,
}: Props) {
  return (
    <svg
      width={outterWidth}
      height={outterWidth}
      viewBox="0 0 604 663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M266.27 62.0499C282.023 34.5705 321.658 34.5706 337.411 62.0499L528.05 394.61C543.719 421.943 523.986 456 492.48 456H111.201C79.6954 456 59.9623 421.943 75.631 394.61L266.27 62.0499Z"
        fill={accentColor}
      />
      <rect
        x="213"
        y="304"
        width="177"
        height="358.628"
        rx="62"
        fill={accentColor}
      />
      <path
        d="M270.814 83.483C284.645 59.3548 319.447 59.3547 333.278 83.483L507.611 387.596C521.368 411.596 504.042 441.5 476.378 441.5H127.714C100.05 441.5 82.7236 411.596 96.4815 387.596L270.814 83.483Z"
        fill={fillColor}
      />
      <rect
        x="229"
        y="347"
        width="146.584"
        height="297"
        rx="45"
        fill={fillColor}
      />
    </svg>
  );
}
