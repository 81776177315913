"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompleteLevelStats = void 0;
const GameState_1 = require("./GameState");
class CompleteLevelStats {
    constructor() {
        this.gameState = GameState_1.GameState.PLAYING;
        this.moveCount = 0;
        this.secondsTaken = 0;
    }
}
exports.CompleteLevelStats = CompleteLevelStats;
