import React, { useEffect, useState } from "react";
import { colors, Piece, PieceState } from "@summitrhode/shared";
import "../../css/Tile.css";
import { UpArrow } from "../Arrows/UpArrow";
import { DownArrow } from "../Arrows/DownArrow";
import { RightArrow } from "../Arrows/RightArrow";
import { LeftArrow } from "../Arrows/LeftArrow";
import { Paper, Typography } from "@mui/material";

interface Props {
    piece: Piece;
    grabNodeCallBack: (piece: Piece) => void;
    moveNodeCallBack: (piece: Piece) => void;
    releaseNodeCallBack: (piece: Piece) => void;
    columnRowRatio: number;
    isMobile: boolean;
}

export function Tile(props: Props) {
    const [piece, setPiece] = useState<Piece>(props.piece);
    const [isFlashing, setIsFlashing] = useState<boolean>(false);

    useEffect(() => {
        setPiece(props.piece);
    }, [props]);

    useEffect(() => {
        if (props.piece.isFlashing) {
            setTimeout(
                function () {
                    setIsFlashing(!isFlashing);
                }.bind(isFlashing),
                300
            );
            props.piece.flashColor();
        }
    }, [props.piece.isFlashing, isFlashing]);

    const preventDragHandler = (e: any) => {
        console.debug("drag");
        e.preventDefault();
    };

    return (
        <Paper
            className="piece"
            data-board-index={props.piece.boardIndex}
            elevation={5}
            sx={{
                background: piece.backgroundColor,
                cursor: "pointer",
                touchAction: "none",

                border: props.isMobile ? `${6 / props.columnRowRatio}vw solid ${piece.accentColor}` : `${3 / props.columnRowRatio}vh solid ${piece.accentColor}`,

                borderRadius: props.isMobile ? `${20 / props.columnRowRatio}vw` : `${10 / props.columnRowRatio}vh`,
                outline: "none",
                width: props.isMobile ? `${85 / props.columnRowRatio}vw` : `${45 / props.columnRowRatio}vh`,
                height: props.isMobile ? `${85 / props.columnRowRatio}vw` : `${45 / props.columnRowRatio}vh`,
                margin: props.isMobile ? `${4 / props.columnRowRatio}vw` : `${2 / props.columnRowRatio}vh`,

                justifyContent: "center",
                alignItems: "center",
                userSelect: "none",
                display: "grid",
            }}
            key={piece.id()}
            onPointerDown={(e) => props.grabNodeCallBack(piece)}
            onMouseEnter={(e) => props.moveNodeCallBack(piece)}
            onMouseOver={(e) => props.moveNodeCallBack(piece)}
            onPointerUp={(e) => props.releaseNodeCallBack(piece)}
            onDragStart={(e) => preventDragHandler(e)}
        >
            <div
                className="letter-container"
                style={{
                    outline: "none",
                    color: piece.textColor,
                    textAlign: "center",
                    alignItems: "center",

                    gridColumn: 1,
                    gridRow: 1,
                    userSelect: "none",
                }}
            >
                <Typography
                    className="letter-piece"
                    unselectable="on"
                    style={{
                        userSelect: "none",
                        fontSize: props.isMobile ? `${40 / props.columnRowRatio}vw` : `${18 / props.columnRowRatio}vh`,
                    }}
                >
                    {piece.letter.toUpperCase()}
                </Typography>
            </div>
            {piece.image && (
                <div
                    className={piece.className}
                    style={{
                        pointerEvents: "none",
                    }}
                >
                    {piece.state === PieceState.UP && (
                        <UpArrow
                            fillColor={piece.arrowFillColor}
                            accentColor={piece.arrowAccentColor}
                            outterWidth={props.isMobile ? `${43.33 / props.columnRowRatio}vw` : `${24 / props.columnRowRatio}vh`}
                        />
                    )}
                    {piece.state === PieceState.DOWN && (
                        <DownArrow
                            fillColor={piece.arrowFillColor}
                            accentColor={piece.arrowAccentColor}
                            outterWidth={props.isMobile ? `${43.33 / props.columnRowRatio}vw` : `${24 / props.columnRowRatio}vh`}
                        />
                    )}
                    {piece.state === PieceState.RIGHT && (
                        <RightArrow
                            fillColor={piece.arrowFillColor}
                            accentColor={piece.arrowAccentColor}
                            outterWidth={props.isMobile ? `${43.33 / props.columnRowRatio}vw` : `${24 / props.columnRowRatio}vh`}
                        />
                    )}
                    {piece.state === PieceState.LEFT && (
                        <LeftArrow
                            fillColor={piece.arrowFillColor}
                            accentColor={piece.arrowAccentColor}
                            outterWidth={props.isMobile ? `${43.33 / props.columnRowRatio}vw` : `${24 / props.columnRowRatio}vh`}
                        />
                    )}
                </div>
            )}
        </Paper>
    );
}
