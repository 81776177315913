import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { LoginModal } from "./LoginModal";
import { useAuth } from "../../context/authContext";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { IAuthenticatedUserGroups, colors, getUserGroups, getUserProfileInformation, componentColors, isUserLoggedOn } from "@summitrhode/shared/";
import { useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { Draw } from "@mui/icons-material";

const appName = "Drawing Blanks";

const defaultUrl = "/";
//TODO implement Profile page
// const settings = ["Profile", "Logout"];
const settings = ["Logout"];

const ResponsiveAppBar = () => {
    const { pathwordUsername, updatePathWordUsername, updateAuthenticatedUserGroups, signOut } = useAuth();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [pages, setPages] = useState<string[]>([]);
    const [urls, setUrls] = useState<string[]>([]);
    const [authenticatedUserGroups, setAuthenticatedUserGroups] = useState<IAuthenticatedUserGroups>();
    const [userLoggedOn, setUserLoggedOn] = useState<Boolean>(false);
    const navigate = useNavigate();

    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;

    let height = 3;
    let maxHeight = 62.5;
    let minHeight = 62.5;

    if (mobileView) {
        height = 7.5;
        maxHeight = 80;
        minHeight = 40;
    }

    useEffect(() => {
        async function asyncFunctions() {
            await isUserLoggedOn().then((result: Boolean) => setUserLoggedOn(result));
            try {
                await getUserGroups().then((userGroups: IAuthenticatedUserGroups) => {
                    setAuthenticatedUserGroups(userGroups);
                    updateAuthenticatedUserGroups(userGroups);
                });
                await getUserProfileInformation().then((context: any) => {
                    if (context && context.profileData.length > 0 && context.profileData[0].username) {
                    } else {
                        updatePathWordUsername("?");
                    }
                });
            } catch {
                setAuthenticatedUserGroups(undefined);
            }
        }
        asyncFunctions();
    }, []);

    useEffect(() => {
        const tempPages = [];
        const tempUrls = [];

        if (authenticatedUserGroups?.isBetaTester) {
            // tempPages.push("Levels");
            // tempUrls.push("/levels");

            tempPages.push("Level Packs");
            tempUrls.push("/packs");

            // tempPages.push("Friends");
            // tempUrls.push("/friends");

            // tempPages.push("Groups");
            // tempUrls.push("/groups");

            tempPages.push("Leaderboard");
            tempUrls.push("/leaderboard");

            tempPages.push("Tutorial");
            tempUrls.push("/tutorial");
        }
        if (authenticatedUserGroups?.isAdmin) {
            tempPages.push("Dictionary");
            tempUrls.push("/dictionary");

            tempPages.push("Level Maker");
            tempUrls.push("/levelMaker");
        }
        setPages(tempPages);
        setUrls(tempUrls);
    }, [authenticatedUserGroups]);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (event: any, page: string) => {
        setAnchorElNav(null);
        if (authenticatedUserGroups?.isBetaTester) {
            switch (page) {
                case pages[0]:
                    navigate(urls[0]);
                    return;
                case pages[1]:
                    navigate(urls[1]);
                    return;
                case pages[2]:
                    navigate(urls[2]);
                    return;
                case pages[3]:
                    navigate(urls[3]);
                    return;
                case pages[4]:
                    navigate(urls[4]);
                    return;
                case pages[5]:
                    navigate(urls[5]);
                    return;

                case pages[6]:
                    if (authenticatedUserGroups?.isAdmin) {
                        navigate(urls[6]);
                    }
                    return;
                case pages[7]:
                    if (authenticatedUserGroups?.isAdmin) {
                        navigate(urls[7]);
                    }
                    return;

                default:
                    return;
            }
        }
    };

    const handleCloseUserMenu = (event: any) => {
        setAnchorElUser(null);
        switch (event.currentTarget.id) {
            case "Profile":
                navigate("/profile");
                return;
            case "Logout":
                signOut();
                return;
            default:
                return;
        }
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                // backgroundColor: colors.accentShade,
                background: componentColors.accent.backgroundColor,
                // font: "Battambang",
                height: `${height}vh`,
                maxHeight: `${maxHeight}px`,
                minHeight: `${minHeight}px`,
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: `${height}vh`,
                    maxHeight: `${maxHeight}px`,
                    minHeight: `${minHeight}px`,
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        height: `${height}vh`,
                        maxHeight: `${maxHeight}px`,
                        minHeight: `${minHeight}px`,
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <Link
                            to={defaultUrl}
                            style={{
                                textDecoration: "none",
                                color: colors.tertiary,
                                flexDirection: "row",
                            }}
                        >
                            <Typography
                                // variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: { xs: "none", md: "flex" },
                                    // font: "Battambang",
                                    fontSize: mobileView ? `${height * 1.2}vh` : `${maxHeight / 2}px`,
                                    alignItems: "center",
                                }}
                            >
                                <Draw />
                                {appName}
                            </Typography>
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={(e) => handleCloseNavMenu(e, page)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <Link to={defaultUrl} style={{ textDecoration: "none", color: colors.tertiary }}>
                            <Typography
                                sx={{
                                    fontSize: mobileView ? `${height / 1.6}vh` : `${maxHeight / 2}px`,
                                }}
                                noWrap
                            >
                                <Draw />
                                {appName}
                            </Typography>
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            height: `${height}vh`,
                            maxHeight: `${maxHeight}px`,
                            minHeight: `${minHeight}px`,
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={(e) => handleCloseNavMenu(e, page)}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    // fontSize: mobileView ? `${height}vh` : `${maxHeight / 4.5}px`,
                                }}
                            >
                                <Typography>{page}</Typography>
                            </Button>
                        ))}
                    </Box>

                    {!userLoggedOn && <LoginModal />}

                    {userLoggedOn && (
                        <Box sx={{ flexGrow: 0, alignContent: "center" }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar
                                        alt={pathwordUsername}
                                        src="/static/images/avatar/2.jpg"
                                        style={{
                                            height: mobileView ? `${height * 0.7}vh` : `${maxHeight * 0.7}px`,
                                            width: mobileView ? `${height * 0.7}vh` : `${maxHeight * 0.7}px`,
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} id={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
