import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Amplify } from "aws-amplify";
import config from "@summitrhode/shared/build/amplifyconfiguration.json";
import { signOut } from "aws-amplify/auth";
import { configureAmplify } from "@summitrhode/shared";

Amplify.configure(config); // Needed to setup amplify react features
configureAmplify(); // Needed setup package amplify. APIs will not work if this is missing

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
