"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthGetInput = void 0;
const getCurrentUserAuthToken_1 = require("./getCurrentUserAuthToken");
async function createAuthGetInput(apiPath) {
    return {
        apiName: "pathwordsAPI",
        path: apiPath,
        options: {
            headers: {
                Authorization: await (0, getCurrentUserAuthToken_1.getCurrentUserAuthTokenString)(),
            },
        },
    };
}
exports.createAuthGetInput = createAuthGetInput;
