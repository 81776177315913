"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserExpansionPacks = void 0;
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
/**
 * Gets the user expansion packs and current level status for
 * each
 *
 */
async function getUserExpansionPacks(isAdminTester = false) {
    const user = await (0, auth_1.fetchAuthSession)();
    let url = "/items/getUserExpansionPacks?sub=" + user.userSub;
    if (isAdminTester)
        url = url + "&isAdminTester=1";
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(url));
    const response = await restOperation.response;
    return JSON.parse(await response.body.text()).expansionPackLevels;
}
exports.getUserExpansionPacks = getUserExpansionPacks;
