import React, { useState, useEffect, useContext } from "react";

import { IAuthenticatedUserGroups, AwsAuth, getUser, getUserGroups } from "@summitrhode/shared";
import {
    AuthUser,
    signIn,
    signUp,
    signOut as logOff,
    confirmSignUp,
    resetPassword,
    confirmResetPassword,
    fetchAuthSession,
    updateUserAttributes,
    updatePassword,
    fetchUserAttributes,
} from "aws-amplify/auth";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuth {
    user?: AuthUser;
    username?: string;
    pathwordUsername?: string;
    sessionInfo?: {
        username?: string;
        email?: string;
        sub?: string;
        accessToken?: string;
        refreshToken?: string;
    };
    authenticatedUserGroups?: IAuthenticatedUserGroups;
    attrInfo?: any;
    authStatus?: AuthStatus;
    signInWithEmail?: any;
    signUpWithEmail?: any;
    signOut?: any;
    verifyCode?: any;
    getSession?: any;
    sendCode?: any;
    forgotPassword?: any;
    changePassword?: any;
    getAttributes?: any;
    setAttribute?: any;
    updatePathWordUsername: (u: string) => void;
    updateAuthenticatedUserGroups: (a: IAuthenticatedUserGroups) => void;
}

const defaultState: IAuth = {
    user: undefined,
    username: undefined,
    pathwordUsername: undefined,
    sessionInfo: {},
    authenticatedUserGroups: undefined,
    authStatus: AuthStatus.Loading,
    updatePathWordUsername: (u: string) => undefined,
    updateAuthenticatedUserGroups: (a: IAuthenticatedUserGroups) => undefined,
};

type Props = {
    children?: React.ReactNode;
};

export const AuthContext = React.createContext<IAuth>(defaultState);

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthIsSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

export const AuthProvider = ({ children }: Props) => {
    const [user, setUser] = useState<AuthUser>();
    const [username, setUsername] = useState<string>();
    const [pathwordUsername, setPathwordUsername] = useState<string>();
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [sessionInfo, setSessionInfo] = useState({});
    const [attrInfo, setAttrInfo] = useState([]);
    const [authenticatedUserGroups, setAuthenticatedUserGroups] = useState<IAuthenticatedUserGroups>();

    useEffect(() => {
        async function getSessionInfo() {
            try {
                const session: any = await getSession();

                const user = await getUser();
                setUser(user);
                setUsername(user.username);

                // TODO: Unsure if we need this. Probably delete
                // window.localStorage.setItem(
                //   "accessToken",
                //   `${session.tokens.accessToken.toString()}`
                // );
                // window.localStorage.setItem(
                //   "refreshToken",
                //   `${session.tokens.idToken.toString()}`
                // );
                await setAttribute({
                    Name: "website",
                    Value: "localhost:3000", //localhost:3000
                });
                const attr: any = await getAttributes();
                setSessionInfo({
                    sub: user.userId,
                });
                setAttrInfo(attr);
                setAuthStatus(AuthStatus.SignedIn);
            } catch (err) {
                setAuthStatus(AuthStatus.SignedOut);
            }
        }
        getSessionInfo();
    }, [setAuthStatus, authStatus]);

    if (authStatus === AuthStatus.Loading) {
        return null;
    }

    async function signInWithEmail(username: string, password: string) {
        try {
            await signIn({
                username,
                password,
            });
            setAuthStatus(AuthStatus.SignedIn);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }

    async function signUpWithEmail(username: string, email: string, password: string) {
        try {
            await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email,
                    },
                },
            });
        } catch (err) {
            throw err;
        }
    }

    async function signOut() {
        await logOff(); // AWS Auth SignOut method with alias above
        setAuthStatus(AuthStatus.SignedOut);
        setUsername(undefined);
        setPathwordUsername(undefined);
        setSessionInfo({});
        setAttrInfo([]);
        setAuthenticatedUserGroups(undefined);
    }

    async function updateAuthenticatedUserGroups(userGroups: IAuthenticatedUserGroups) {
        setAuthenticatedUserGroups(userGroups);
    }

    async function verifyCode(username: string, code: string) {
        try {
            await confirmSignUp({
                confirmationCode: code,
                username,
                options: { forceAliasCreation: true },
            });
        } catch (err) {
            throw err;
        }
    }

    async function getSession() {
        try {
            const session = await fetchAuthSession();
            return session;
        } catch (err) {
            throw err;
        }
    }

    async function getAttributes() {
        try {
            const attr = await fetchUserAttributes();
            return attr;
        } catch (err) {
            throw err;
        }
    }

    async function getAuthenticatedUserGroups() {
        try {
            const userGroups = await getUserGroups();
            return userGroups;
        } catch (err) {
            throw err;
        }
    }

    async function setAttribute(attr: any) {
        try {
            const res = await updateUserAttributes(attr);
            return res;
        } catch (err) {
            throw err;
        }
    }

    async function sendCode(username: string) {
        try {
            const user = await getUser();
            if (!user) {
                console.debug(`could not find ${username}`);
                return;
            }

            await resetPassword({ username });
        } catch (err) {
            throw err;
        }
    }

    async function forgotPassword(username: string, confirmationCode: string, newPassword: string) {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
        } catch (err) {
            throw err;
        }
    }

    async function changePassword(oldPassword: string, newPassword: string) {
        try {
            await updatePassword({ oldPassword, newPassword });
        } catch (err) {
            throw err;
        }
    }

    function updatePathWordUsername(username: string): void {
        setPathwordUsername(username);
        return;
    }

    const state: IAuth = {
        user,
        username,
        pathwordUsername,
        authStatus,
        sessionInfo,
        attrInfo,
        authenticatedUserGroups,
        signUpWithEmail,
        signInWithEmail,
        signOut,
        verifyCode,
        getSession,
        sendCode,
        forgotPassword,
        changePassword,
        getAttributes,
        setAttribute,
        updatePathWordUsername,
        updateAuthenticatedUserGroups,
    };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
