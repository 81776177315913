"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordDetails = void 0;
class WordDetails {
    constructor(name, wordId, wordIndexId) {
        this.name = name;
        this.wordId = wordId;
        this.wordIndexId = wordIndexId;
    }
}
exports.WordDetails = WordDetails;
