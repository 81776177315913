import { Box, Paper, Typography } from "@mui/material";
import {colors} from "@summitrhode/shared/";

function GuestPage() {
  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  return (
    <Box sx={{ display: "flex", height: "100vh", justifyContent: "center" }}>
      <Paper
        sx={{
          alignItems: "center",
          backgroundColor: colors.white,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          maxWidth: "900px",
          maxHeight: "200px",
          margin: 5,
          textAlign: "center",
        }}
      >
        <Typography variant="h3" style={{ fontSize: mobileView ? "3.5vh" : "" }}>
          You need to reach out to the right folks and get verified!
        </Typography>
      </Paper>
    </Box>
  );
}

export default GuestPage;
