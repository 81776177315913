import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { AdminPageAPICalls, AdminWord, AdminParentCategory, AdminSubCategory, colors, AdminWordRelationship } from "@summitrhode/shared";
import { cleanExpansionPackDetails, cleanStringEntry } from "./UtilityFunctions";
import { Button, Fab, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Typography, capitalize } from "@mui/material";
import { CheckCircle, DoNotDisturbAlt } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { DictionaryEntryModificationModal } from "./DictionaryEntryModificationModal";
import { Oval } from "react-loader-spinner";

function AdminDictionaryPage() {
    const [loading, setLoading] = useState<boolean>(false);

    const { authenticatedUserGroups, user } = useAuth();
    const [parentCategory, setParentCategory] = useState<String>("");
    // const [subCategory, setSubCategory] = useState<String>("");
    const [word, setWord] = useState<String>("");
    const [parentCategories, setParentCategories] = useState<AdminParentCategory[]>([]);
    const [subCategories, setSubCategories] = useState<AdminSubCategory[]>([]);
    const [words, setWords] = useState<AdminWord[]>([]);
    const [activeParentId, setActiveParentId] = useState<number>(-1);
    const [activeSubCategory, setActiveSubCategory] = useState<AdminSubCategory>({ subId: -1, psx_id: -1, name: "", enabled_for_automation: false });
    const [activeParentWords, setActiveParentWords] = useState<AdminWord[]>([]);
    const [insertString, setInsertString] = useState<String>("");

    const [addParentButtonEnabled, setAddParentButtonEnabled] = useState<boolean>(false);
    const [editParentButtonEnabled, setEditParentButtonEnabled] = useState<boolean>(false);
    const [addSubButtonEnabled, setAddSubButtonEnabled] = useState<boolean>(false);
    const [editSubButtonEnabled, setEditSubButtonEnabled] = useState<boolean>(false);
    const [addWordButtonEnabled, setAddWordButtonEnabled] = useState<boolean>(false);
    const [editWordButtonEnabled, setEditWordButtonEnabled] = useState<boolean>(false);
    const [adminPageAPICalls, setAdminPageAPICalls] = useState<AdminPageAPICalls>(new AdminPageAPICalls(authenticatedUserGroups));

    const [enableDictionaryEntryModificationModal, setEnableDictionaryEntryModificationModal] = useState<boolean>(false);
    const [showDictionaryEntryModificationModal, setShowDictionaryEntryModificationModal] = useState<boolean>(false);
    const [defaultModalWordIndex, setDefaultModalWordIndex] = useState<number>(0);
    const [categoryWordsRelationships, setCategoryWordsRelationships] = useState<AdminWordRelationship[]>([]);

    const [reloadList, setReloadList] = useState<boolean>(false);

    // const adminPageAPICalls = new AdminPageAPICalls();

    useEffect(() => {
        if (authenticatedUserGroups?.isAdmin) {
            setAdminPageAPICalls(new AdminPageAPICalls(authenticatedUserGroups));
            getParentCategories();
            setAddParentButtonEnabled(true);
        }
    }, [authenticatedUserGroups]);

    useEffect(() => {
        if (reloadList) {
            if (activeSubCategory.subId > 0) {
                getWords(activeSubCategory.subId);
            } else {
                getSubCategories(activeParentId);
            }
            setReloadList(false);
        }
    }, [reloadList]);

    const getParentCategories = async () => {
        const parentResult = await adminPageAPICalls.parentSubXrefs.getActiveParentSubXrefs();
        setParentCategories(parentResult);
    };

    const getSubCategories = async (parentId: number) => {
        if (parentId) {
            setActiveParentId(parentId);
            const subCategoriesReturn = await adminPageAPICalls.subCategories.getSubCategoriesFromParent(parentId);

            if (subCategoriesReturn) {
                setSubCategories(subCategoriesReturn.subs);
                setActiveParentWords(subCategoriesReturn.words);
                setCategoryWordsRelationships(subCategoriesReturn.wordRelationships);
                setWords(subCategoriesReturn.words);
            }
        } else {
            setSubCategories([]);
            setActiveParentWords([]);
            setCategoryWordsRelationships([]);
            setWords([]);
        }
        setLoading(false);
    };

    const getWords = async (subId: number) => {
        const wordsResult = await adminPageAPICalls.parentSubXrefs.getWordsFromParentSub(activeParentId, subId);
        setCategoryWordsRelationships(wordsResult.wordRelationships);
        setWords(wordsResult.words);
        setLoading(false);
    };

    const handleParentChange = (e: SelectChangeEvent<unknown>) => {
        const parentId = Number(e.target.value);
        setInsertString("");

        // Check if parent is the base entry
        if (parentId) {
            setEditParentButtonEnabled(true);
            setAddSubButtonEnabled(true);
            setAddWordButtonEnabled(true);
            setEditWordButtonEnabled(true);
        } else {
            setEditParentButtonEnabled(false);
            setAddSubButtonEnabled(false);
            setAddWordButtonEnabled(false);
            setEditWordButtonEnabled(false);

            setLoading(false);
        }
        setEditSubButtonEnabled(false);
        setActiveSubCategory({ subId: -1, psx_id: -1, name: "", enabled_for_automation: false });
        const parentEntry = parentCategories.find((e) => e.parentId === parentId);
        const parentName = parentEntry?.name ? parentEntry?.name : "";
        setWords([]);
        setActiveParentWords([]);
        setParentCategory(parentName);
        getSubCategories(parentId);
    };

    const handleSubChange = (e: SelectChangeEvent<unknown>) => {
        const subId = Number(e.target.value);
        setInsertString("");

        if (subId) {
            const subEntry = subCategories.find((e) => e.subId === subId);
            if (subEntry) {
                const subName = subEntry.name;
                const enabledForAutomation = subEntry.enabled_for_automation;

                setEditSubButtonEnabled(true);
                // setAddWordButtonEnabled(true);
                // setEditWordButtonEnabled(true);
                setWords([]);
                getWords(subId);
                setActiveSubCategory({
                    subId: subId,
                    psx_id: subEntry.psx_id,
                    name: subName,
                    enabled_for_automation: enabledForAutomation,
                });
            }
        } else {
            setEditSubButtonEnabled(false);
            // setAddWordButtonEnabled(false);
            // setEditWordButtonEnabled(false);
            setWords(activeParentWords);
            setActiveSubCategory({
                subId: -1,
                psx_id: -1,
                name: "",
                enabled_for_automation: false,
            });
        }

        console.debug(activeParentId + "     " + subId);
    };

    // Update the selected parent category
    // pass new value to the database
    // refresh the page upon completion
    const handleEditParentCategory = async () => {
        const updatedString = prompt("Edit this category? {" + parentCategory + "}");

        if (updatedString && updatedString?.length > 0) {
            await adminPageAPICalls.parentCategories.updateParentCategory(activeParentId, updatedString);
            setParentCategory(updatedString);
            getParentCategories();
        }
        setLoading(false);
    };

    // Update the selected sub category
    // pass new value to the database
    const handleEditSubCategory = async () => {
        const updatedString = prompt("Edit this category? {" + activeSubCategory.name + "}");

        if (updatedString && updatedString?.length > 0) {
            setLoading(true);
            await adminPageAPICalls.subCategories
                .updateSubCategory(activeParentId, activeSubCategory.subId, updatedString)
                // .then((res: { id: any } | void) => {
                //     setActiveSubCategory({
                //         subId: res ? res.id : -1,
                //         psx_id: -1,
                //         name: updatedString,
                //         enabled_for_automation: activeSubCategory.enabled_for_automation,
                //     });
                // })
                .then(() => getSubCategories(activeParentId))
                .then(() => getWords(activeSubCategory.subId))
                .then(() => setLoading(false));
        }
    };

    const handleToggleEnableAutomation = async () => {
        let promptString = "Type anything in here if you want to make this ";
        promptString += activeSubCategory.enabled_for_automation ? "not enabled for automation." : "enabled for automation.";

        const updatedString = prompt(promptString);

        if (updatedString && updatedString?.length > 0) {
            const newSubCategoryResult = await adminPageAPICalls.parentSubXrefs.updateParentSubEnabledForAutomation(activeParentId, activeSubCategory.subId, !activeSubCategory.enabled_for_automation);

            setSubCategories(newSubCategoryResult);
            setActiveSubCategory({
                subId: activeSubCategory.subId,
                psx_id: activeSubCategory.psx_id,
                name: activeSubCategory.name,
                enabled_for_automation: !activeSubCategory.enabled_for_automation,
            });
        }
    };

    // Update the selected sub category
    // pass new value to the database
    const handleEditWord = async (word: string, wordId: number) => {
        const updatedString = prompt("Edit this word? {" + word + "}");

        if (updatedString && updatedString?.length > 0) {
            const newWordResult = await adminPageAPICalls.parentSubXrefs.updateWord(activeParentId, activeSubCategory.subId, wordId, updatedString);

            setWords(newWordResult.updatedWordList);
            setActiveParentWords(newWordResult.updatedWordListOfParent);
        }
    };

    // Takes in new csv to insert into parent categories
    const handleAddNewParentCategory = async (textInput?: string) => {
        if (textInput) {
            adminPageAPICalls.parentCategories.addNewParentCategory(textInput).then(() => {
                getParentCategories();
            });
        } else {
            let newString = prompt("Add to the Parent Categories? (Be sure to separate by commas)");

            if (newString && newString?.length > 0) {
                newString = cleanExpansionPackDetails(newString);

                const newParentResult = await adminPageAPICalls.parentCategories.addNewParentCategory(newString);

                setParentCategories(newParentResult);
            }
        }
    };

    // Takes in new csv to insert into sub categories
    const handleAddNewSubCategory = async (parentId?: number, textInput?: string) => {
        if (textInput && parentId) {
            adminPageAPICalls.subCategories.addNewSubCategory(parentId, textInput).then(() => {
                getParentCategories().then(() => {
                    getSubCategories(parentId);
                });
            });
        } else {
            let newString = prompt(`Add one Sub Categories under {${parentCategory}}? (Be sure to not include any commas)`);

            if (newString && newString?.length > 0 && !newString.includes(",")) {
                newString = cleanExpansionPackDetails(newString);

                const returnNewSub = await adminPageAPICalls.subCategories.addNewSubCategory(activeParentId, newString);

                setActiveSubCategory({
                    subId: -1,
                    psx_id: -1,
                    name: "",
                    enabled_for_automation: false,
                });

                setSubCategories(returnNewSub);
            }
        }
    };

    // Takes in new csv to insert into sub categories
    const handleAddNewWord = async () => {
        let newString = prompt(`Add to the Words under {${parentCategory}}:{${activeSubCategory.name}}? (Be sure to separate by commas)`);

        if (newString && newString?.length > 0) {
            newString = cleanStringEntry(newString);

            const newWordResult = await adminPageAPICalls.parentSubXrefs.addNewWord(activeParentId, activeSubCategory.subId, newString);

            if (newWordResult === undefined) {
                return;
            }

            const entryString: string = newWordResult.entryString;
            const entryStringCount = (entryString.match(/,/g) || []).length + 1;
            const newWords = newWordResult.newWords;
            const previouslyExistingWords = newWordResult.previouslyExistingWords;
            const wordList = newWordResult.wordList;
            setActiveParentWords(newWordResult.wordListOfParent);

            const wordsToSet: {
                wordId: number;
                name: string;
                wcx_id: number;
                color?: string;
            }[] = [];

            wordList.forEach((word) => {
                if (newWords.find((newWord) => newWord.wordId === word.wordId) !== undefined) {
                    wordsToSet.push({
                        wordId: word.wordId,
                        name: word.name,
                        wcx_id: word.wcx_id ? word.wcx_id : -1,
                        color: colors.primary,
                    });
                } else if (previouslyExistingWords.find((newWord) => newWord.wordId === word.wordId) !== undefined) {
                    wordsToSet.push({
                        wordId: word.wordId,
                        name: word.name,
                        wcx_id: word.wcx_id ? word.wcx_id : -1,
                        color: colors.warning,
                    });
                } else {
                    wordsToSet.push({
                        wordId: word.wordId,
                        name: word.name,
                        wcx_id: word.wcx_id ? word.wcx_id : -1,
                        color: colors.black,
                    });
                }
            });

            const insertString =
                "Successfully inserted " +
                newWords.length +
                " new word(s) and " +
                previouslyExistingWords.length +
                " previously existing word(s) out of " +
                entryStringCount +
                " word(s) into " +
                parentCategory +
                "/" +
                activeSubCategory.name;

            setInsertString(insertString);
            setWords(wordsToSet);
        }
    };

    const updateDefaultWordIndex = async (index: number) => {
        setDefaultModalWordIndex(index);
        return;
    };

    const refreshWordListAfterUpdate = async () => {
        if (activeSubCategory.subId > -1) {
            // getWords(activeSubCategory.subId);
            getSubCategories(activeParentId);
        } else {
            getSubCategories(activeParentId);
        }
        return;
    };

    if (authenticatedUserGroups?.isAdmin) {
        return (
            <>
                <div style={{ backgroundColor: colors.white }}>
                    <DictionaryEntryModificationModal
                        addNewParentCategory={(textInput) => handleAddNewParentCategory(textInput)}
                        addNewSubCategory={(parentId, textInput) => handleAddNewSubCategory(parentId, textInput)}
                        defaultWordIndex={defaultModalWordIndex}
                        open={showDictionaryEntryModificationModal}
                        onClose={() => setShowDictionaryEntryModificationModal(false)}
                        onSave={(changedWordRelationships) => {
                            setLoading(true);
                            adminPageAPICalls.parentSubXrefs.saveWordRelationshipChanges(changedWordRelationships).then(async () => {
                                await refreshWordListAfterUpdate().then(() => setLoading(false));
                            });
                            setShowDictionaryEntryModificationModal(false);
                        }}
                        list={["test1", "test2", "test3"]}
                        loading={loading}
                        parentCategories={parentCategories}
                        puzzleWordsRelationships={categoryWordsRelationships}
                    />
                    {/* <Button
                            onClick={() => {
                                setShowDictionaryEntryModificationModal(true);
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            Show Word Dictionary Relationships
                        </Button> */}

                    <div>
                        <FormControl sx={{ minWidth: "175px", maxWidth: "400px", margin: "10px" }}>
                            <InputLabel id="Parent-Category-Select-Label">Parent Category</InputLabel>
                            <Select
                                defaultValue={""}
                                id="Parent-Category-Select"
                                label="Parent-Category"
                                labelId="Parent-Category-Select-Label"
                                onChange={(e) => {
                                    setLoading(true);
                                    handleParentChange(e);
                                }}
                            >
                                <MenuItem>----</MenuItem>
                                {parentCategories.map((option) => (
                                    <MenuItem key={option.parentId} value={option.parentId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            disabled={!editParentButtonEnabled}
                            key={activeParentId}
                            onClick={() => {
                                setLoading(true);
                                handleEditParentCategory();
                            }}
                            variant="contained"
                            sx={{
                                minWidth: "50px",
                                maxWidth: "100px",
                                margin: "10px",
                                marginTop: "17px",
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            disabled={!addParentButtonEnabled}
                            onClick={() => handleAddNewParentCategory()}
                            variant="contained"
                            sx={{
                                minWidth: "50px",
                                maxWidth: "100px",
                                margin: "10px",
                                marginTop: "17px",
                            }}
                        >
                            Add
                        </Button>
                    </div>
                    <div>
                        <FormControl sx={{ minWidth: "175px", maxWidth: "400px", margin: "10px" }}>
                            <InputLabel id="Sub-Category-Select-Label">Sub Category</InputLabel>
                            <Select
                                value={activeSubCategory.subId > -1 ? activeSubCategory.subId : undefined}
                                id="Sub-Category-Select"
                                label="Sub-Category"
                                labelId="Sub-Category-Select-Label"
                                onChange={(e) => {
                                    setLoading(true);
                                    handleSubChange(e);
                                }}
                            >
                                <MenuItem>----</MenuItem>
                                {subCategories.map((option) => (
                                    <MenuItem key={option.subId} value={option.subId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            disabled={!editSubButtonEnabled}
                            key={activeSubCategory.subId}
                            onClick={() => handleEditSubCategory()}
                            variant="contained"
                            sx={{
                                minWidth: "50px",
                                maxWidth: "100px",
                                margin: "10px",
                                marginTop: "17px",
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            disabled={!addSubButtonEnabled}
                            onClick={() => handleAddNewSubCategory()}
                            variant="contained"
                            sx={{
                                minWidth: "50px",
                                maxWidth: "100px",
                                margin: "10px",
                                marginTop: "17px",
                            }}
                        >
                            Add
                        </Button>
                    </div>
                    <div>
                        {activeSubCategory.subId > 0 && (
                            <div style={{ flexDirection: "row" }}>
                                <Typography>{words.length} words and</Typography>

                                {activeSubCategory.enabled_for_automation && (
                                    <Fab variant="extended" onClick={() => handleToggleEnableAutomation()}>
                                        <CheckCircle sx={{ color: "green", mr: 1 }} />
                                        Enabled for Automation
                                    </Fab>
                                )}
                                {!activeSubCategory.enabled_for_automation && (
                                    <Fab variant="extended" onClick={() => handleToggleEnableAutomation()}>
                                        <DoNotDisturbAlt sx={{ color: "red", mr: 1 }} />
                                        Not Enabled for Automation
                                    </Fab>
                                )}
                            </div>
                        )}

                        <Button
                            disabled={!addWordButtonEnabled}
                            onClick={() => handleAddNewWord()}
                            variant="contained"
                            sx={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                margin: "10px",
                                marginTop: "17px",
                            }}
                        >
                            Add Word
                        </Button>
                        {loading && <Oval ariaLabel="loading-indicator" height={100} width={100} strokeWidth={5} strokeWidthSecondary={1} color={colors.primary} secondaryColor="white" />}
                        {!loading && (
                            <List
                                key={activeSubCategory.subId}
                                id="Word List"
                                dense
                                sx={{
                                    width: "100%",
                                    flexFlow: "column",
                                    flexWrap: "wrap",
                                    height: 1200,
                                    overflow: "auto",
                                    maxWidth: 1200,
                                    bgcolor: "",
                                    margin: "-1px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {insertString.length > 0 && <Typography>{insertString}</Typography>}
                                {words.map((option, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{ width: 250, maxWidth: "20vw" }}
                                        secondaryAction={
                                            activeSubCategory.subId > -1 && (
                                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditWord(option.name, option.wordId)}>
                                                    <EditIcon />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        <ListItemButton
                                            disabled={!editWordButtonEnabled}
                                            onClick={() => {
                                                updateDefaultWordIndex(index).then(() => setShowDictionaryEntryModificationModal(true));
                                            }}
                                        >
                                            <ListItemText key={option.wordId} primary={index + 1 + ". " + capitalize(option.name)} sx={{ color: option.color ? option.color : colors.black }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </div>
                </div>
            </>
        );
    } else {
        return <div>You shouldn't be here!</div>;
    }
}

export default AdminDictionaryPage;
