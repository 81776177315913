"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelMetaData = void 0;
class LevelMetaData {
    constructor(dailyLevelId, levelId, levelName, levelSubtitle, numRows, numColumns, difficulty, date, parentCategoryId, parentCategory, subCategoryId, subCategory, timeLimit, moveLimit) {
        this.dailyLevelId = dailyLevelId;
        this.levelId = levelId;
        this.levelName = levelName;
        this.levelSubtitle = levelSubtitle;
        this.numRows = numRows;
        this.numColumns = numColumns;
        this.difficulty = difficulty;
        this.date = date;
        this.parentCategoryId = parentCategoryId;
        this.parentCategory = parentCategory;
        this.subCategoryId = subCategoryId;
        this.subCategory = subCategory;
        this.timeLimit = timeLimit;
        this.moveLimit = moveLimit;
    }
}
exports.LevelMetaData = LevelMetaData;
