"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPageGeneralFunctions = void 0;
class AdminPageGeneralFunctions {
    static convertAnswerCoordinatesToArray(answerCoordinates) {
        const returnArray = [];
        for (let i = 0; i < answerCoordinates.length; i++) {
            if (answerCoordinates[i].answerCoordinateIndexId === i) {
                returnArray.push(answerCoordinates[i].answerCoordinate);
            }
        }
        return returnArray;
    }
    static convertLetterDetailsToArray(letterDetails) {
        const returnArray = [];
        for (let i = 0; i < letterDetails.length; i++) {
            if (letterDetails[i].letterIndexId === i) {
                returnArray.push(letterDetails[i].letterValue);
            }
        }
        return returnArray;
    }
    static convertWordDetailsToWordArray(wordDetails) {
        const returnArray = [];
        for (let i = 0; i < wordDetails.length; i++) {
            if (wordDetails[i].wordIndexId === i) {
                returnArray.push({
                    wordId: wordDetails[i].wordId,
                    name: wordDetails[i].name,
                });
            }
        }
        return returnArray;
    }
}
exports.AdminPageGeneralFunctions = AdminPageGeneralFunctions;
