"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignLevel = void 0;
class CampaignLevel {
    constructor(campaignId, levelNumber, levelId, name, subtitle) {
        this.campaignId = campaignId;
        this.levelNumber = levelNumber;
        this.levelId = levelId;
        this.name = name;
        this.subtitle = subtitle;
    }
}
exports.CampaignLevel = CampaignLevel;
