import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {handleFriendRequest} from "@summitrhode/shared";
import { useAuth } from "../../context/authContext";
import { useEffect, useState } from "react";
import { LoadingWheel } from "../UtilityComponents/LoadingWheel";

interface Props {
  friends: {
    // picture: string;
    username: string;
    userID: string;
    firstName: string;
    lastName: string;
    StatusCode: string;
  }[];
  pendingFriendRequests: {
    // picture: string;
    username: string;
    userID: string;
    firstName: string;
    lastName: string;
    StatusCode: string;
  }[];
}

export function FriendsList({ friends, pendingFriendRequests }: Props) {
  const navigate = useNavigate();
  const { sessionInfo } = useAuth();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  const [friendList, setFriendList] = useState<
    {
      // picture: string;
      username: string;
      userID: string;
      firstName: string;
      lastName: string;
      StatusCode: string;
    }[]
  >(friends);

  const [pendingFriendList, setPendingFriendList] = useState<
    {
      // picture: string;
      username: string;
      userID: string;
      firstName: string;
      lastName: string;
      StatusCode: string;
    }[]
  >(pendingFriendRequests);

  useEffect(() => {
    setPageLoaded(true);
  }, [friendList, pendingFriendList]);

  // useEffect(() => {
  //   setPendingFriendList(pendingFriendRequests);
  // }, [pendingFriendRequests]);

  const handleUsernameClicked = (userID: string) => {
    navigate("/profile", { state: { profileToView: userID } });
  };

  const handleFriendRequestButtonClicked = async (
    requesterId: string,
    response: string,
    index: number
  ) => {
    if (sessionInfo && sessionInfo.sub) {
      const userID = sessionInfo.sub;
      await handleFriendRequest(requesterId, userID, response);
      let tempPendingFriendList = pendingFriendList;
      let tempFriendList = friendList;
      if (response === "A") {
        tempFriendList.push(...tempPendingFriendList.splice(index, 1));
      } else {
        tempPendingFriendList.splice(index, 1);
      }
      setPendingFriendList(tempPendingFriendList);
      setFriendList(tempFriendList);
    }
  };

  if (pageLoaded) {
    return (
      <Box>
        {pendingFriendList.length > 0 && (
          <List>
            <ListSubheader>Pending Friend Requests</ListSubheader>
            {pendingFriendList.map((friend, index) => (
              <ListItem sx={{ justifyContent: "space-between" }}>
                {/* <ListItemAvatar>
              <Avatar src={friend.picture} />
            </ListItemAvatar> */}
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    handleUsernameClicked(friend.userID);
                  }}
                >
                  <ListItemText
                    color="secondary"
                    primary={friend.username}
                    secondary={friend.firstName + " " + friend.lastName}
                  />
                </Button>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPageLoaded(false);
                      handleFriendRequestButtonClicked(
                        friend.userID,
                        "A",
                        index
                      ).then(() => setPageLoaded(true));
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => {
                      setPageLoaded(false);
                      handleFriendRequestButtonClicked(
                        friend.userID,
                        "D",
                        index
                      ).then(() => setPageLoaded(true));
                    }}
                    variant="contained"
                  >
                    Decline
                  </Button>
                </Box>
                {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => handleUsernameClicked(friend.userID)}
              >
                Go To Profile
              </Button> */}
              </ListItem>
            ))}
          </List>
        )}
        <List>
          <ListSubheader>Friends</ListSubheader>
          {friendList.map((friend) => (
            <ListItem>
              {/* <ListItemAvatar>
              <Avatar src={friend.picture} />
            </ListItemAvatar> */}
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  handleUsernameClicked(friend.userID);
                }}
              >
                <ListItemText
                  color="secondary"
                  primary={friend.username}
                  secondary={friend.firstName + " " + friend.lastName}
                />
              </Button>
              {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUsernameClicked(friend.userID)}
            >
              Go To Profile
            </Button> */}
            </ListItem>
          ))}
        </List>
      </Box>
    );
  } else {
    return <LoadingWheel />;
  }
}
