/**
 * Prompts that appear for the tutorial
 */

import { PromptDataInterface, PromptType } from "@summitrhode/shared";

const tutorialPrompts: {
  levelNumber: number;
  levelPrompts: PromptDataInterface[];
}[] = [
  {
    levelNumber: 1,
    levelPrompts: [
      {
        promptType: PromptType.INITIAL,
        title: "Welcome To PathWords!",
        body1: "Where the game is in the name!",
        body2: "Find all the words in the least amount of paths!",
        body3:
          "Let's begin with an easy example. Start with one of the colored tiles and drag left, right, up or down to find a PathWord!",
        footnote: "(We'll help out on this first one!)",
        buttonText: "Let's Go!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.MISTAKE,
        title: "Not That Way!",
        body1:
          "That was a good try, but can you find another way that path should go?",
        body2: "Try to follow the blinking tiles",
        body3: "",
        buttonText: "I Got This!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.PARTIAL,
        title: "Almost There!",
        body1: "You're on the right track! ",
        body2: "Check back and see what tiles you need to finish the PathWord!",
        body3: "",
        buttonText: "Got It!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.SECONDARY,
        title: "Great!",
        body1: "You just found your first PathWord!",
        body2:
          "Notice the lock next to the word - this means you found a correct WayWord for this puzzle!",
        body3:
          "The lock keeps a PathWord safe so you don't accidentally use those tiles for another.",
        footnote: "(The lock is only in Easy mode)",
        buttonText: "I'll find the rest!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.SUCCESS,
        title: "Nice job!",
        body1: "You Found All the PathWords in this puzzle!",
        body2: "Let's try one that is a little more complex.",
        body3: "",
        buttonText: "I'm Ready!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
    ],
  },
  {
    levelNumber: 2,
    levelPrompts: [
      {
        promptType: PromptType.INITIAL,
        title: "Blanks!?",
        body1: "You didn't think it would be that easy did you?",
        body2:
          "Try finding a Word while making your Path with the help of the blank tiles.",
        body3: "",
        footnote: "(Blank tiles can only be used once, but for any PathWord)",
        buttonText: "Let's Go!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.MISTAKE,
        title: "Not That Way!",
        body1:
          "That was a good try, but can you find another way that path should go?",
        body2: "Try to follow the blinking tiles",
        body3: "",
        buttonText: "I got this!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.PARTIAL,
        title: "Almost!",
        body1:
          "You're on the right track! Just need to find the rest of the tiles in the PathWord!",
        body2: "Try to follow the blinking tiles",
        body3: "",
        buttonText: "I got this!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.SECONDARY,
        title: "Well Done!",
        body1: "You just found your first PathWord using blanks!",
        body2:
          "The goals of every level are to:\n1) Find all WayWords AND \n2) Use every tile to get the most points!",
        body3: "Now, finish this level!",

        buttonText: "I'll find the rest!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
      {
        promptType: PromptType.SUCCESS,
        title: "You Did it Again!",
        body1: "Seems like we can still make it a bit harder… ",
        body2: "Now you know how to use the blanks. Next up is hints!",
        body3: "Next up is hints!",
        buttonText: "I'm Ready!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
    ],
  },
  {
    levelNumber: 3,
    levelPrompts: [
      {
        promptType: PromptType.INITIAL,
        title: "No More First Letters!",
        body1: "Harder puzzles no longer give you the first PathWord letter.",
        body2:
          " It's up to you to figure out the WayWords based on the title/category.",
        body3:
          "If this worries you, hints can help! Choose a button at the bottom to see what it does!",
        footnote: "(To clear a PathWord, click the Trash icon on the right.)",
        buttonText: "Let's Go!",
        settings: {
          bigHintEnabled: true,
          littleHintEnabled: true,
          checkAllEnabled: true,
        },
      },
      {
        promptType: PromptType.SUCCESS,
        title: "You've completed the tutorial!",
        body1: "You sure have navigated those challenging PATHs well!",
        body2: "Ok, let's leave the jokes and play some real levels!",
        body3: "",
        buttonText: "Let's Do It!",
        settings: {
          bigHintEnabled: false,
          littleHintEnabled: false,
          checkAllEnabled: false,
        },
      },
    ],
  },
];

export default tutorialPrompts;
