"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PuzzleBoard = void 0;
const _1 = require("./");
const utility_1 = require("../utility");
const styles_1 = require("../styles");
class PuzzleBoard {
    get gameState() {
        return this._gameState;
    }
    // Return empty array to fill in blanks in word box
    get remainingWordCount() {
        const tempArray = [];
        for (let i = 0; i < this.correctWords.length - this._wordGuessCount; i++) {
            tempArray.push("");
        }
        return tempArray;
    }
    get wordGuessCount() {
        return this._wordGuessCount;
    }
    get moveCount() {
        return this._moveCount;
    }
    get timeTaken() {
        return this._timeTaken;
    }
    get blanksLeft() {
        return this._blanksLeft;
    }
    get littleHintsUsed() {
        return this._littleHintsUsed;
    }
    get bigHintsUsed() {
        return this._bigHintsUsed;
    }
    get checkAllsUsed() {
        return this._checkAllsUsed;
    }
    get difficulty() {
        return this._difficulty;
    }
    get finalScore() {
        return this._finalScore;
    }
    get moveCountReached() {
        return this._moveCountReached;
    }
    get timeCapReached() {
        return this._timeCapReached;
    }
    get timeLimit() {
        return this._timeLimit;
    }
    constructor(puzzle, cachedPuzzleBoard) {
        this.board = [];
        this.wordGuesses = {};
        this.blanksToolTipEnabled = false;
        this.blanksToolTipTitle = "";
        this.clockRunning = true;
        this.puzzleComplete = false;
        this._enableTimeCap = false;
        this._timeLimit = 0;
        this._enableMoveCap = false;
        this._moveLimit = 0;
        this.correctWords = [];
        this._gameState = _1.GameState.PLAYING;
        this.colorGenerator = new _1.ColorGenerator();
        this._moveCount = 0;
        this._countedMove = false;
        this._shouldCountMove = false;
        this._wordGuessIndex = 0;
        this._wordGuessCount = 0;
        this._timeTaken = 0.0;
        this._blanksLeft = 0;
        this._finalScore = -1;
        this._littleHintsUsed = 0;
        this._bigHintsUsed = 0;
        this._checkAllsUsed = 0;
        this._numberOfPuzzleTiles = 0;
        this._moveCountReached = false;
        this._timeCapReached = false;
        this._gaveUpOnPuzzle = false;
        /**
         * Allows the user to give up on the puzzle
         * and see all the answers
         */
        this.giveUpOnPuzzle = (gaveUp) => {
            this._gaveUpOnPuzzle = gaveUp;
        };
        /**
         * Toggles if the clock should be running for the current
         * puzzle instance
         * @param shouldClockRun
         */
        this.setClockRunning = (shouldClockRun) => {
            this.clockRunning = shouldClockRun;
        };
        // Get puzzle size, difficulty
        this.rows = puzzle.rows;
        this.columns = puzzle.columns;
        this._difficulty = puzzle.difficulty;
        this._levelType = puzzle.levelType;
        this._numberOfPuzzleTiles = puzzle.rows * puzzle.columns;
        if (puzzle.timeLimit) {
            this._timeLimit = puzzle.timeLimit;
            this._enableTimeCap = true;
        }
        if (puzzle.moveLimit) {
            this._moveLimit = puzzle.moveLimit;
            this._enableMoveCap = true;
        }
        let answerIndexes = (0, utility_1.ConvertAnswerCoordinatesToArray)(puzzle.answerCoordinates);
        // Loop through all the rows and collumns to create
        // the board object and the wordGuess object
        for (let row = 0; row < this.rows; row++) {
            for (let column = 0; column < this.columns; column++) {
                let pieceIndex = row * this.columns + column;
                let correctWordIndex = answerIndexes.findIndex((answerArray) => answerArray.includes(pieceIndex));
                let correctWordPieceIndex = answerIndexes[correctWordIndex].findIndex((wordArray) => wordArray === pieceIndex);
                let newPiece = new _1.Piece(pieceIndex, row, column, puzzle.letters[pieceIndex], correctWordIndex, correctWordPieceIndex);
                if ((0, _1.ShouldDisplayAnchors)(this._difficulty) && correctWordPieceIndex === 0) {
                    this.AddPieceAsAnchor(newPiece);
                    if (newPiece.wordGuessKey !== undefined) {
                        this.wordGuesses[newPiece.wordGuessKey].setIsClearable(false);
                    }
                }
                this.board.push(newPiece);
            }
        }
        this.correctWords = (0, utility_1.CreateCorrectWords)(this.board, answerIndexes);
        if (puzzle.isAdminPuzzle) {
            this.correctWords.forEach((word) => {
                this.revealWholeAnswer(word.pieceArray[0]);
            });
        }
        if (cachedPuzzleBoard) {
            this._bigHintsUsed = cachedPuzzleBoard._bigHintsUsed;
            this._checkAllsUsed = cachedPuzzleBoard._checkAllsUsed;
            // this.clockRunning = cachedPuzzleBoard.clockRunning;
            this._littleHintsUsed = cachedPuzzleBoard._littleHintsUsed;
            this._moveCount = cachedPuzzleBoard._moveCount;
            this._timeTaken = cachedPuzzleBoard._timeTaken;
            // this._wordGuessCount = cachedPuzzleBoard._wordGuessCount;
            this._gameState = cachedPuzzleBoard._gameState;
            // Reformat the board after being JSON stringified
            Object.entries(cachedPuzzleBoard.wordGuesses).map(([id, wordGuess]) => {
                let wordGuessKey = undefined;
                wordGuess.pieceArray.forEach((piece) => {
                    if (piece.wordGuessPieceIndex === 0 && piece.letter === this.board[piece.boardIndex].letter) {
                        this.tryAddPieceAsAnchor(this.board[piece.boardIndex]);
                        this.setActiveWordGuess(this.board[piece.boardIndex]);
                        wordGuessKey = this.board[piece.boardIndex].wordGuessKey;
                    }
                    else {
                        if (wordGuessKey !== undefined && piece.letter === this.board[piece.boardIndex].letter) {
                            this.tryAddPieceToActiveWord(this.board[piece.boardIndex]);
                        }
                    }
                    if (piece.wordGuessPieceIndex === wordGuess.pieceArray.length - 1) {
                        this.releaseActiveWordGuess(piece, true);
                    }
                });
            });
            // Reset all flashing hints
            cachedPuzzleBoard.board.forEach((piece) => {
                if (piece.isFlashing && piece.letter === this.board[piece.boardIndex].letter) {
                    this.revealLittleHintAnswer(this.board[piece.boardIndex], this._difficulty, false);
                }
            });
        }
    }
    /**
     * Sets the active word guess based on the piece.
     * Usually this gets called after a piece is selected on the UI
     * @param piece
     * @returns
     */
    setActiveWordGuess(piece) {
        if (piece.wordGuessKey === undefined)
            return;
        this.activeWordGuess = this.wordGuesses[piece.wordGuessKey];
        this._countedMove = false;
        this._shouldCountMove = false;
        this.startingMovePiece = piece;
        this._lastMovedOnPiece = piece;
        this.blanksToolTipEnabled = false;
        this.blanksToolTipTitle = "";
    }
    /**
     * Saves the starting word guess to later identify if it changes
     * on relase to count the move or not.
     * @param piece
     */
    setInitialActiveWordGuess(piece) {
        if (this._initialActiveWordGuess === undefined) {
            const tempWordGuess = Object.entries(this.wordGuesses).find((wordGuess) => wordGuess[0] === piece.wordGuessKey)?.[1];
            // pass the value and not the reference
            this._initialActiveWordGuess = JSON.parse(JSON.stringify(tempWordGuess));
        }
    }
    hideBlanksToolTip() {
        this.blanksToolTipEnabled = false;
    }
    /**
     * Shows all the answers when a puzzle is lost
     *
     */
    revealAllAnswers() {
        this.correctWords.forEach((word) => {
            this.revealWholeAnswer(word.pieceArray[0], false);
        });
    }
    /**
     * Shows a tooltip if the user grabs a blank
     *
     */
    setGrabBlanksToolTip() {
        this.blanksToolTipTitle = "You can't grab a blank tile! Use them as a wildcard";
        this.blanksToolTipEnabled = true;
    }
    /**
     * Shows a tooltip if the user releases on a blank
     *
     */
    setReleaseOnBlanksToolTip() {
        this.blanksToolTipTitle = "You can't release a PathWord on blank tile! Try continuing to another letter!";
        this.blanksToolTipEnabled = true;
    }
    removeWordGuess(wordGuessKey) {
        // Add color back to pool
        this.colorGenerator.addColorBack(this.wordGuesses[wordGuessKey].backgroundColor, this.wordGuesses[wordGuessKey].accentColor, this.wordGuesses[wordGuessKey].coreColor, this.wordGuesses[wordGuessKey].terminalAccentColor, this.wordGuesses[wordGuessKey].lockedAccentColor, this.wordGuesses[wordGuessKey].cssFilterBackground, this.wordGuesses[wordGuessKey].cssFilterAccent);
        // Return the pieces to nodes
        this.wordGuesses[wordGuessKey].cleanPieceArray();
        // Remove wordGuess from wordGuesses
        delete this.wordGuesses[wordGuessKey];
        //update wordGuessCount
        this._wordGuessCount--;
    }
    /**
     * Slice a wordGuess up to and including the given piece
     * Will remove entire word guess if given piece is anchor
     * @param piece
     * @returns
     */
    sliceOrRemoveWordGuess(piece) {
        if (piece.wordGuessKey === undefined || piece.wordGuessPieceIndex === undefined)
            return;
        if (piece.wordGuessPieceIndex === 0) {
            this.removeWordGuess(piece.wordGuessKey);
            return;
        }
        this.wordGuesses[piece.wordGuessKey].slicePieceArray(piece.wordGuessPieceIndex - 1);
    }
    /**
     * Sets activeWordGuess to undefined.
     * Usually this gets called when the pointer is released
     */
    turnOffFlashOnAllTiles() {
        this.board.forEach((piece) => {
            if (piece.state === _1.PieceState.NODE) {
                piece.setIsFlashing(false);
            }
        });
    }
    /**
     * Updates the prompt state that should be shown
     * on tutorials as player traverses through the level.
     * Evaluates if the activeWordGuess has any incorrect pieces
     */
    handleTutorialPromptStateChange(wordState) {
        if (this.activeWordGuess === undefined) {
            console.debug("No activeWordGuess to handle tutorial prompt for");
            return;
        }
        // Player found the exact right answer but hasn't found all the PathWords
        if (wordState === _1.WordState.RIGHT_WORD_EXACT_MATCH && this._gameState === _1.GameState.PLAYING) {
            let correctWords = 0;
            //Only show the secondary tutorial modal once
            Object.entries(this.wordGuesses).map(([id, wordguess]) => {
                if (wordguess.wordState === _1.WordState.RIGHT_WORD_EXACT_MATCH || wordguess.wordState === _1.WordState.RIGHT_WORD) {
                    correctWords += 1;
                }
            });
            if (correctWords === 1) {
                return _1.PromptType.SECONDARY;
            }
            else {
                return;
            }
        }
        // Player found the exact right answer as well as all the PathWords
        if (wordState === _1.WordState.RIGHT_WORD_EXACT_MATCH) {
            return _1.PromptType.SUCCESS;
        }
        let correctWord;
        let anchorIsCorrect = false;
        let hasMistake = false;
        this.activeWordGuess.pieceArray.forEach((piece, index) => {
            if (index === 0) {
                if (piece.correctWordIndex !== undefined) {
                    correctWord = this.correctWords[piece.correctWordIndex];
                    if (piece.boardIndex === correctWord.pieceArray[0].boardIndex) {
                        anchorIsCorrect = true;
                    }
                }
                else {
                    console.debug("The first tile doesn't have a correct word index");
                    hasMistake = true;
                }
            }
            const pieceInCorrectLocation = correctWord.pieceArray.find((correctWordPiece, index) => piece.correctWordIndex === correctWordPiece.correctWordIndex &&
                piece.wordGuessPieceIndex === correctWordPiece.correctWordPieceIndex &&
                piece.boardIndex === correctWordPiece.boardIndex);
            if (pieceInCorrectLocation === undefined && this.activeWordGuess) {
                if (anchorIsCorrect && this.activeWordGuess.pieceArray[1]) {
                    this.sliceOrRemoveWordGuess(this.activeWordGuess.pieceArray[1]);
                }
                if (!anchorIsCorrect) {
                    this.sliceOrRemoveWordGuess(this.activeWordGuess.pieceArray[0]);
                }
                console.debug("Piece was in the wrong location");
                hasMistake = true;
            }
        });
        if (hasMistake) {
            return _1.PromptType.MISTAKE;
        }
        else {
            console.debug("All current letters are in right place. Word just isn't complete yet");
            return _1.PromptType.PARTIAL;
        }
    }
    /**
     * Sets activeWordGuess to undefined.
     * Usually this gets called when the pointer is released
     * @returns Prompt type for when tutorial is on
     */
    releaseActiveWordGuess(piece, fromHint = false) {
        if (this.activeWordGuess) {
            // Snap back to last letter if final letter is blank
            let snappedBack = false;
            while (this.activeWordGuess.pieceArray.length > 0 && this.activeWordGuess.pieceArray[this.activeWordGuess.pieceArray.length - 1].letter === " ") {
                this.activeWordGuess.slicePieceArray(this.activeWordGuess.pieceArray.length - 2);
                snappedBack = true;
                this.setReleaseOnBlanksToolTip();
            }
            if (snappedBack) {
                this._lastMovedOnPiece = this.activeWordGuess.pieceArray[this.activeWordGuess.pieceArray.length - 1];
            }
            // check if the new path is different than what it started as
            if (this.activeWordGuess.pieceArray.length === this._initialActiveWordGuess?.pieceArray.length) {
                this.activeWordGuess.pieceArray.forEach((activePiece, index) => {
                    if (activePiece.boardIndex !== this._initialActiveWordGuess?.pieceArray[index].boardIndex) {
                        this._shouldCountMove = true;
                    }
                });
            }
            else {
                this._shouldCountMove = true;
            }
            this._initialActiveWordGuess = undefined;
            // this.startingMovePiece?.setIsFlashing(true);
            // this._lastMovedOnPiece?.setIsFlashing(true);
            const wordState = (0, utility_1.CheckWordState)(this.activeWordGuess, this.correctWords, this.difficulty);
            this.handleWordStateChange(wordState);
            if (!this._countedMove && this._shouldCountMove && !fromHint) {
                this._moveCount++;
                this._countedMove = true;
            }
            this.setGameState();
            let tutorialPromptState = undefined;
            // if (
            //   this._difficulty === Difficulty.EASY ||
            //   this._difficulty === Difficulty.MEDIUM
            // ) {
            if (this._levelType === _1.LevelTypes.TUTORIAL) {
                tutorialPromptState = this.handleTutorialPromptStateChange(wordState);
            }
            // }
            this.activeWordGuess = undefined;
            return tutorialPromptState;
        }
        if (this._gaveUpOnPuzzle) {
            this.setGameState();
        }
        return;
    }
    /**
     * Updates the word state that should be shown
     * on word bank depending on difficulty
     */
    handleWordStateChange(wordState) {
        if (this.activeWordGuess === undefined)
            return;
        if (this._difficulty === _1.Difficulty.EXPERT || this._difficulty === _1.Difficulty.GOD || wordState === _1.WordState.SEARCHING) {
            this.activeWordGuess.setState(_1.WordState.SEARCHING);
        }
        else if (this._difficulty === _1.Difficulty.MEDIUM || this._difficulty === _1.Difficulty.HARD) {
            this.activeWordGuess.setState(_1.WordState.RIGHT_WORD);
        }
        else {
            //We're in easy and not searching
            if (wordState === _1.WordState.RIGHT_WORD_EXACT_MATCH) {
                this.activeWordGuess.setIsLocked(true);
                this.activeWordGuess.setLastPieceAnchorColor();
            }
            this.activeWordGuess.setState(wordState);
        }
    }
    /**
     * Reveals the word associated with the piece that was clicked
     * on the hint activation
     */
    revealWholeAnswer(selectedPiece, moveShouldCount = true) {
        // return if piece is undefined or not a part of correctWord
        if (selectedPiece === undefined || selectedPiece.correctWordIndex === undefined)
            return;
        // Loop through the pieces of the correctWord which the selectedPiece belongs to
        this.correctWords[selectedPiece.correctWordIndex].pieceArray.forEach((piece) => {
            // Add the piece to new wordGuess
            if (piece.correctWordPieceIndex === 0) {
                if (piece.state !== _1.PieceState.ANCHOR) {
                    this.sliceOrRemoveWordGuess(piece);
                    this.AddPieceAsAnchor(piece);
                }
                this.setActiveWordGuess(piece);
                this.activeWordGuess?.setIsLocked(true);
                this.activeWordGuess?.setIsClearable(false);
            }
            else {
                // Slice or remove wordGuess if the piece is already a part of a wordGuess
                this.sliceOrRemoveWordGuess(piece);
            }
            this.tryAddPieceToActiveWord(piece);
        });
        if (moveShouldCount) {
            this._bigHintsUsed++;
            this.setGameState();
        }
        if (!this._countedMove && moveShouldCount) {
            this._moveCount++;
            this._countedMove = true;
        }
        return this.releaseActiveWordGuess(selectedPiece, true);
    }
    /**
     * Checks all the word guesses the user has input and removes
     * any incorrect tiles
     */
    checkAllWordGuesses(selectedPiece) {
        if (selectedPiece === undefined || selectedPiece.correctWordIndex === undefined)
            return;
        Object.entries(this.wordGuesses).forEach((entry) => {
            const [key, wordGuess] = entry;
            let correctWordIndex = undefined;
            let wordGuessSliced = false;
            wordGuess.pieceArray.forEach((piece, index) => {
                if (!wordGuessSliced) {
                    if (index === 0) {
                        if (piece.correctWordPieceIndex === 0) {
                            correctWordIndex = piece.correctWordIndex;
                        }
                        else {
                            this.sliceOrRemoveWordGuess(piece);
                            wordGuessSliced = true;
                        }
                    }
                    else if (correctWordIndex !== undefined) {
                        if (piece.correctWordIndex !== correctWordIndex || piece.correctWordPieceIndex !== index) {
                            // Find the last valid tile that isn't a blank
                            let tempIndex = index - 1;
                            let isBlank = true;
                            while (isBlank) {
                                if (wordGuess.pieceArray[tempIndex].letter === " ") {
                                    tempIndex -= 1;
                                    wordGuess.pieceArray[tempIndex].setIsLocked(false);
                                    wordGuess.pieceArray[tempIndex].setIsClickable(true);
                                }
                                else {
                                    isBlank = false;
                                }
                            }
                            wordGuess.slicePieceArray(tempIndex);
                            correctWordIndex = undefined;
                        }
                        else {
                            if (piece.correctWordPieceIndex === this.correctWords[correctWordIndex].pieceArray.length - 1) {
                                wordGuess.setIsLocked(true);
                                wordGuess.setIsClearable(false);
                            }
                            else {
                                wordGuess.pieceArray[index - 1].setIsLocked(true);
                                wordGuess.pieceArray[index - 1].setIsClickable(false);
                            }
                        }
                    }
                }
            });
            if (wordGuess.pieceArray.length > 0 && !wordGuessSliced) {
                wordGuess.setLastPieceAnchorColor();
            }
        });
        this._checkAllsUsed++;
        return this.releaseActiveWordGuess(selectedPiece, true);
    }
    /**
     * Reveals the next character associated with the piece that was clicked
     * on the hint activation
     */
    revealLittleHintAnswer(selectedPiece, difficulty, shouldCountAsHintMove = true) {
        // return if piece is undefined or not a part of correctWord
        if (selectedPiece === undefined || selectedPiece.correctWordIndex === undefined)
            return;
        let numberOfRevealedTiles = 1;
        if (difficulty === _1.Difficulty.EASY) {
            numberOfRevealedTiles = 3;
        }
        else if (difficulty === _1.Difficulty.MEDIUM) {
            numberOfRevealedTiles = 2;
        }
        if (!shouldCountAsHintMove) {
            numberOfRevealedTiles = 0;
        }
        // Loop through the pieces of the correctWord which the selectedPiece belongs to
        this.correctWords[selectedPiece.correctWordIndex].pieceArray.forEach((piece, index) => {
            // Add the piece to new wordGuess
            if (piece.correctWordPieceIndex === 0) {
                if (piece.state !== _1.PieceState.ANCHOR) {
                    this.sliceOrRemoveWordGuess(piece);
                    this.AddPieceAsAnchor(piece);
                    numberOfRevealedTiles--;
                }
                this.setActiveWordGuess(piece);
                this.activeWordGuess?.setIsClearable(false);
            }
            else {
                if (this.activeWordGuess?.wordGuessKey !== piece.wordGuessKey) {
                    this.sliceOrRemoveWordGuess(piece);
                }
            }
            if (numberOfRevealedTiles > 0 && piece.wordGuessKey === undefined) {
                this.tryAddPieceToActiveWord(piece);
                if (selectedPiece.correctWordIndex !== undefined && index === this.correctWords[selectedPiece.correctWordIndex].pieceArray.length - 1) {
                    this._shouldCountMove = true;
                    this.activeWordGuess?.setIsLocked(true);
                }
                // if (index > 0) {
                //   this.activeWordGuess?.pieceArray[index - 1].setIsLocked(true);
                //   this.activeWordGuess?.pieceArray[index - 1].setIsClickable(false);
                // }
                if (piece.letter !== " ") {
                    numberOfRevealedTiles--;
                }
            }
            if (numberOfRevealedTiles <= 0 && selectedPiece.wordGuessKey !== this.activeWordGuess?.wordGuessKey) {
                if (selectedPiece.correctWordPieceIndex === this.activeWordGuess?.getWord().length) {
                    this.tryAddPieceToActiveWord(selectedPiece);
                }
                else {
                    selectedPiece.setIsFlashing(true, this.activeWordGuess?.backgroundColor);
                }
                // else {
                //   selectedPiece.backgroundColor = backgroundColor;
                //   selectedPiece.accentColor = accentColor;
                //   selectedPiece.letter = selectedPiece.letter.toUpperCase();
                // }
            }
        });
        this.setGameState();
        if (!this._countedMove && this._shouldCountMove) {
            this._moveCount++;
            this._countedMove = true;
        }
        if (shouldCountAsHintMove) {
            this._littleHintsUsed++;
        }
        this.activeWordGuess?.setLastPieceAnchorColor();
        return this.releaseActiveWordGuess(selectedPiece, true);
    }
    /**
     * Takes in a piece and sets it to the board at its original position
     * @param piece
     * @returns The updated board
     */
    flashNextPiece(piece) {
        if (piece.wordGuessKey === undefined || this._difficulty > _1.Difficulty.MEDIUM)
            return;
        const wordGuess = this.wordGuesses[piece.wordGuessKey];
        if (wordGuess.pieceArray[0].correctWordIndex === undefined)
            return;
        const correctWord = this.correctWords[wordGuess.pieceArray[0].correctWordIndex];
        correctWord.pieceArray.forEach((piece) => {
            if (piece.state === _1.PieceState.NODE) {
                piece.setIsFlashing(true, wordGuess.pieceArray[0].backgroundColor);
                return;
            }
        });
    }
    /**
     * Takes in a piece and sets it to the board at its original position
     * @param piece
     * @returns The updated board
     */
    updateBoard(piece) {
        if (piece)
            this.board[piece.boardIndex] = piece;
        return [...this.board];
    }
    /**
     * Tries to break the active word at the piece index.
     * Usually called as the user doubles back on their chain.
     * EX: BELT is active word and piece is E. New word is BE.
     * @param piece Piece that active word will end at
     * @returns True if the break was successful
     */
    trySliceActiveWord(piece) {
        let lastArrayPiece = this.activeWordGuess?.pieceArray.at(-1);
        // Verify the piece is in the active word group
        if (piece.wordGuessKey !== this.activeWordGuess?.wordGuessKey || piece.wordGuessPieceIndex === undefined || this.activeWordGuess === undefined || piece.id() === lastArrayPiece.id())
            return false;
        console.debug(`Breaking active word ${this.activeWordGuess.getWord()} at ${piece.letter}`);
        this.activeWordGuess.slicePieceArray(piece.wordGuessPieceIndex);
        this._lastMovedOnPiece = this.activeWordGuess.pieceArray[this.activeWordGuess.pieceArray.length - 1];
        return true;
    }
    /**
     * Adds piece to end of active word if all conditions are met
     * @param piece Piece to be added to Active Word
     * @returns True if piece was added
     */
    tryAddPieceToActiveWord(piece) {
        if (!this.checkIfPieceCanBeAdded(piece))
            return false;
        console.debug(`Adding piece ${piece.letter}`);
        this.activeWordGuess?.addPiece(piece);
        this._lastMovedOnPiece = piece;
        return true;
    }
    /**
     * Makes add piece conditions check on piece
     * @param piece
     * @returns True if piece does not fail any condition check
     */
    checkIfPieceCanBeAdded(piece) {
        if (this.activeWordGuess === undefined) {
            console.debug("activeWordGuess is undefined. Previous tile failed to be grabbed");
            return false;
        }
        // Piece cannot be added if it is an anchor piece
        if (piece.state === _1.PieceState.ANCHOR) {
            console.debug("Cannot add piece because it is an anchor");
            return false;
        }
        // Piece cannot be added if it is in another wordGuess
        if (piece.wordGuessKey !== undefined) {
            console.debug("This letter is already in another wordguess");
            return false;
        }
        if (this.activeWordGuess.pieceArray.length > 0) {
            let lastArrayPiece = this.activeWordGuess.pieceArray.at(-1);
            // Piece cannot be added if it is greater than 2 rows away
            if (Math.abs(piece.row - lastArrayPiece.row) > 1)
                return false;
            // Piece cannot be added if it is greater than 2 columns away
            if (Math.abs(piece.column - lastArrayPiece.column) > 1)
                return false;
            // Piece cannot be added if row and column are equal to 1 (diagonal)
            if (Math.abs(piece.row - lastArrayPiece.row) === 1 && Math.abs(piece.column - lastArrayPiece.column) === 1)
                return false;
        }
        return true;
    }
    checkTime() {
        if (this.timeTaken >= this._timeLimit &&
            this._enableTimeCap //&&
        //this.gameState === GameState.PLAYING
        ) {
            this.setGameState();
            return true;
        }
        return false;
    }
    /**
     *
     * @returns
     */
    setGameState() {
        let hasMovesLeft = true;
        let hasTimeLeft = true;
        // Evaluate if player has exceeded the caps for the level
        if (this.moveCount >= this._moveLimit && this._enableMoveCap) {
            hasMovesLeft = false;
            this._moveCountReached = true;
        }
        if (this.timeTaken >= this._timeLimit && this._enableTimeCap) {
            hasTimeLeft = false;
            this._timeCapReached = true;
        }
        /* Only evaluate is they are still playing IF there is still time
       And moves left
    */
        // Check to see if the guesses match the answers. If not, return false
        if (!(0, utility_1.CheckGuessesMatchAnswers)(this.wordGuesses, this.correctWords) && hasMovesLeft && hasTimeLeft && !this._gaveUpOnPuzzle) {
            this._gameState = _1.GameState.PLAYING;
            return;
        }
        const unassignedTileCheck = (0, utility_1.CheckForUnassignedTiles)(this.board);
        /* Only evaluate is they are still playing IF there is still time
       And moves left
    */
        if (unassignedTileCheck.gameState === _1.GameState.PLAYING && hasMovesLeft && hasTimeLeft && !this._gaveUpOnPuzzle) {
            this._gameState = _1.GameState.PLAYING;
            return;
        }
        // Only check for scores if they are still in a playing state
        if (this.gameState === _1.GameState.PLAYING) {
            this._blanksLeft = unassignedTileCheck.blankCount;
            let hintPentaltyPoints = 0;
            let timerCountsInScore = false;
            if (this._levelType === _1.LevelTypes.DAILY || this._levelType === _1.LevelTypes.GAUNTLET) {
                hintPentaltyPoints = 75 * this.littleHintsUsed + 30 * this.checkAllsUsed;
                timerCountsInScore = true;
            }
            this._finalScore = Math.floor((0, utility_1.getMovesMadeScore)(this._moveCount, this.correctWords.length, this._difficulty, timerCountsInScore) +
                (0, utility_1.getTimeTakenScore)(this._timeTaken, this._difficulty, timerCountsInScore) +
                (0, utility_1.getBlanksLeftScore)(this._blanksLeft, this._difficulty, this._numberOfPuzzleTiles, timerCountsInScore) -
                hintPentaltyPoints);
            const scoreMetrics = (0, utility_1.getScoringMetricsByDifficulty)(this._difficulty);
            this.lockAllTiles();
            if (!hasMovesLeft || !hasTimeLeft || this._gaveUpOnPuzzle) {
                if (this._gameState === _1.GameState.PLAYING) {
                    this._gameState = _1.GameState.NO_STAR;
                    const rightWords = (0, utility_1.GetAmountOfCorrectWordsGuessed)(this.wordGuesses, this.correctWords);
                    // configure the final score based on precentage of how many words they found
                    this._finalScore = Math.floor(this._finalScore * ((1 + 2 * rightWords) / (3 * this.correctWords.length)));
                    this.revealAllAnswers();
                }
                this.puzzleComplete = true;
                return;
            }
            if (this._finalScore > scoreMetrics.threeStarMin) {
                this._gameState = _1.GameState.THREE_STAR;
            }
            else if (this._finalScore > scoreMetrics.twoStarMin) {
                this._gameState = _1.GameState.TWO_STAR;
            }
            else {
                this._gameState = _1.GameState.ONE_STAR;
            }
            this.puzzleComplete = true;
        }
        return;
    }
    /**
     * Locks all the tiles if a game is complete
     * @returns void
     */
    lockAllTiles() {
        this.board.forEach((piece) => {
            piece.setIsClickable(false);
            piece.setIsLocked(true);
        });
        return;
    }
    /**
     * Checks a couple conditions to see if a piece can be added as an anchor
     * Adds the piece as anchor if conditions are all true
     * @param piece
     * @returns true if piece was added
     */
    tryAddPieceAsAnchor(piece) {
        // Can only convert node to anchor
        if (piece.state !== _1.PieceState.NODE)
            return false;
        // Cannot add piece as anchor if difficulty is on easy or medium
        if (!(0, _1.CanAddAnchors)(this._difficulty))
            return false;
        this.AddPieceAsAnchor(piece);
        return true;
    }
    /**
     * Adds piece as anchor and updates word guesses with new wordguess
     * @param piece
     */
    AddPieceAsAnchor(piece) {
        let color = this.colorGenerator.getNewColor();
        this.wordGuesses[this._wordGuessIndex.toString()] = new _1.WordGuess(piece, color.background, color.accent, color.coreColor, color.terminalAccent, color.lockedAccent, styles_1.colors.white, color.cssFilterBackground, color.cssFilterAccent, this._wordGuessIndex);
        this._wordGuessIndex++;
        this._wordGuessCount++;
    }
    setTimeTaken(timeTaken) {
        this._timeTaken = timeTaken;
    }
    setFinalTimeTaken(timeTaken) {
        this._timeTaken = timeTaken;
        this.clockRunning = false;
    }
}
exports.PuzzleBoard = PuzzleBoard;
