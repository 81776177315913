"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserProfileInformation = void 0;
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
// Gets the user profile data
// TODO: Define return type
async function getUserProfileInformation() {
    const user = await (0, auth_1.fetchAuthSession)();
    let url = "/items/getUserProfileInformation?sub=" + user.userSub;
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(url));
    const response = await restOperation.response;
    const jsonBody = await response.body.json();
    return jsonBody;
}
exports.getUserProfileInformation = getUserProfileInformation;
