import { Typography } from "@mui/material";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import "../../css/Typography.css";
import { colors, LevelTypes } from "@summitrhode/shared";
import { useEffect, useState } from "react";

interface Props {
    boxPadding?: string;
    isMobile: boolean;
    height?: number;
    levelType: LevelTypes;
    moveLimit: number;
    fontColor?: string;
}

export function MoveBox({ boxPadding, isMobile, height, levelType, moveLimit, fontColor = colors.white }: Props) {
    const { puzzleBoard } = usePuzzleBoardContext();

    const [shouldCountMovesDown, setShouldCountMovesDown] = useState<boolean>(levelType === LevelTypes.GAUNTLET);

    const [moveCount, setMoveCount] = useState<number>(0);

    useEffect(() => {
        if (puzzleBoard) {
            if (shouldCountMovesDown) {
                setMoveCount(moveLimit - puzzleBoard.moveCount);
            } else {
                setMoveCount(puzzleBoard.moveCount);
            }
        }
    }, [puzzleBoard?.moveCount]);

    if (levelType === LevelTypes.DAILY || levelType === LevelTypes.GAUNTLET) {
        return (
            <Typography
                sx={{
                    color: fontColor,
                    fontSize: isMobile ? "1.75vh" : "20px",
                }}
            >
                {shouldCountMovesDown ? "Moves Left: " : "Moves: "}
                {moveCount}
            </Typography>
        );
    } else {
        return (
            <>
                <Typography
                    sx={{
                        color: fontColor,
                        fontSize: isMobile ? "2vh" : "20px",
                    }}
                >
                    {shouldCountMovesDown ? "Moves Left" : "Moves"}
                </Typography>
                <Typography
                    sx={{
                        color: fontColor,
                        fontSize: isMobile ? "2vh" : "20px",
                    }}
                >
                    {moveCount}
                </Typography>
            </>
        );
    }
}
