"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertAnswerCoordinatesToArray = void 0;
/**
 * Breaks up DB answer coordinates separated by : to be an array
 * @param answerCoordinates
 * @returns number[][]
 */
function ConvertAnswerCoordinatesToArray(answerCoordinates) {
    const convertedAnswerCoordinates = [];
    for (let answerWord = 0; answerWord < answerCoordinates.length; answerWord++) {
        const stringArray = answerCoordinates[answerWord].split(":");
        const numberArray = [];
        stringArray.forEach((str) => {
            numberArray.push(Number(str));
        });
        convertedAnswerCoordinates.push(numberArray);
    }
    return convertedAnswerCoordinates;
}
exports.ConvertAnswerCoordinatesToArray = ConvertAnswerCoordinatesToArray;
