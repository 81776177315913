"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Word = void 0;
class Word {
    constructor(startingPiece, wordIndex) {
        this.wordIndex = wordIndex;
        this.pieceArray = [];
        this.pieceArray.push(startingPiece);
    }
    /**
     * Return the word spelled by the pieces in the array
     */
    getWord() {
        if (this.pieceArray.length === 0)
            return "";
        return this.pieceArray
            .map((piece) => (piece.letter ? piece.letter : " "))
            .reduce((p, c) => p + c)
            .replaceAll(" ", "");
    }
}
exports.Word = Word;
