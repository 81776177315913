import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { ExpansionPack } from "@summitrhode/shared";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

interface Props {
    currentPackDetails: ExpansionPack | undefined;
    createExpansionPackClicked: boolean;
    updateExpansionPackClicked: boolean;
    handleClose: (saved: boolean) => void;
    handleSave: (isUpdate: boolean, packName: string, packDescription: string, isAvailableToUsers: boolean, isAvailableToAdmins: boolean) => void;
}

export default function EditExpansionPackModal({ currentPackDetails, createExpansionPackClicked, updateExpansionPackClicked, handleClose, handleSave }: Props) {
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [packName, setPackName] = useState<string>("");
    const [packDescription, setPackDescription] = useState<string>("");
    const [isAvailableToUsers, setIsAvailableToUsers] = useState<boolean>(false);
    const [isAvailableToAdmins, setIsAvailableToAdmins] = useState<boolean>(false);

    useEffect(() => {
        if (createExpansionPackClicked) {
            setPackName("");
            setPackDescription("");
            setIsAvailableToUsers(false);
            setOpen(true);
            return;
        }

        if (updateExpansionPackClicked) {
            //Use current pack details to prepopulate
            setIsUpdate(true);
            if (currentPackDetails) {
                setPackName(currentPackDetails.name);
                setPackDescription(currentPackDetails.description);
                setIsAvailableToUsers(currentPackDetails.isAvailableToUsers ? currentPackDetails.isAvailableToUsers : false);
                setIsAvailableToAdmins(currentPackDetails.isAvailableToAdmins ? currentPackDetails.isAvailableToAdmins : false);
            }
            setOpen(true);
            return;
        }

        setOpen(false);
    }, [createExpansionPackClicked, updateExpansionPackClicked]);

    useEffect(() => {
        if (!isAvailableToAdmins) {
            setIsAvailableToUsers(false);
        }
    }, [isAvailableToAdmins]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <TextField
                    id="expansion-pack-name"
                    fullWidth
                    label="Expansion Pack Name"
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                    onChange={(e) => setPackName(e.target.value)}
                    defaultValue={packName}
                />
                <TextField
                    id="expansion-pack-description"
                    fullWidth
                    label="Expansion Pack Description"
                    multiline
                    maxRows={4}
                    onChange={(e) => setPackDescription(e.target.value)}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                    defaultValue={packDescription}
                />
                {isUpdate && (
                    <>
                        <FormControlLabel control={<Checkbox checked={isAvailableToAdmins} onChange={(e) => setIsAvailableToAdmins(e.target.checked)} />} label="Available To Admins" />
                        <FormControlLabel
                            control={<Checkbox checked={isAvailableToUsers} disabled={!isAvailableToAdmins} onChange={(e) => setIsAvailableToUsers(e.target.checked)} />}
                            label="Available To Users"
                        />
                    </>
                )}
                <Button onClick={() => handleClose(false)}>Close</Button>
                <Button onClick={() => handleSave(isUpdate, packName, packDescription, isAvailableToUsers, isAvailableToAdmins)}>Save</Button>
            </Box>
        </Modal>
    );
}
