"use strict";
/**
 * Each word entry in the wordbank shall have a current state.
 * Base state is SEARCHING. Upon arriving at a word can be RIGHT_WORD_WRONG_LETTERS,
  RIGHT_WORD_WRONG_BLANKS,
  RIGHT_WORD,
  RIGHT_WORD_EXACT_MATCH,
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordState = void 0;
var WordState;
(function (WordState) {
    WordState[WordState["SEARCHING"] = 0] = "SEARCHING";
    WordState[WordState["RIGHT_WORD_WRONG_LETTERS"] = 1] = "RIGHT_WORD_WRONG_LETTERS";
    WordState[WordState["RIGHT_WORD_WRONG_BLANKS"] = 2] = "RIGHT_WORD_WRONG_BLANKS";
    WordState[WordState["RIGHT_WORD"] = 3] = "RIGHT_WORD";
    WordState[WordState["RIGHT_WORD_EXACT_MATCH"] = 4] = "RIGHT_WORD_EXACT_MATCH";
})(WordState || (exports.WordState = WordState = {}));
