"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckWordState = void 0;
const models_1 = require("../../models");
/**
 * Goes through each word entry and checks the status
 * if each word is an exact match or not
 * @param board
 * @returns WordState
 */
function CheckWordState(wordGuess, correctWords, difficulty) {
    let hasRightWord = false;
    let hasWrongLetters = false;
    let hasWrongBlanks = false;
    for (const correctWord of correctWords) {
        //Check if words match
        if (correctWord.getWord() === wordGuess.getWord()) {
            hasRightWord = true;
            const maxIndex = wordGuess.pieceArray.length - 1;
            //Make sub-array of only letters
            const correctLetterBoardIndexes = [];
            const guessLetterBoardIndexes = [];
            let counter = 0;
            for (const piece of correctWord.pieceArray) {
                if (piece.letter !== " ")
                    correctLetterBoardIndexes.push(piece.boardIndex);
                const pieceIndex = counter <= maxIndex ? counter : maxIndex;
                if (piece.boardIndex !== wordGuess.pieceArray[pieceIndex].boardIndex) {
                    hasWrongBlanks = true;
                }
                counter++;
            }
            for (let piece of wordGuess.pieceArray) {
                if (piece.letter !== " ")
                    guessLetterBoardIndexes.push(piece.boardIndex);
            }
            for (let boardIndex in correctLetterBoardIndexes) {
                if (correctLetterBoardIndexes[boardIndex] !==
                    guessLetterBoardIndexes[boardIndex]) {
                    hasWrongLetters = true;
                }
            }
            break;
        }
    }
    if (hasRightWord) {
        // if (difficulty === Difficulty.EASY) {
        if (hasWrongLetters) {
            return models_1.WordState.RIGHT_WORD_WRONG_LETTERS;
        }
        else if (hasWrongBlanks) {
            return models_1.WordState.RIGHT_WORD_WRONG_BLANKS;
        }
        else {
            return models_1.WordState.RIGHT_WORD_EXACT_MATCH;
        }
        // } else if (
        //   difficulty === Difficulty.HARD ||
        //   difficulty === Difficulty.MEDIUM
        // ) {
        //   return { wordState: WordState.RIGHT_WORD };
        // } else {
        //   return { wordState: WordState.SEARCHING };
        // }
    }
    else {
        return models_1.WordState.SEARCHING;
    }
}
exports.CheckWordState = CheckWordState;
