"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserProfileDetailInformation = void 0;
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
// Gets the user profile data
// TODO: Define the output here
async function getUserProfileDetailInformation(profileID) {
    const user = await (0, auth_1.fetchAuthSession)();
    let url = "/items/getUserProfileDetailInformation?sub=" + user.userSub;
    if (profileID) {
        url += "&profileID=" + profileID;
    }
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(url));
    const response = await restOperation.response;
    return JSON.parse(await response.body.text());
}
exports.getUserProfileDetailInformation = getUserProfileDetailInformation;
