import React, { useContext, useState } from "react";
import { PromptType, PuzzleBoard, WordGuess } from "@summitrhode/shared";

export interface IPuzzleBoardContext {
    puzzleBoard: PuzzleBoard | undefined;
    updatePuzzleBoard: (puzzleBoard: PuzzleBoard) => void;
    wordGuesses: { [id: number]: WordGuess };
    updateWordGuesses: () => void;
    wordGuessCount: number;
    currentTutorialPromptState: PromptType;
    updateCurrentTutorialPromptState: (promptType: PromptType) => void;
}

interface Props {
    children: React.ReactNode;
}

const puzzleBoardDefaults: IPuzzleBoardContext = {
    puzzleBoard: undefined,
    updatePuzzleBoard: (puzzleBoard: PuzzleBoard) => undefined,
    wordGuesses: {},
    updateWordGuesses: () => undefined,
    wordGuessCount: 0,
    currentTutorialPromptState: PromptType.INITIAL,
    updateCurrentTutorialPromptState: (promptType: PromptType) => undefined,
};

const PuzzleBoardContext = React.createContext<IPuzzleBoardContext>(puzzleBoardDefaults);

export function usePuzzleBoardContext() {
    return useContext(PuzzleBoardContext);
}

export const PuzzleBoardProvider: React.FC<Props> = ({ children }) => {
    const [puzzleBoard, setPuzzleBoard] = useState<PuzzleBoard>();
    const [wordGuesses, setWordGuesses] = useState<{ [id: number]: WordGuess }>({});
    const [wordGuessCount, setWordGuessCount] = useState<number>(0);

    const [currentTutorialPromptState, setCurrentTutorialPromptState] = useState<PromptType>(PromptType.INITIAL);

    /**
     * Called any time wordGuesses changes
     */
    const updateWordGuesses = () => {
        if (puzzleBoard) {
            setWordGuesses({ ...puzzleBoard.wordGuesses });
            let count = 0;
            Object.entries({ ...puzzleBoard.wordGuesses }).map(([id, wordGuess]) => {
                count++;
            });
            setWordGuessCount(count);
        }
    };

    const updatePuzzleBoard = (newPuzzleBoard: PuzzleBoard) => {
        setPuzzleBoard(newPuzzleBoard);
        setWordGuesses(newPuzzleBoard.wordGuesses);
    };

    const updateCurrentTutorialPromptState = (promptType: PromptType) => {
        setCurrentTutorialPromptState(promptType);
    };

    // const updateLevelCompleteStats = (
    //   winState: WinState,
    //   moveCount: number,
    //   secondsTaken: number
    // ) => {
    //   setLevelCompleteStats({
    //     winState: winState,
    //     moveCount: moveCount,
    //     secondsTaken: secondsTaken,
    //   });
    // };

    const value = {
        puzzleBoard,
        updatePuzzleBoard,
        wordGuesses,
        updateWordGuesses,
        wordGuessCount,
        currentTutorialPromptState,
        updateCurrentTutorialPromptState,
        // levelCompleteStats,
        // updateLevelCompleteStats,
    };

    return <PuzzleBoardContext.Provider value={value}>{children}</PuzzleBoardContext.Provider>;
};
