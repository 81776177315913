"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminWordRelationshipDetails = void 0;
class AdminWordRelationshipDetails {
    constructor(parentID, parentName, parentSubXrefID, subID, subName, wordID, wordName) {
        this.parentID = parentID;
        this.parentName = parentName;
        this.parentSubXrefID = parentSubXrefID;
        this.subID = subID;
        this.subName = subName;
        this.wordID = wordID;
        this.wordName = wordName;
    }
}
exports.AdminWordRelationshipDetails = AdminWordRelationshipDetails;
