"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Puzzle = void 0;
class Puzzle {
    constructor(letters, answerCoordinates, parentCategory, subCategory, rows, columns, difficulty, isAdminPuzzle, title = parentCategory, subtitle = "", words, parentCategoryId, subCategoryId, levelNumber, RDSLevelID, levelType, timeLimit, moveLimit) {
        this.letters = letters;
        this.answerCoordinates = answerCoordinates;
        this.parentCategory = parentCategory;
        this.subCategory = subCategory;
        this.rows = rows;
        this.columns = columns;
        this.difficulty = difficulty;
        this.isAdminPuzzle = isAdminPuzzle;
        this.title = title;
        this.subtitle = subtitle;
        this.words = words;
        this.parentCategoryId = parentCategoryId;
        this.subCategoryId = subCategoryId;
        this.levelNumber = levelNumber;
        this.RDSLevelID = RDSLevelID;
        this.levelType = levelType;
        this.timeLimit = timeLimit;
        this.moveLimit = moveLimit;
    }
    setLetters(letters) {
        this.letters = letters;
    }
    setAnswerCoordinates(answerCoordinates) {
        this.answerCoordinates = answerCoordinates;
    }
    // // TODO: Need to adjust this to number when firebase shift is complete
    // public setParentCategoryId(parentCategoryId: string) {
    //   this.parentCategoryId = parentCategoryId;
    // }
    // // TODO: Need to adjust this to number when firebase shift is complete
    // public setSubCategoryId(subCategoryId: string) {
    //   this.subCategoryId = subCategoryId;
    // }
    setRows(rows) {
        this.rows = rows;
    }
    setColumns(columns) {
        this.columns = columns;
    }
    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }
    setIsAdminPuzzle(isAdminPuzzle) {
        this.isAdminPuzzle = isAdminPuzzle;
    }
    setWords(words) {
        this.words = words;
    }
    setlevelNumber(levelNumber) {
        this.levelNumber = levelNumber;
    }
    // TODO: Need to adjust this to number when firebase shift is complete
    setRDSLevelID(puzzleId) {
        this.RDSLevelID = puzzleId;
    }
    get rowRatio() {
        if (this.rows >= this.columns)
            return 1;
        else
            return this.rows / this.columns;
    }
    get columnRatio() {
        if (this.columns >= this.rows)
            return 1;
        else
            return this.columns / this.rows;
    }
}
exports.Puzzle = Puzzle;
