import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../../css/Typography.css";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";

import { colors, PuzzleBoard, Difficulty, LevelTypes, componentColors } from "@summitrhode/shared";
import { TimerBox } from "./TimerBox";
import { MoveBox } from "./MoveBox";

interface Props {
    boxPadding: string;
    cacheLevelData: (board: PuzzleBoard | undefined) => void;
    difficulty: Difficulty;
    enableRevealPuzzleButton: () => void;
    isMobile: boolean;
    levelNumber: number | string;
    pausePuzzle: boolean;
    showTimer: boolean;
    subtitle: string;
    title: string;
    levelType: LevelTypes;
    timeLimit: number;
    timeOutLevel: () => void;
    moveLimit: number;
}

export function LevelBox({
    boxPadding,
    cacheLevelData,
    difficulty,
    enableRevealPuzzleButton,
    isMobile,
    levelNumber,
    pausePuzzle,
    showTimer,
    subtitle,
    title,
    levelType,
    timeLimit,
    timeOutLevel,
    moveLimit,
}: Props) {
    const { puzzleBoard } = usePuzzleBoardContext();
    const [shouldCountMovesDown, setShouldCountMovesDown] = useState<boolean>(levelType === LevelTypes.GAUNTLET);
    const [titleSize, setTitleSize] = useState<number>(2);
    const [subtitleSize, setSubtitleSize] = useState<number>(2);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    useEffect(() => {
        setValues();
    }, []);

    useEffect(() => {
        setValues();
    }, [title]);

    const setValues = () => {
        const characterLimitForTitle = 3.5;
        const characterLimitForSubtitle = 3.5;
        const titleScaler = subtitle.length > 0 ? 1.5 : 2;
        const subtitleScaler = subtitle.length > 0 ? 1.5 : 0;
        const minimumLength = 5;

        let titleLength = title.length < minimumLength ? minimumLength : title.length;
        let subtitleLength = subtitle.length < minimumLength ? minimumLength : subtitle.length;

        setTitleSize((characterLimitForTitle / Math.log(titleLength)) * titleScaler);
        setSubtitleSize((characterLimitForSubtitle / Math.log(subtitleLength)) * subtitleScaler);
    };

    return isMobile ? (
        <Paper
            elevation={5}
            sx={{
                alignItems: "center",
                borderRadius: isMobile ? "1vh" : "3vh",
                background: componentColors.primary.backgroundColorFade,
                // backgroundColor: colors.tertiary + "CC",
                borderColor: componentColors.primary.borderColor,
                border: `.4vh solid ${componentColors.primary.borderColor}`,
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                marginTop: ".75vh",
                maxWidth: "1000px",
                padding: boxPadding,
                paddingX: "3vw",
                width: "96vw",
            }}
        >
            <div style={{ textAlign: "center", width: "20vw" }}>
                {typeof levelNumber === "number" && levelNumber > 0 ? (
                    <Typography sx={{ color: colors.white, fontSize: "2vh" }}>Lvl {levelNumber}</Typography>
                ) : (
                    <Typography sx={{ color: colors.white, fontSize: "2vh" }}>{levelNumber}</Typography>
                )}
                <Typography sx={{ color: colors.white, fontSize: "2vh" }}>{capitalizeFirstLetter(Difficulty[difficulty])}</Typography>
            </div>
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <Typography
                    sx={{
                        color: colors.white,
                        display: "flex",
                        fontSize: `${titleSize}vh`,
                        fontWeight: subtitle && subtitle.length > 0 ? "" : "bold",
                        textAlign: "center",
                    }}
                >
                    {title}
                </Typography>
                {subtitle && (
                    <Typography
                        sx={{
                            color: colors.white,
                            fontSize: `${subtitleSize}vh`,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {subtitle}
                    </Typography>
                )}
            </div>
            <div style={{ textAlign: "center", width: "20vw" }}>
                <MoveBox fontColor={colors.white} levelType={levelType} isMobile moveLimit={moveLimit} />
                <TimerBox
                    cacheLevelData={cacheLevelData}
                    enableRevealPuzzleButton={enableRevealPuzzleButton}
                    isMobile={isMobile}
                    pausePuzzle={pausePuzzle}
                    showTimer={showTimer}
                    levelType={levelType}
                    timeLimit={timeLimit}
                    timeOutLevel={timeOutLevel}
                />
            </div>
        </Paper>
    ) : (
        <Paper
            sx={{
                maxWidth: 300,
                borderRadius: "16px",
                border: "1px solid black",
                padding: boxPadding,
            }}
        >
            <Typography variant="h6" component="div" align="center">
                {typeof levelNumber === "number" && levelNumber > 0 ? `Level: ${levelNumber} :` : levelNumber}
                {Difficulty[difficulty]}
            </Typography>
            <Typography variant="body1" component="div" align="center">
                {title}
            </Typography>
            <Typography variant="body1" component="div" align="center">
                {subtitle}
            </Typography>
        </Paper>
    );
}
