"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthPostInput = void 0;
const getCurrentUserAuthToken_1 = require("./getCurrentUserAuthToken");
async function createAuthPostInput(apiPath, bodyInput) {
    return {
        apiName: "pathwordsAPI",
        path: apiPath,
        options: {
            body: bodyInput,
            headers: {
                Authorization: await (0, getCurrentUserAuthToken_1.getCurrentUserAuthTokenString)(),
            },
        },
    };
}
exports.createAuthPostInput = createAuthPostInput;
