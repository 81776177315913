import { Paper, Button, Typography, Card, CardContent, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLevelContext } from "../../context/levelContext";

import "./HomePage.css";
import { LevelTypes, colors, RdsLevel } from "@summitrhode/shared";

interface Props {
    isMobile: boolean;
    userCurrentCampaignLevel: number;
    expansionPacks: {
        id: number;
        name: string;
        description: string;
        levelNumber: number;
        numberOfLevels: number;
    }[];
}

export function CampaignPuzzleMenuBox({ isMobile, userCurrentCampaignLevel, expansionPacks }: Props) {
    const navigate = useNavigate();
    const { updateCurrentExpansionLevel, numberOfLevelsInCampaign } = useLevelContext();

    const playCampaignPuzzleClicked = () => {
        // updateCurrentLevel(
        //   new RdsLevel("UIDPlaceHolder", userCurrentCampaignLevel)
        // );
        navigate("/campaign");
    };

    const expansionPackClicked = (id: number, levelNumber: number, packName: string) => {
        updateCurrentExpansionLevel(new RdsLevel("launch", levelNumber, LevelTypes.EXPANSION, id, packName));
        navigate(`/expansion`);
    };

    return (
        // <>
        <Paper
            sx={{
                textAlign: "center",
                width: "50%",
                maxWidth: "800px",
                // justifySelf: "center",
                alignItems: "center",
                // height: "30vh",
                // border: "1px solid black",
                padding: "1%",
                display: "flex",
                flexDirection: "column",
                marginX: "25%",
                // background: "rgb(217,217,217,75%)",
                // flexBasis: "40%",
                border: "2px solid",
                borderColor: colors.accentShade,
                // borderRadius: "1.5%",
                backgroundColor: colors.accent,
            }}
            elevation={5}
        >
            <Typography variant="h5" color={"white"}>
                Starter Pack
            </Typography>

            <Button disabled={userCurrentCampaignLevel > numberOfLevelsInCampaign} onClick={playCampaignPuzzleClicked} variant="contained">
                {userCurrentCampaignLevel <= numberOfLevelsInCampaign ? "Play Level " + userCurrentCampaignLevel : "Complete"}
            </Button>
        </Paper>
        /* <Paper
        sx={{
          width: "95%",
          maxWidth: "800px",
          // height: "30vh",
          // border: "1px solid black",
          padding: "1%",
          display: "flex",
          flexDirection: "column",
          margin: "1%",
          // justifyContent: "center",
          // alignItems: "center",
          // boxSizing:"border-box",
          paddingTop: "15px",
          flexBasis: "40%",
          background: colors.accentShade + "CC", //"rgb(217,217,217,75%)",
        }}
        elevation={5}
      >
        <Typography
          variant="h6"
          sx={{
            textDecorationLine: "underline",
            fontWeight: "bold",
            backgroundColor: colors.white + "BB",
          }}
        >
          Starter Pack
        </Typography>
        <Button
          disabled={userCurrentCampaignLevel > numberOfLevelsInCampaign}
          onClick={playCampaignPuzzleClicked}
          variant="contained"
        >
          {userCurrentCampaignLevel <= numberOfLevelsInCampaign
            ? "Play Level " + userCurrentCampaignLevel
            : "Complete"}
        </Button> */

        /* <hr></hr>
        <Paper
          className="expansionContainer"
          elevation={5}
          sx={{
            backgroundColor: colors.grayscaleShade + "88",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textDecorationLine: "underline",
              fontWeight: "bold",
              backgroundColor: colors.white + "BB",
            }}
          >
            Expansion Packs
          </Typography>
          <div className="spacer" />
          <div className="expansionOptionsContainer">
            {expansionPacks.map((pack) => (
              <div key={pack.id}>
                <div className="spacer" />
                <div className="expansionOption" style={{ margin: "5px" }}>
                  <Paper
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "5px",
                      height: "100px",
                      justifyContent: "center",
                      width: "40vw",
                      maxWidth: "300px",
                    }}
                  >
                    <div>{pack.name}</div>
                    <Button
                      disabled={pack.levelNumber + 1 > pack.numberOfLevels}
                      sx={{ backgroundColor: colors.accent, border: "0" }}
                      onClick={() =>
                        expansionPackClicked(
                          pack.id,
                          pack.levelNumber ? pack.levelNumber + 1 : 1,
                          pack.name
                        )
                      }
                      variant="contained"
                    >
                      {pack.levelNumber + 1 <= pack.numberOfLevels
                        ? "Level " +
                          (pack.levelNumber ? pack.levelNumber + 1 : 1)
                        : "Complete"}
                    </Button>
                  </Paper>
                </div>
              </div>
            ))}
          </div>
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
        </Paper> */
        /* </Paper> */
        /* </> */
    );
}
