"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminWordRelationship = void 0;
class AdminWordRelationship {
    constructor(wordData, matchingEntries) {
        this.wordData = wordData;
        this.matchingEntries = matchingEntries;
    }
}
exports.AdminWordRelationship = AdminWordRelationship;
