"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LetterDetails = void 0;
class LetterDetails {
    constructor(letterValue, letterIndexId) {
        this.letterValue = letterValue;
        this.letterIndexId = letterIndexId;
    }
}
exports.LetterDetails = LetterDetails;
