"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateNewGauntletPack = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
async function GenerateNewGauntletPack() {
    let path = "/items/generateNewGauntletPack";
    try {
        const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
        const response = await restOperation.response;
        console.log('GET call succeeded: ', response);
        return response.body.text;
    }
    catch (error) {
        console.log('GET call failed: ', error);
    }
    //For if this were to shift to a POST
    //   try {
    //     const user = await Auth.currentAuthenticatedUser();
    //     const token = user.signInUserSession.idToken.jwtToken;
    //     let docRef = await API.post(
    //       "pathwordsAPI",
    //       "/items/generateNewGauntletPack",
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //         body: {},
    //       }
    //     );
    //     console.debug(docRef);
    //     return docRef;
    //   } catch (e) {
    //     console.error("Error: ", e);
    //     return "error";
    //   }
}
exports.GenerateNewGauntletPack = GenerateNewGauntletPack;
