"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCareerDailyStreakStats = void 0;
class UserCareerDailyStreakStats {
    constructor(currentStreak, currentStreakPoints, lastDailyCompleteDate, lastUpdatedDate, longestStreak, longestStreakPoints, userID) {
        this.currentStreak = currentStreak;
        this.currentStreakPoints = currentStreakPoints;
        this.lastDailyCompleteDate = lastDailyCompleteDate;
        this.lastUpdatedDate = lastUpdatedDate;
        this.longestStreak = longestStreak;
        this.longestStreakPoints = longestStreakPoints;
        this.userID = userID;
    }
}
exports.UserCareerDailyStreakStats = UserCareerDailyStreakStats;
