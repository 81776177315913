"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsAuth = void 0;
const auth_1 = require("../../node_modules/aws-amplify/dist/esm/auth");
class AwsAuth {
    static async signOut() {
        await (0, auth_1.signOut)();
    }
}
exports.AwsAuth = AwsAuth;
