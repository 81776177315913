import React, { useContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

export interface IUserContext {
  dailyPuzzleData?: {
    longestStreak?: number;
    longestStreakPoints?: number;
    currentStreak?: number;
    currentStreakPoints?: number;
  };
  updateDailyPuzzleData: (context: {
    longestStreak?: number;
    longestStreakPoints?: number;
    currentStreak?: number;
    currentStreakPoints?: number;
  }) => void;
}

const userContextDefaults: IUserContext = {
  dailyPuzzleData: {
    longestStreak: 0,
    longestStreakPoints: 0,
    currentStreak: 0,
    currentStreakPoints: 0,
  },
  updateDailyPuzzleData: (context: {
    longestStreak?: number;
    longestStreakPoints?: number;
    currentStreak?: number;
    currentStreakPoints?: number;
  }) => undefined,
};

const UserContext = React.createContext<IUserContext>(userContextDefaults);

export function useUserContext() {
  return useContext(UserContext);
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [dailyPuzzleData, setDailyPuzzleData] = useState<{
    longestStreak?: number;
    longestStreakPoints?: number;
    currentStreak?: number;
    currentStreakPoints?: number;
  }>();

  function updateDailyPuzzleData(context: {
    longestStreak?: number;
    longestStreakPoints?: number;
    currentStreak?: number;
    currentStreakPoints?: number;
  }): void {
    setDailyPuzzleData({
      longestStreak: context.longestStreak,
      longestStreakPoints: context.longestStreakPoints,
      currentStreak: context.currentStreak,
      currentStreakPoints: context.currentStreakPoints,
    });
    return;
  }

  const value = {
    dailyPuzzleData,
    updateDailyPuzzleData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
