"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminParentCategory = void 0;
class AdminParentCategory {
    constructor(parentId, name, subCategories) {
        this.parentId = parentId;
        this.name = name;
        this.subCategories = subCategories;
    }
}
exports.AdminParentCategory = AdminParentCategory;
