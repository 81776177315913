"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelDetails = void 0;
class LevelDetails {
    constructor(answerCoordinateDetails, letterDetails, levelMetaData, wordDetails) {
        this.answerCoordinateDetails = answerCoordinateDetails;
        this.letterDetails = letterDetails;
        this.levelMetaData = levelMetaData;
        this.wordDetails = wordDetails;
    }
}
exports.LevelDetails = LevelDetails;
