import { useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface Props {
  buttonText: string;
  enabled: boolean;
  handleSave: (textInput: string) => void;
  label: string;
  title: string;
}

export function SingleTextInputModal({
  buttonText,
  enabled,
  handleSave,
  label,
  title,
}: Props) {
  const [open, setOpen] = useState(false);
  const [textInput, setTextInput] = useState<string>("");

  useEffect(() => {
    // if (createExpansionPackClicked) {
    //   setPackName("");
    //   setPackDescription("");
    //   setIsAvailableToUsers(false);
    //   setOpen(true);
    //   return;
    // }
    // if (updateExpansionPackClicked) {
    //   //Use current pack details to prepopulate
    //   setIsUpdate(true);
    //   if (currentPackDetails) {
    //     setPackName(currentPackDetails.name);
    //     setPackDescription(currentPackDetails.description);
    //     setIsAvailableToUsers(currentPackDetails.isAvailableToUsers);
    //     setIsAvailableToAdmins(currentPackDetails.isAvailableToAdmins);
    //   }
    //   setOpen(true);
    //   return;
    // }
    // setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setTextInput("");
  };

  return (
    <>
      <Button
        disabled={!enabled}
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          margin: 1,
          width: "auto",
        }}
        variant="contained"
      >
        {buttonText}
        <AddCircleIcon />
      </Button>

      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
          <TextField
            id="input-single-text-modal"
            fullWidth
            label={label}
            sx={{ marginTop: "10px" }}
            variant="outlined"
            onChange={(e) => setTextInput(e.target.value)}
            defaultValue={textInput}
          />
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            disabled={textInput.length === 0}
            onClick={() => {
              handleSave(textInput);
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
