import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLevelContext } from "../../context/levelContext";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import "../../css/Typography.css";
import { colors, GameState, LevelTypes, PuzzleBoard, ConvertSecondsToTimerDisplay } from "@summitrhode/shared";

interface Props {
    cacheLevelData: (board: PuzzleBoard | undefined) => void;
    isMobile: boolean;
    pausePuzzle: boolean;
    showTimer: boolean;
    levelType: LevelTypes;
    timeLimit: number;
    enableRevealPuzzleButton: () => void;
    timeOutLevel: () => void;
}

export function TimerBox({ cacheLevelData, isMobile, pausePuzzle, showTimer, levelType, timeLimit, enableRevealPuzzleButton, timeOutLevel }: Props) {
    const { currentCampaignLevel, currentExpansionLevel } = useLevelContext();
    const { puzzleBoard, updatePuzzleBoard } = usePuzzleBoardContext();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milliseconds, setMilliseconds] = useState(0);
    const [timerLoaded, setTimerLoaded] = useState<boolean>(false);
    const [stopTimer, setStopTimer] = useState<boolean>(false);
    const [shouldDisplayCountingDown, setShouldDisplayCountingDown] = useState<boolean>(levelType === LevelTypes.GAUNTLET);

    useEffect(() => {
        if (puzzleBoard && !timerLoaded) {
            const timeTaken = puzzleBoard.timeTaken;
            const cachedMinutes = Math.floor(timeTaken / 60);
            const cachedSeconds = Math.floor(timeTaken - cachedMinutes * 60);
            const cachedMilliseconds = (timeTaken - cachedMinutes * 60 - cachedSeconds) * 100;
            setMinutes(cachedMinutes);
            setSeconds(cachedSeconds);
            setMilliseconds(cachedMilliseconds);
            setTimerLoaded(true);
        }
    }, [puzzleBoard]);

    useEffect(() => {
        if (puzzleBoard && puzzleBoard.gameState !== GameState.PLAYING) {
            puzzleBoard?.setFinalTimeTaken(minutes * 60 + seconds + milliseconds / 100);
            setStopTimer(true);
        }
    }, [puzzleBoard?.gameState]);

    useEffect(() => {
        setMilliseconds(0);
        setSeconds(0);
        setMinutes(0);
    }, [currentCampaignLevel, currentExpansionLevel]);

    useEffect(() => {
        if (pausePuzzle || stopTimer) {
            return;
        }
        // let myInterval = setInterval(() => {
        //   setSeconds((second) => second + 0.1);
        // }, 100);
        let myInterval = setInterval(() => {
            setMilliseconds((millisecond) => millisecond + 1);
        }, 10);
        return () => {
            clearInterval(myInterval);
        };
    }, [pausePuzzle, stopTimer]);

    useEffect(() => {
        if (milliseconds > 100) {
            setSeconds((second) => second + 1);
            setMilliseconds(0);
            cacheLevelData(puzzleBoard);
            if (puzzleBoard?.checkTime()) {
                // updatePuzzleBoard(puzzleBoard);
                timeOutLevel();
            }
        }
        puzzleBoard?.setTimeTaken(minutes * 60 + seconds + milliseconds / 100);
    }, [milliseconds]);

    useEffect(() => {
        if (seconds > 59) {
            setMinutes((minute) => minute + 1);
            setSeconds(0);
        }
        if (levelType === LevelTypes.GAUNTLET && puzzleBoard && minutes * 60 + seconds + milliseconds / 100 > puzzleBoard.timeLimit && puzzleBoard.gameState === GameState.PLAYING) {
            if (puzzleBoard.checkTime()) {
                // updatePuzzleBoard(puzzleBoard);
                timeOutLevel();
            }
        }

        if (seconds + minutes * 60 >= 90) {
            enableRevealPuzzleButton();
        }
        // puzzleBoard?.setTimeTaken(minutes * 60 + seconds + milliseconds / 100);
    }, [seconds]);

    if (showTimer) {
        if (shouldDisplayCountingDown) {
            return <Typography sx={{ color: colors.white, fontSize: isMobile ? "2vh" : "20px" }}>{ConvertSecondsToTimerDisplay(timeLimit - (minutes * 60 + seconds))}</Typography>;
        } else {
            return (
                <Typography sx={{ color: colors.white, fontSize: isMobile ? "2vh" : "20px" }}>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
                </Typography>
            );
        }
    } else {
        return <></>;
    }
}
