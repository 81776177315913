"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Piece = void 0;
const styles_1 = require("../styles");
const _1 = require("./");
const RightArrow = require("../../assets/RightArrow.svg");
const DownArrow = require("../../assets/DownArrow.svg");
const LeftArrow = require("../../assets/LeftArrow.svg");
const UpArrow = require("../../assets/UpArrow.svg");
class Piece {
    constructor(boardIndex, row, column, letter, correctWordIndex, correctWordPieceIndex) {
        this.boardIndex = boardIndex;
        this.row = row;
        this.column = column;
        this.letter = letter;
        this.correctWordIndex = correctWordIndex;
        this.correctWordPieceIndex = correctWordPieceIndex;
        this._backgroundColor = "linear-gradient(223deg, #F0EFED 13.3%, #DDD7CB 88.66%)";
        this._accentColor = "#DDD7CB"; // "#E6E3DC";
        this.image = "";
        this.className = "";
        this.state = _1.PieceState.NODE;
        this.backgroundColor = this._backgroundColor; //rgb(230,227,220)";
        this.accentColor = this._accentColor;
        this.cssFilterBackground = "rgb(221,215,203)";
        this.cssFilterAccent = "rgb(230,227,220)";
        this.arrowFillColor = this._accentColor;
        this.arrowAccentColor = this._accentColor;
        this.textColor = styles_1.colors.black;
        this.isClickable = true;
        this.isFlashing = false;
        this.isLocked = false;
        this.flashingColors = {
            color1: this._backgroundColor,
            color2: this._backgroundColor,
        };
        /**
         * wordGuessIndex is used to find which wordGuess this piece belongs to
         */
        this.wordGuessKey = undefined;
        /**
         * wordGuessPieceIndex is used to find which number in the wordGuess this piece is
         */
        this.wordGuessPieceIndex = undefined;
    }
    id() {
        return `x${this.row}y${this.column}`;
    }
    /**
     * This function assumes that the piece was already added to the word guess
     * @param wordGuess
     */
    addToWordGuess(wordGuess) {
        this.backgroundColor = wordGuess.backgroundColor;
        if (wordGuess.isLocked) {
            this.accentColor = wordGuess.lockedAccentColor;
        }
        else {
            this.accentColor = wordGuess.terminalAccentColor;
        }
        this.textColor = wordGuess.textColor;
        this.cssFilterBackground = wordGuess.cssFilterBackground;
        this.cssFilterAccent = wordGuess.cssFilterAccent;
        this.wordGuessKey = wordGuess.wordGuessKey;
        this.wordGuessPieceIndex = wordGuess.pieceArray.length - 1;
        this.isFlashing = false;
        this.arrowFillColor = wordGuess.terminalAccentColor;
        this.arrowAccentColor = wordGuess.accentColor;
        this.setDirection(wordGuess);
    }
    /**
     * This enables piece to be manipulated
     * Turned off when right word is got on easier levels
     * or hint has been used
     * @param isClickable
     */
    setIsClickable(isClickable) {
        this.isClickable = isClickable;
    }
    /**
     * This enables piece to be locked
     * if it was revealed in a hint
     * @param isLocked
     */
    setIsLocked(isLocked) {
        this.isLocked = isLocked;
    }
    /**
     * This enables piece to be flashing
     * Turned off unless in tutorial mode
     * @param isFlashing
     * @param flashingColor
     */
    setIsFlashing(isFlashing, flashingColor = this._backgroundColor) {
        this.isFlashing = isFlashing;
        this.flashingColors = {
            color1: this._backgroundColor,
            color2: flashingColor,
        };
        if (!isFlashing) {
            this.backgroundColor = this._backgroundColor;
        }
    }
    /**
     * This switches the flashing colors back and forth on
     * the tile
     */
    flashColor() {
        if (this.backgroundColor === this.flashingColors.color2) {
            this.backgroundColor = this.flashingColors.color1;
            // this.textColor = colors.black;
        }
        else {
            this.backgroundColor = this.flashingColors.color2;
            // this.textColor = colors.white;
        }
    }
    /**
     * Returns the direction of the piece relative to the last piece in the activeWordGuess
     * EX: Piece is one row below the last piece in activeWordGuess. The pieces state is now DOWN.
     * @param piece
     * @returns
     */
    setDirection(wordGuess) {
        if (wordGuess.pieceArray.length === 1)
            return this.setState(_1.PieceState.ANCHOR);
        let lastArrayPiece = wordGuess.pieceArray.at(-2);
        if (this.row - lastArrayPiece.row === 1)
            return this.setState(_1.PieceState.DOWN);
        if (this.row - lastArrayPiece.row === -1)
            return this.setState(_1.PieceState.UP);
        if (this.column - lastArrayPiece.column === 1)
            return this.setState(_1.PieceState.RIGHT);
        if (this.column - lastArrayPiece.column === -1)
            return this.setState(_1.PieceState.LEFT);
        return;
    }
    /**
     * Setting the state changes the z index, why? the classname and image
     */
    setState(state) {
        switch (state) {
            case _1.PieceState.ANCHOR:
                this.className = "";
                this.image = "";
                this.state = _1.PieceState.ANCHOR;
                break;
            case _1.PieceState.LEFT:
                this.className = "left-piece connect";
                this.image = LeftArrow;
                this.state = _1.PieceState.LEFT;
                break;
            case _1.PieceState.RIGHT:
                this.className = "right-piece connect";
                this.image = RightArrow;
                this.state = _1.PieceState.RIGHT;
                break;
            case _1.PieceState.DOWN:
                this.className = "down-piece connect";
                this.image = DownArrow;
                this.state = _1.PieceState.DOWN;
                break;
            case _1.PieceState.UP:
                this.className = "up-piece connect";
                this.image = UpArrow;
                this.state = _1.PieceState.UP;
                break;
            case _1.PieceState.NODE:
                this.className = "";
                this.image = "";
                this.accentColor = this._accentColor;
                this.arrowFillColor = this.accentColor;
                this.arrowAccentColor = this.accentColor;
                this.backgroundColor = this._backgroundColor;
                this.textColor = styles_1.colors.black;
                this.wordGuessKey = undefined;
                this.wordGuessPieceIndex = undefined;
                this.state = _1.PieceState.NODE;
                break;
            default:
                throw new Error(`PieceState ${state} does not exist`);
        }
    }
}
exports.Piece = Piece;
