"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeTakenScore = void 0;
const _1 = require("./");
//Checks to see how many stars the player gets based on moves
function getTimeTakenScore(timeTaken, difficulty, timerCountsInScore) {
    const scoreMetrics = (0, _1.getScoringMetricsByDifficulty)(difficulty);
    if (!timerCountsInScore) {
        return 0;
    }
    let timeTakenScore = scoreMetrics.timePoints *
        (scoreMetrics.timeScalingFactor /
            (scoreMetrics.timeScalingFactor + timeTaken - scoreMetrics.minimumTime));
    switch (true) {
        case timeTakenScore > scoreMetrics.timePoints:
            return scoreMetrics.timePoints;
        case timeTakenScore < scoreMetrics.minimumTimeScore:
            return scoreMetrics.minimumTimeScore;
        default:
            return timeTakenScore;
    }
}
exports.getTimeTakenScore = getTimeTakenScore;
