"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParentSubXrefs = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
class ParentSubXrefs {
    constructor(authenticatedUserGroups) {
        this.authenticatedUserGroups = authenticatedUserGroups;
    }
    convertAdminParentSubXrefArrayToAdminParentCategoryArray(parentSubXref) {
        const returnArray = parentSubXref.map((category) => ({
            parentId: category.parentID,
            name: category.parentName,
            subCategories: category.subCategories.map((sub) => ({
                subId: sub.subID,
                psx_id: sub.psxID,
                name: sub.subName,
            })),
        }));
        return returnArray;
    }
    async getActiveParentSubXrefs() {
        if (this.authenticatedUserGroups?.isAdmin) {
            let path = "/items/getActiveParentSubXrefs";
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            return this.convertAdminParentSubXrefArrayToAdminParentCategoryArray(JSON.parse(await response.body.text()).parentSubXrefs);
        }
        return [];
    }
    async getWordsFromParentSub(parentId, subCatId) {
        if (this.authenticatedUserGroups?.isAdmin) {
            let path = "/items/getEntries?parentCategoryId=" + parentId + "&subCategoryId=" + subCatId;
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            const words = JSON.parse(await response.body.text()).words;
            const wordRelationships = JSON.parse(await response.body.text()).wordRelationships;
            return { words, wordRelationships };
        }
        return { words: [], wordRelationships: [] };
    }
    async updateParentSubEnabledForAutomation(parentId, subId, enableForAutomation) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateParentSubEnabledForAutomation",
                    parentCategoryId: parentId,
                    subCategoryId: subId,
                    enableForAutomation: enableForAutomation,
                }));
                const response = await restOperation.response;
                console.debug("Enabled for automation: ", response);
                return JSON.parse(await response.body.text()).updatedParentSubEnabledList;
            }
            catch (e) {
                console.error("Error enabling for automation: ", e);
            }
        }
        return [];
    }
    async saveWordRelationshipChanges(changeArray) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/SaveWordRelationshipChanges";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    changeArray,
                }));
                const response = await restOperation.response;
                console.debug("Word relationships modified: ", response);
            }
            catch (e) {
                console.error("Error modifying word relationships: ", e);
            }
        }
        return;
    }
    async addNewWord(parentId, subId, insertString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "InsertNewWord",
                    parentCategoryId: parentId,
                    subCategoryId: subId,
                    insertString: insertString,
                }));
                const response = await restOperation.response;
                console.debug("New word added with ID: ", response);
                const entryString = JSON.parse(await response.body.text()).insertWordResult.entryString;
                const newWords = JSON.parse(await response.body.text()).insertWordResult.newWords;
                const previouslyExistingWords = JSON.parse(await response.body.text()).insertWordResult.previouslyExistingWords;
                const wordList = JSON.parse(await response.body.text()).wordList;
                const wordListOfParent = JSON.parse(await response.body.text()).wordListOfParent;
                return { entryString, newWords, previouslyExistingWords, wordList, wordListOfParent };
            }
            catch (e) {
                console.error("Error adding word: ", e);
            }
        }
        return;
    }
    async updateWord(parentId, subId, wordId, updateString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateWord",
                    parentCategoryId: parentId,
                    subCategoryId: subId,
                    wordId: wordId,
                    updateString: updateString,
                }));
                const response = await restOperation.response;
                console.debug("Word updated with ID: ", response);
                const updatedWordList = JSON.parse(await response.body.text()).updatedWordList;
                const updatedWordListOfParent = JSON.parse(await response.body.text()).updatedWordListOfParent;
                return { updatedWordList, updatedWordListOfParent };
            }
            catch (e) {
                console.error("Error updating word: ", e);
            }
        }
        return { updatedWordList: [], updatedWordListOfParent: [] };
    }
}
exports.ParentSubXrefs = ParentSubXrefs;
