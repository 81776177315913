"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckGuessesMatchAnswers = void 0;
//Checks if the trimmed down words guessed match the correct answers
function CheckGuessesMatchAnswers(WordGuesses, correctWords) {
    for (let id in WordGuesses) {
        if (!correctWords.some(correctWord => correctWord.getWord() === WordGuesses[id].getWord())) {
            return false;
        }
    }
    return true;
}
exports.CheckGuessesMatchAnswers = CheckGuessesMatchAnswers;
