import React, { useContext, useState } from "react";
import { LevelTypes, RdsLevel } from "@summitrhode/shared/";

interface Props {
  children: React.ReactNode;
}

export interface ILevelContext {
  currentCampaignLevel: RdsLevel;
  updateCurrentCampaignLevel: (level: RdsLevel) => void;
  numberOfLevelsInCampaign: number;
  updateNumberOfLevelsInCampaign: (numLevels: number) => void;
  dailyPuzzleStats: any;
  updateDailyPuzzleStats: any;
  currentExpansionLevel: RdsLevel;
  updateCurrentExpansionLevel: (level: RdsLevel) => void;
  currentGauntletLevel: RdsLevel;
  updateCurrentGauntletLevel: (level: RdsLevel) => void;
}

const levelContextDefaults: ILevelContext = {
  currentCampaignLevel: new RdsLevel(),
  updateCurrentCampaignLevel: (level: RdsLevel) => undefined,
  numberOfLevelsInCampaign: -1,
  updateNumberOfLevelsInCampaign: (numLevels: number) => undefined,
  dailyPuzzleStats: undefined,
  updateDailyPuzzleStats: () => undefined,
  currentExpansionLevel: new RdsLevel(),
  updateCurrentExpansionLevel: (level: RdsLevel) => undefined,
  currentGauntletLevel: new RdsLevel(),
  updateCurrentGauntletLevel: (level: RdsLevel) => undefined,
};

const LevelContext = React.createContext<ILevelContext>(levelContextDefaults);

export function useLevelContext() {
  return useContext(LevelContext);
}

export const LevelProvider: React.FC<Props> = ({ children }) => {
  const [currentCampaignLevel, setCurrentCampaignLevel] = useState<RdsLevel>(
    new RdsLevel("startup", 1)
  );
  const [dailyPuzzleStats, setDailyPuzzleStats] = useState<any>();
  const [currentExpansionLevel, setCurrentExpansionLevel] = useState<RdsLevel>(
    new RdsLevel("startup", 1, LevelTypes.EXPANSION)
  );
  const [currentGauntletLevel, setCurrentGauntletLevel] = useState<RdsLevel>(
    new RdsLevel("startup", 1, LevelTypes.GAUNTLET)
  );
  const [numberOfLevelsInCampaign, setNumberOfLevelsInCampaign] =
    useState<number>(-1);

  function updateCurrentCampaignLevel(level: RdsLevel): void {
    setCurrentCampaignLevel(level);
    return;
  }

  function updateDailyPuzzleStats(stats: any): void {
    setDailyPuzzleStats(stats);
    return;
  }

  function updateCurrentExpansionLevel(level: RdsLevel): void {
    setCurrentExpansionLevel(level);
    return;
  }

  function updateCurrentGauntletLevel(level: RdsLevel): void {
    setCurrentGauntletLevel(level);
    return;
  }

  function updateNumberOfLevelsInCampaign(numLevels: number): void {
    setNumberOfLevelsInCampaign(numLevels);
    return;
  }

  const value = {
    currentCampaignLevel,
    updateCurrentCampaignLevel,
    numberOfLevelsInCampaign,
    updateNumberOfLevelsInCampaign,
    dailyPuzzleStats,
    updateDailyPuzzleStats,
    currentExpansionLevel,
    updateCurrentExpansionLevel,
    currentGauntletLevel,
    updateCurrentGauntletLevel,
  };

  return (
    <LevelContext.Provider value={value}>{children}</LevelContext.Provider>
  );
};
