"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDailyLeaderboardStats = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
// Gets the stats to populate the daily leaderboard
async function getDailyLeaderboardStats(limit, start) {
    const user = await (0, auth_1.fetchAuthSession)();
    let path = "/items/getDailyLeaderboardStats?sub=" + user.userSub;
    if (limit) {
        path += "&limit=" + limit;
    }
    if (start) {
        path += " &start=" + start;
    }
    try {
        const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
        const response = await restOperation.response;
        console.log("GET call succeeded: ", response);
        return JSON.parse(await response.body.text());
    }
    catch (error) {
        console.log("GET call failed: ", error);
    }
    return { friendsTopDailys: [], globalTopDailys: [], stats: [] };
}
exports.getDailyLeaderboardStats = getDailyLeaderboardStats;
