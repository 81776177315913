"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlanksLeftScore = void 0;
const _1 = require("./");
//Checks to see how many stars the player gets based on moves
function getBlanksLeftScore(blanksLeft, difficulty, numberOfTilesInPuzzle, timerCountsInScore) {
    const scoreMetrics = (0, _1.getScoringMetricsByDifficulty)(difficulty);
    let maxScore = scoreMetrics.blankPoints;
    if (!timerCountsInScore) {
        maxScore = scoreMetrics.blankPointsWithoutTimer;
    }
    let blanksScore = maxScore *
        ((numberOfTilesInPuzzle -
            blanksLeft * scoreMetrics.blanksLeftScalingFactor) /
            numberOfTilesInPuzzle);
    switch (true) {
        case blanksScore > maxScore:
            return maxScore;
        case blanksScore < scoreMetrics.minimumBlankScore:
            return scoreMetrics.minimumBlankScore;
        default:
            return blanksScore;
    }
}
exports.getBlanksLeftScore = getBlanksLeftScore;
