"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckForUnassignedTiles = void 0;
const index_1 = require("../../models/index");
/**
 * Returns 3 stars if all pieces are used
 * Returns 2 stars if a blank node was not used
 * Returns Playing if a letter was not used
 * @param board
 * @returns
 */
function CheckForUnassignedTiles(board) {
    let blankCount = 0;
    for (let boardIndex = 0; boardIndex < board.length; boardIndex++) {
        if (board[boardIndex].wordGuessKey === undefined) {
            if (board[boardIndex].letter === " ") {
                // return WinState.TWO_STAR;
                blankCount++;
            }
            else {
                return { gameState: index_1.GameState.PLAYING, blankCount: blankCount };
            }
        }
    }
    return { gameState: index_1.GameState.COMPLETE, blankCount: blankCount };
}
exports.CheckForUnassignedTiles = CheckForUnassignedTiles;
