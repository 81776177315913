import React from "react";
import Button from "@mui/material/Button";
import { usePuzzleBoardContext } from "../../context/puzzleBoardContext";
import { colors, GameState } from "@summitrhode/shared";

interface Props {
    nextLevelCallBack: () => void;
    nextBoxText: string;
}

export function NextBox(props: Props) {
    const { puzzleBoard } = usePuzzleBoardContext();

    return (
        <div>
            <Button variant="contained" disabled={puzzleBoard?.gameState === GameState.PLAYING} onClick={props.nextLevelCallBack} style={{ backgroundColor: colors.accent }}>
                {props.nextBoxText}
            </Button>
        </div>
    );
}
