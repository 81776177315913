"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubCategories = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
class SubCategories {
    constructor(authenticatedUserGroups) {
        this.authenticatedUserGroups = authenticatedUserGroups;
    }
    async getSubCategoriesFromParent(parentId) {
        if (this.authenticatedUserGroups?.isAdmin) {
            let path = "/items/getEntries?parentCategoryId=" + parentId;
            const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
            const response = await restOperation.response;
            return JSON.parse(await response.body.text());
        }
        return;
    }
    async addNewSubCategory(parentId, insertString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "InsertNewSubCategory",
                    parentCategoryId: parentId,
                    insertString: insertString,
                }));
                const response = await restOperation.response;
                console.debug("Sub added with ID: ", response);
                return JSON.parse(await response.body.text()).subList;
            }
            catch (e) {
                console.error("Error adding sub: ", e);
            }
        }
        return [];
    }
    async updateSubCategory(parentId, subId, updateString) {
        if (this.authenticatedUserGroups?.isAdmin) {
            try {
                let path = "/items/UpdateDictionary/";
                let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
                    method: "UpdateSubCategory",
                    parentCategoryId: parentId,
                    subCategoryId: subId,
                    updateString: updateString,
                }));
                const response = await restOperation.response;
                console.debug("Sub updated with ID: ", response);
            }
            catch (e) {
                console.error("Error updating sub: ", e);
            }
        }
        return;
    }
}
exports.SubCategories = SubCategories;
