"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPageAPICalls = void 0;
const _1 = require("./");
class AdminPageAPICalls {
    constructor(authenticatedUserGroups) {
        this.expansionPacks = new _1.ExpansionPacks(authenticatedUserGroups);
        this.levels = new _1.Levels(authenticatedUserGroups);
        this.parentCategories = new _1.ParentCategories(authenticatedUserGroups);
        this.parentSubXrefs = new _1.ParentSubXrefs(authenticatedUserGroups);
        this.subCategories = new _1.SubCategories(authenticatedUserGroups);
    }
}
exports.AdminPageAPICalls = AdminPageAPICalls;
