"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getRandomColor(usedColors) {
    const colorOptions = [
        "rgb(155,233,77)",
        "rgb(255,0,0)",
        "rgb(255,128,0)",
        "rgb(255,150,0)",
        "rgb(102,102,255)",
        "rgb(255,0,255)",
        "rgb(102,255,255)",
        "rgb(192,192,192)",
        "rgb(204,0,102)",
        "rgb(204,153,255)",
        "rgb(204,204,0)",
        "rgb(204,229,255)",
        "rgb(245,144,88)",
        "rgb(133,220,151)",
        "rgb(164,127,192)",
    ];
    let color = "";
    while (color === "") {
        const randomIndex = Math.floor(Math.random() * colorOptions.length);
        if (usedColors.findIndex((c) => c === colorOptions[randomIndex]) === -1) {
            color = colorOptions[randomIndex];
            usedColors.push(color);
        }
        if (usedColors.length === colorOptions.length) {
            const randomRed = Math.floor(Math.random() * 195 + 60);
            const randomGreen = Math.floor(Math.random() * 195 + 60);
            const randomBlue = Math.floor(Math.random() * 195 + 60);
            color = `rgb(${randomRed},${randomGreen},${randomBlue})`;
        }
    }
    return [color, usedColors];
}
exports.default = getRandomColor;
