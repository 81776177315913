import { Box, Button, SxProps, Theme } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { CampaignPuzzleMenuBox } from "./CampaignPuzzleMenuBox";
import { DailyPuzzleMenuBox } from "./DailyPuzzleMenuBox";
import { useLevelContext } from "../../context/levelContext";
import { useAuth } from "../../context/authContext";
import EditProfileModal from "../Profile/EditProfileModal";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { LoadingWheel } from "../UtilityComponents/LoadingWheel";
import { ModuleCards } from "./ModuleCards";
import { RdsLevel, GenerateNewGauntletPack, getHomeScreenUserContext, LevelTypes, OrderExpansionPacks, UserFullDailyStats, UserPartialDailyStats, ExpansionPack } from "@summitrhode/shared";
import AdsenseComponent from "../Ads/AdsComponent";

let homeContainerStyle: SxProps<Theme> | undefined = undefined;

export function HomePage() {
    // Context
    const { currentCampaignLevel, updateCurrentCampaignLevel, updateCurrentGauntletLevel, updateNumberOfLevelsInCampaign } = useLevelContext();
    const { authenticatedUserGroups, updatePathWordUsername } = useAuth();
    const { updateDailyPuzzleData } = useUserContext();
    const navigate = useNavigate();

    // States
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [showEditProfileModal, setShowEditProfileModal] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [gauntletButtonEnabled, setGauntletButtonEnabled] = useState<boolean>(false);
    const [gauntletCreationButtonEnabled, setGauntletCreationButtonEnabled] = useState<boolean>(true); // Placeholder for now until admin page is built out to support the Gauntlet creation
    const [globalTopDailys, setGlobalTopDailys] = useState<UserFullDailyStats[]>([]);
    const [friendsTopDailys, setFriendsTopDailys] = useState<UserFullDailyStats[]>([]);
    const [expansionPackLevels, setExpansionPackLevels] = useState<ExpansionPack[]>([]);
    const [userDailyStats, setUserDailyStats] = useState<UserPartialDailyStats | undefined>();
    const [userStreakStats, setUserStreakStats] = useState<any | undefined>();
    const [pendingFriendRequests, setPendingFriendRequests] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState(false);

    // Data Loading
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.matchMedia("(max-width: 600px)").matches);
        }
        getInitialContext().then(() => setPageLoaded(true));
        // Attach the event listener to the window object
        window.addEventListener("resize", handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getInitialContext = async () => {
        await getHomeScreenUserContext().then((context: Awaited<ReturnType<typeof getHomeScreenUserContext>>) => {
            // This will send the user to the tutorial if the userData is not created. This should be refactored
            console.log(context);
            if (context.userData.length === 0) {
                navigate("/tutorial");
            } else if (context.userData.length > 0 && context.userData[0].username) {
                updatePathWordUsername(context.userData[0].username);
            } else {
                setShowEditProfileModal(true);
            }
            if (context.globalTopDailys) {
                setGlobalTopDailys(context.globalTopDailys);
            }
            if (context.friendTopDailys) {
                setFriendsTopDailys(context.friendTopDailys);
            }
            if (context.expansionPackLevels) {
                setExpansionPackLevels(OrderExpansionPacks(context.expansionPackLevels));
            }
            if (context.pendingFriendRequests) {
                setPendingFriendRequests(context.pendingFriendRequests.length);
            }
            if (context.userDailyStats) {
                updateDailyPuzzleData(context.userDailyStats[0]);
                setUserStreakStats(context.userDailyStats[0]);
            }
            if (context.gauntletInstanceData) {
                updateCurrentGauntletLevel(new RdsLevel("", context.gauntletInstanceData.levelNumber, LevelTypes.GAUNTLET, undefined, undefined, context.gauntletInstanceData.gauntletPackId));
                setGauntletButtonEnabled(true);
            }
            if (context.dailyStats.length > 0) {
                const tempStats = context.dailyStats[0];
                setUserDailyStats(new UserPartialDailyStats(tempStats.moveCount, tempStats.timeTaken, tempStats.score, tempStats.userID));
            }
            if (context.campaignLevel[0].level_number > 0) {
                updateCurrentCampaignLevel(new RdsLevel("launch", context.campaignLevel[0].level_number + 1));
                updateNumberOfLevelsInCampaign(context.campaignLevel[0].numberOfLevels);
            } else {
                updateCurrentCampaignLevel(new RdsLevel("", 1));
                updateNumberOfLevelsInCampaign(1);
            }
        });
        // await createNewGauntletPack();
    };

    // Callbacks
    const playGauntletPuzzleClicked = () => {
        navigate("/gauntlet");
    };
    const createNewGauntletPack = async () => {
        return await GenerateNewGauntletPack();
    };

    // CSS
    if (!isMobile) {
        homeContainerStyle = {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            minWidth: "100vw",
            justifyContent: "center",
        };
    } else {
        homeContainerStyle = {};
    }

    // HTML
    if (pageLoaded) {
        window.scrollTo(0, 0);
        if (showEditProfileModal) {
            return (
                <div>
                    <EditProfileModal currentData={undefined} firstTimeSetup hideModal={() => setShowEditProfileModal(false)} />
                </div>
            );
        }
        return (
            <Box className="home-container" sx={homeContainerStyle}>
                <DailyPuzzleMenuBox isMobile={true} friendsTopDailys={friendsTopDailys} globalTopDailys={globalTopDailys} userDailyStats={userDailyStats} userStreakStats={userStreakStats} />
                <CampaignPuzzleMenuBox userCurrentCampaignLevel={currentCampaignLevel.levelNumber} isMobile expansionPacks={[]} key={"campaign"} />
                <ModuleCards isMobile={isMobile} pendingFriendRequests={pendingFriendRequests} />

                {/* <div style={{ position:"absolute", height: '5rem', width: '95%', maxWidth: '800px', bottom:"0" }}>
                    <AdsenseComponent adClient="ca-pub-3829790692231392" adSlot="4591879259" adFormat="auto" />
                </div> */}
            </Box>
        );
    } else {
        return <LoadingWheel />;
    }
}
