"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignStats = void 0;
class CampaignStats {
    constructor(campaignLevels, userCampaignStats) {
        this.campaignLevels = campaignLevels;
        this.userCampaignStats = userCampaignStats;
    }
}
exports.CampaignStats = CampaignStats;
