"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUserNameAvailability = void 0;
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const createAuthGetInput_1 = require("../BasicFunctions/createAuthGetInput");
// Checks if a username already exists
async function checkUserNameAvailability(username) {
    let path = "/items/checkUserNameAvailability?username=" + username;
    const restOperation = (0, api_1.get)(await (0, createAuthGetInput_1.createAuthGetInput)(path));
    const response = await restOperation.response;
    const jsonBody = await response.body.json();
    console.log("GET call succeeded: ", jsonBody);
    return jsonBody[0].count;
}
exports.checkUserNameAvailability = checkUserNameAvailability;
