"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCareerTopDailyStats = void 0;
class UserCareerTopDailyStats {
    constructor(date, percentile, ranking, timesDone, totalPlayers, userID) {
        this.date = date;
        this.percentile = percentile;
        this.ranking = ranking;
        this.timesDone = timesDone;
        this.totalPlayers = totalPlayers;
        this.userID = userID;
    }
}
exports.UserCareerTopDailyStats = UserCareerTopDailyStats;
