"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminSubCategory = void 0;
class AdminSubCategory {
    constructor(subId, psx_id, name, enabled_for_automation) {
        this.subId = subId;
        this.psx_id = psx_id;
        this.name = name;
        this.enabled_for_automation = enabled_for_automation;
    }
}
exports.AdminSubCategory = AdminSubCategory;
