"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgb(
      ${parseInt(result[1], 16)},
      ${parseInt(result[2], 16)},
      ${parseInt(result[3], 16)})
    `
        : hex;
}
exports.default = hexToRgb;
