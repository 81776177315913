"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelTypes = void 0;
var LevelTypes;
(function (LevelTypes) {
    LevelTypes[LevelTypes["CAMPAIGN"] = 0] = "CAMPAIGN";
    LevelTypes[LevelTypes["DAILY"] = 1] = "DAILY";
    LevelTypes[LevelTypes["EXPANSION"] = 2] = "EXPANSION";
    LevelTypes[LevelTypes["USER_CREATED"] = 3] = "USER_CREATED";
    LevelTypes[LevelTypes["TUTORIAL"] = 4] = "TUTORIAL";
    LevelTypes[LevelTypes["GAUNTLET"] = 5] = "GAUNTLET";
})(LevelTypes || (exports.LevelTypes = LevelTypes = {}));
