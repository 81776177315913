import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Paper, TextField } from "@mui/material";
import {
  UpdateUserProfile,
  checkUserNameAvailability,
} from "@summitrhode/shared";
import { cleanStringEntry } from "../Admin/UtilityFunctions";
import { useAuth } from "../../context/authContext";

interface Props {
  currentData: any | undefined;
  firstTimeSetup: boolean;
  hideModal: () => void;
}

export default function EditProfileModal({
  currentData,
  firstTimeSetup,
  hideModal,
}: Props) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState<string>("Edit Profile");
  const [pathwordUsername, setPathwordUsername] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [usernameIsAvailable, setUsernameIsAvailable] = useState<boolean>(true);

  const { authenticatedUserGroups, attrInfo, username } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const thing = attrInfo;
    const that = username;
    if (firstTimeSetup) {
      setTitle("Welcome! Set up your profile!");
      if (username) {
        setPathwordUsername(username);
      }
    }
    handleShow();
  }, []);

  const saveData = async () => {
    const checkCount = await checkUserNameAvailability(pathwordUsername);
    const tempUsername = cleanStringEntry(pathwordUsername);
    const tempFirstName = cleanStringEntry(firstName);
    const tempLastName = cleanStringEntry(lastName);

    if (checkCount === 0) {
      UpdateUserProfile(tempUsername, tempFirstName, tempLastName, "", true);
      hideModal();
    } else {
      setUsernameIsAvailable(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Paper sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              required
              id="outlined-required"
              inputProps={{ maxLength: 15 }}
              label="Display Name"
              defaultValue={username}
              onChange={(e) => {
                setPathwordUsername(e.target.value);
                setUsernameIsAvailable(true);
              }}
              error={!usernameIsAvailable}
              helperText={usernameIsAvailable ? "" : "Need a unique username!"}
            />

            <TextField
              required
              id="outlined-helperText"
              label="First Name"
              defaultValue={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              required
              id="outlined-helperText"
              label="Last Name"
              defaultValue={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            {/* <TextField
              required
              id="outlined-helperText"
              label={`Birthday "MM-DD-YYYY"`}
              defaultValue={birthday}
              onChange={(e) => {
                setBirthday(e.target.value);
              }}
            /> */}
          </Paper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={hideModal}>
            Close
          </Button>
          <Button variant="contained" onClick={saveData}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
