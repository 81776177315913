import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, List, ListItem, ListItemText, Divider, MenuItem, Select, Button, ListItemButton, SelectChangeEvent, InputLabel, FormControl } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AdminParentCategory, AdminSubCategory, AdminWord, AdminWordRelationship, ModifyCommandTypes } from "@summitrhode/shared";
import { colors } from "@summitrhode/shared";
import { SingleTextInputModal } from "../UtilityComponents/SingleTextInputModal";
import { Oval } from "react-loader-spinner";

interface CustomModalProps {
    addNewParentCategory: (textInput: string) => void;
    addNewSubCategory: (parentId: number, textInput: string) => void;
    defaultWordIndex: number;
    loading: boolean;
    open: boolean;
    onClose: () => void;
    onSave: (
        changedWordRelationships: {
            wordID: number;
            parentID: number;
            subID: number;
            initialParentID: number;
            initialSubID: number;
            initialPsxID: number;
            modificationCommandType: ModifyCommandTypes;
        }[]
    ) => void;
    list: string[];
    parentCategories: AdminParentCategory[];
    puzzleWordsRelationships: AdminWordRelationship[];
}

interface RelationshipType {
    entryIndex: number;
    initialParentID: number;
    initialSubID: number;
    initialPsxID: number;
    parentID: number;
    parentName: string;
    parentSubXrefID: number;
    subID: number;
    subName: string;
    subCategoryList: AdminSubCategory[];
    wordID: number;
    wordName: string;
    wasModified: boolean;
    enableUpdate: boolean;
    showRelationship: boolean;
    modificationCommandType: ModifyCommandTypes;
    modificationColor: string;
}

interface ComponentProps {
    option1: string;
    option2: string;
}

export const DictionaryEntryModificationModal: React.FC<CustomModalProps> = ({
    addNewParentCategory,
    addNewSubCategory,
    defaultWordIndex,
    loading,
    open,
    onClose,
    onSave,
    list,
    parentCategories,
    puzzleWordsRelationships,
}) => {
    const [selectedWordRelationships, setSelectedWordRelationships] = useState<RelationshipType[]>([]);

    const [wordRelationships, setWordRelationships] = useState<
        {
            wordData: { wordId: number; name: string };
            matchingEntries: RelationshipType[];
            wasModified: boolean;
        }[]
    >([]);

    const [activeWordIndex, setActiveWordIndex] = useState<number>(defaultWordIndex);
    const [activeWord, setActiveWord] = useState<string>("");
    const [previousParentCategories, setPreviousParentCategories] = useState<AdminParentCategory[]>([]);
    const [undoAll, setUndoAll] = useState<boolean>(false);
    const [changeWasMade, setChangeWasMade] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            initializePage();
        }
    }, [open]);

    useEffect(() => {
        if (undoAll) {
            initializePage();
            setUndoAll(false);
            setChangeWasMade(false);
        }
    }, [undoAll]);

    useEffect(() => {
        if (previousParentCategories.length > 0) {
            parentCategories.forEach((parent) => {
                const matchingParent = previousParentCategories.find((entry) => entry.parentId === parent.parentId);
                if (matchingParent?.subCategories?.length !== parent.subCategories?.length) {
                    updateSubCategoriesForParent(parent.parentId);
                }
            });
            setPreviousParentCategories(parentCategories);
        }
    }, [parentCategories]);

    const updateDefaultWordIndex = async (index: number) => {
        setActiveWordIndex(index);
        return;
    };

    const initializePage = () => {
        //Set all the relationships
        const tempRelationships: {
            wordData: AdminWord;
            matchingEntries: RelationshipType[];
            wasModified: boolean;
        }[] = [];

        puzzleWordsRelationships.forEach((word) => {
            const tempMatchingEntriesArray: RelationshipType[] = [];
            word.matchingEntries.forEach((relationship, index) => {
                tempMatchingEntriesArray.push({
                    entryIndex: index,
                    initialParentID: relationship.parentID,
                    initialSubID: relationship.subID,
                    initialPsxID: relationship.parentSubXrefID,
                    parentID: relationship.parentID,
                    parentName: relationship.parentName,
                    parentSubXrefID: relationship.parentSubXrefID,
                    subID: relationship.subID,
                    subName: relationship.subName,
                    subCategoryList: getSubCategories(relationship.parentID),
                    wordID: relationship.wordID,
                    wordName: relationship.wordName,
                    wasModified: false,
                    enableUpdate: false,
                    showRelationship: true,
                    modificationCommandType: ModifyCommandTypes.NONE,
                    modificationColor: "",
                });
            });
            tempRelationships.push({
                wordData: word.wordData,
                matchingEntries: tempMatchingEntriesArray,
                wasModified: false,
            });
        });

        setChangeWasMade(false);
        setPreviousParentCategories(parentCategories);
        setWordRelationships(tempRelationships);
        setSelectedWordRelationships(tempRelationships[defaultWordIndex].matchingEntries);
        setActiveWord(tempRelationships[defaultWordIndex].wordData.name);
        setActiveWordIndex(defaultWordIndex);
    };

    const updateSubCategoriesForParent = (parentId: number) => {
        const subCategoryList = getSubCategories(parentId);

        const tempWordRelationships: {
            wordData: {
                wordId: number;
                name: string;
            };
            matchingEntries: RelationshipType[];
            wasModified: boolean;
        }[] = wordRelationships.map((relationship) => {
            relationship.matchingEntries.map((entry) => {
                if (entry.parentID === parentId) {
                    entry.subCategoryList = subCategoryList;
                }
                return entry;
            });
            return relationship;
        });

        const tempSelectedWordRelationships: RelationshipType[] = selectedWordRelationships.map((entry) => {
            if (entry.parentID === parentId) {
                entry.subCategoryList = subCategoryList;
            }
            return entry;
        });

        setWordRelationships(tempWordRelationships);
        setSelectedWordRelationships(tempSelectedWordRelationships);
    };

    const handleWordSelection = (index: number) => {
        setActiveWordIndex(index);
        setSelectedWordRelationships(wordRelationships[index].matchingEntries);
        setActiveWord(puzzleWordsRelationships[index].wordData.name);
    };

    const getSubCategories = (parentId: number): AdminSubCategory[] => {
        const tempParent: AdminParentCategory[] = parentCategories.filter((p) => p.parentId === parentId);

        if (tempParent[0].subCategories) {
            return tempParent[0].subCategories;
        }
        return [];
    };

    const getParentName = (parentId: number): string => {
        const tempParent: AdminParentCategory[] = parentCategories.filter((p) => p.parentId === parentId);
        return tempParent[0].name;
    };

    const handleParentChange = (e: SelectChangeEvent<number>, relationship: RelationshipType) => {
        const parentId = Number(e.target.value);
        const tempWordRelationships = [...wordRelationships];
        const tempRelationship = tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex];

        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].parentID = parentId;
        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].parentName = getParentName(parentId);
        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].subID = -1;
        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].subName = "";
        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].subCategoryList = getSubCategories(parentId);

        //Enable updating if the parent doesn't match the initial parent
        if (tempRelationship.initialParentID !== tempRelationship.parentID) {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = true;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].enableUpdate = true;
        } else {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = false;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].enableUpdate = false;
        }

        setSelectedWordRelationships(tempWordRelationships[activeWordIndex].matchingEntries);
        setWordRelationships(tempWordRelationships);
    };

    const handleSubChange = (e: SelectChangeEvent<number>, relationship: RelationshipType) => {
        const subId = Number(e.target.value);
        const tempWordRelationships = [...wordRelationships];

        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].subID = subId;

        const tempRelationship = tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex];

        //Enable updating if the subCategory doesn't match the initial sub
        if (tempRelationship.initialParentID !== tempRelationship.parentID || tempRelationship.subID !== tempRelationship.initialSubID) {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = true;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].enableUpdate = true;
        } else {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = false;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].enableUpdate = false;
        }

        setSelectedWordRelationships(tempWordRelationships[activeWordIndex].matchingEntries);
        setWordRelationships(tempWordRelationships);
    };

    const handleAddRelationshipClicked = () => {
        const tempWordRelationships = [...wordRelationships];

        tempWordRelationships[activeWordIndex].matchingEntries.push({
            entryIndex: tempWordRelationships[activeWordIndex].matchingEntries.length,
            initialParentID: -1,
            initialSubID: -1,
            initialPsxID: -1,
            parentID: -1,
            parentName: "",
            parentSubXrefID: -1,
            subID: -1,
            subName: "",
            subCategoryList: [],
            wordID: tempWordRelationships[activeWordIndex].wordData.wordId,
            wordName: tempWordRelationships[activeWordIndex].wordData.name,
            wasModified: false,
            enableUpdate: false,
            showRelationship: true,
            modificationCommandType: ModifyCommandTypes.NONE,
            modificationColor: "",
        });

        setSelectedWordRelationships(tempWordRelationships[activeWordIndex].matchingEntries);
        setWordRelationships(tempWordRelationships);
    };

    const handleUpdateClicked = (relationship: RelationshipType) => {
        const tempWordRelationships = [...wordRelationships];

        tempWordRelationships[activeWordIndex].wasModified = true;
        setChangeWasMade(true);

        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].enableUpdate = false;

        if (relationship.initialParentID > -1) {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationCommandType = ModifyCommandTypes.UPDATE;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationColor = "Blue";
        } else {
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationCommandType = ModifyCommandTypes.INSERT;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationColor = "Green";
        }

        setSelectedWordRelationships(tempWordRelationships[activeWordIndex].matchingEntries);
        setWordRelationships(tempWordRelationships);
    };

    const handleRemoveClicked = (relationship: RelationshipType) => {
        const tempWordRelationships = [...wordRelationships];

        if (relationship.initialParentID > -1 && relationship.initialSubID > -1) {
            // The relationship existed from the database
            tempWordRelationships[activeWordIndex].wasModified = true;
            setChangeWasMade(true);
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationCommandType = ModifyCommandTypes.DELETE;
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationColor = "Red";

            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = true;
        } else {
            // The relationship was just made by the admin in this session
            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationCommandType = ModifyCommandTypes.NONE;

            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].modificationColor = "";

            tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].wasModified = false;
        }

        tempWordRelationships[activeWordIndex].matchingEntries[relationship.entryIndex].showRelationship = false;

        setSelectedWordRelationships(tempWordRelationships[activeWordIndex].matchingEntries);
        setWordRelationships(tempWordRelationships);
    };

    const handleSaveChangesClicked = () => {
        const changedWordRelationships: {
            wordID: number;
            parentID: number;
            subID: number;
            initialParentID: number;
            initialSubID: number;
            initialPsxID: number;
            modificationCommandType: ModifyCommandTypes;
        }[] = [];

        wordRelationships.forEach((relationship) => {
            if (relationship.wasModified) {
                relationship.matchingEntries.forEach((entry) => {
                    if (entry.wasModified) {
                        changedWordRelationships.push({
                            wordID: entry.wordID,
                            parentID: entry.parentID,
                            subID: entry.subID ? entry.subID : -1,
                            initialParentID: entry.initialParentID,
                            initialSubID: entry.initialSubID,
                            initialPsxID: entry.initialPsxID,
                            modificationCommandType: entry.modificationCommandType,
                        });
                    }
                });
            }
        });

        onSave(changedWordRelationships);
    };

    if (loading) {
        <Modal open={open}>
            <Oval ariaLabel="loading-indicator" height={100} width={100} strokeWidth={5} strokeWidthSecondary={1} color={colors.primary} secondaryColor="white" />
        </Modal>;
    } else {
        return (
            <Modal open={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        maxWidth: "100vw",
                        maxHeight: "600px",

                        p: 4,
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box
                            sx={{
                                marginRight: 2,
                                maxHeight: "500px",
                                overflow: "auto",
                            }}
                        >
                            <Typography variant="h6" component="h2" gutterBottom>
                                Words
                            </Typography>
                            <List>
                                {wordRelationships.map((item, index) => (
                                    <ListItemButton key={item.wordData.wordId} selected={index === activeWordIndex} autoFocus={index === activeWordIndex} onClick={() => handleWordSelection(index)}>
                                        <ListItemText
                                            disableTypography
                                            primary={item.wordData.name}
                                            sx={{
                                                fontWeight: item.matchingEntries.length > 0 ? "" : "Bold",
                                                textDecoration: item.matchingEntries.length > 0 ? "" : "underline dotted",
                                            }}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box
                            sx={{
                                marginLeft: 2,
                                maxHeight: "500px",
                                overflow: "auto",
                            }}
                        >
                            <Typography variant="h6" component="h2" gutterBottom>
                                Categories for {activeWord}
                            </Typography>
                            {selectedWordRelationships.map(
                                (relationship, index) =>
                                    relationship.showRelationship && (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: 2,
                                                backgroundColor: index % 2 === 0 ? colors.grayscaleTint + "99" : colors.white,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginRight: 2,
                                                }}
                                            >
                                                <FormControl
                                                    // fullWidth
                                                    sx={{
                                                        minWidth: "200px",
                                                        maxWidth: "400px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <InputLabel id="Parent-Category-Select-Label">Parent Category</InputLabel>
                                                    <Select
                                                        id="Parent-Category-Select"
                                                        label="Parent-Category"
                                                        labelId="Parent-Category-Select-Label"
                                                        onChange={(e) => handleParentChange(e, relationship)}
                                                        sx={{ backgroundColor: "white" }}
                                                        value={relationship.parentID > -1 ? relationship.parentID : undefined}
                                                    >
                                                        <MenuItem>----</MenuItem>
                                                        {parentCategories.map((option) => (
                                                            <MenuItem key={option.parentId} value={option.parentId}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <SingleTextInputModal
                                                    buttonText=""
                                                    enabled={true}
                                                    handleSave={(textInput) => {
                                                        addNewParentCategory(textInput);
                                                    }}
                                                    label="Parent Category"
                                                    title="New Parent Category"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginRight: 2,
                                                }}
                                            >
                                                <FormControl
                                                    // fullWidth
                                                    sx={{
                                                        minWidth: "200px",
                                                        maxWidth: "400px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <InputLabel id="Sub-Category-Select-Label">Sub Category</InputLabel>
                                                    <Select
                                                        id="Sub-Category-Select"
                                                        label="Sub-Category"
                                                        labelId="Sub-Category-Select-Label"
                                                        onChange={(e) => handleSubChange(e, relationship)}
                                                        sx={{ backgroundColor: "white" }}
                                                        value={relationship.subID !== null && relationship.subID > -1 ? relationship.subID : undefined}
                                                    >
                                                        <MenuItem>----</MenuItem>
                                                        {relationship.subCategoryList.map((option) => (
                                                            <MenuItem key={option.subId} value={option.subId}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <SingleTextInputModal
                                                    buttonText=""
                                                    enabled={relationship.parentID > -1}
                                                    handleSave={(textInput) => addNewSubCategory(relationship.parentID, textInput)}
                                                    label="Sub Category"
                                                    title="New Sub Category"
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Button variant="contained" color="secondary" disabled={!relationship.enableUpdate} onClick={() => handleUpdateClicked(relationship)}>
                                                    Update
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleRemoveClicked(relationship);
                                                    }}
                                                >
                                                    <RemoveCircleIcon />
                                                </Button>
                                            </Box>
                                        </Box>
                                    )
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    handleAddRelationshipClicked();
                                }}
                            >
                                Add New Relationship <AddCircleIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "25px",
                        }}
                    >
                        <Button onClick={onClose} variant="contained" color="secondary">
                            Close
                        </Button>
                        <Button disabled={!changeWasMade} onClick={() => setUndoAll(true)} variant="contained" color="primary">
                            Undo All
                        </Button>
                        <Button
                            disabled={!changeWasMade}
                            onClick={() => {
                                handleSaveChangesClicked();
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    }
};
