"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCareerStatsByDifficulty = void 0;
class UserCareerStatsByDifficulty {
    constructor(averageScore, averageTime, difficulty, levelsPlayed) {
        this.averageScore = averageScore;
        this.averageTime = averageTime;
        this.difficulty = difficulty;
        this.levelsPlayed = levelsPlayed;
    }
}
exports.UserCareerStatsByDifficulty = UserCareerStatsByDifficulty;
