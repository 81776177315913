"use strict";
/**
 * Defines the commands allowed to admins when
 * modifying entries in the database
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModifyCommandTypes = void 0;
var ModifyCommandTypes;
(function (ModifyCommandTypes) {
    ModifyCommandTypes[ModifyCommandTypes["NONE"] = 0] = "NONE";
    ModifyCommandTypes[ModifyCommandTypes["INSERT"] = 1] = "INSERT";
    ModifyCommandTypes[ModifyCommandTypes["UPDATE"] = 2] = "UPDATE";
    ModifyCommandTypes[ModifyCommandTypes["DELETE"] = 3] = "DELETE";
})(ModifyCommandTypes || (exports.ModifyCommandTypes = ModifyCommandTypes = {}));
