"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieceState = void 0;
var PieceState;
(function (PieceState) {
    //Piece able to be grabbed or dragged over to extend a chain
    PieceState[PieceState["NODE"] = 0] = "NODE";
    //Anchor point to grab and move along the puzzle to extend the chain
    PieceState[PieceState["ANCHOR"] = 1] = "ANCHOR";
    //The final letter for a word that is revealed (in the easier difficulties)
    PieceState[PieceState["TERMINAL"] = 2] = "TERMINAL";
    //The directions of the arrows when an anchor moves from the tile in a given direction
    PieceState[PieceState["UP"] = 3] = "UP";
    PieceState[PieceState["DOWN"] = 4] = "DOWN";
    PieceState[PieceState["LEFT"] = 5] = "LEFT";
    PieceState[PieceState["RIGHT"] = 6] = "RIGHT";
})(PieceState || (exports.PieceState = PieceState = {}));
