import React from "react";
import Button from "@mui/material/Button";
import { colors } from "@summitrhode/shared";

interface Props {
    previousLevelCallBack: () => void;
    previousBoxText: string;
}

export function PreviousBox(props: Props) {
    return (
        <div>
            <Button variant="contained" onClick={props.previousLevelCallBack} style={{ backgroundColor: colors.accent }}>
                {props.previousBoxText}
            </Button>
        </div>
    );
}
