"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitUserLevelFeedback = void 0;
const createAuthPostInput_1 = require("../BasicFunctions/createAuthPostInput");
const api_1 = require("../../../node_modules/aws-amplify/dist/esm/api");
const auth_1 = require("../../../node_modules/aws-amplify/dist/esm/auth");
async function SubmitUserLevelFeedback(levelID, starCount, comment) {
    let path = "/items/submitUserLevelFeedback";
    const user = await (0, auth_1.fetchAuthSession)();
    let restOperation = (0, api_1.post)(await (0, createAuthPostInput_1.createAuthPostInput)(path, {
        userID: user.userSub,
        levelID,
        starCount,
        comment,
    }));
    const response = await restOperation.response;
    console.debug("Document written with ID: ", response);
    return;
}
exports.SubmitUserLevelFeedback = SubmitUserLevelFeedback;
