"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserLoggedOn = void 0;
const getUser_1 = require("./getUser");
async function isUserLoggedOn() {
    try {
        let test = await (0, getUser_1.getUser)();
        return true;
    }
    catch (err) {
        if (err.name && err.name == "UserUnAuthenticatedException") {
            return false;
        }
        throw new Error(err);
    }
}
exports.isUserLoggedOn = isUserLoggedOn;
