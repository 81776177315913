import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {colors, ConvertSecondsToTimerDisplay, UserFullDailyStats } from "@summitrhode/shared";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


interface Props {
  displayGroup: string;
  friendScores?: UserFullDailyStats[];
  globalScores?: UserFullDailyStats[];
  podiumColors: string[];
  isLeaderboard?: boolean;
  navigatePage: () => void;
}

const Leaderboard = (props: Props) => {
  const navigate = useNavigate();
  const {
    displayGroup,
    friendScores,
    globalScores,
    podiumColors,
    isLeaderboard,
    navigatePage,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleUsernameClicked = (userID: string) => {
    navigate("/profile", { state: { profileToView: userID } });
  };

  const showFullLeaderboardClicked = () => {
    navigate("/leaderboard");
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxHeight: isLeaderboard ? "65vh" : 250 }}
      >
        <Table sx={{}} aria-label="sticky table">
          <TableHead
            sx={{
              backgroundColor: colors.accentShade,
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
            title="Today's Daily"
          >
            <TableRow>
              <TableCell
                sx={{
                  color: colors.white,
                  backgroundColor: colors.accentShade,
                  left: 0,
                  position: "sticky",
                }}
                align="center"
              >
                {isLeaderboard ? "Name" : "Top Players"}
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Moves
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Time
              </TableCell>
              <TableCell sx={{ color: colors.white }} align="center">
                Score
              </TableCell>
              {isLeaderboard && (
                <>
                  <TableCell sx={{ color: colors.white }} align="center">
                    Streak
                  </TableCell>
                  <TableCell sx={{ color: colors.white }} align="center">
                    Blanks Left
                  </TableCell>
                  <TableCell sx={{ color: colors.white }} align="center">
                    Hints Used
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayGroup === "friends" &&
              friendScores?.map((entry, index) => (
                <TableRow
                  key={entry.username}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    // component="th"
                    scope="row"
                    sx={{
                      left: 0,
                      position: "sticky",
                      backgroundColor: colors.white,
                    }}
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        handleUsernameClicked(entry.userID);
                      }}
                    >
                      {index < 3 && (
                        <EmojiEventsIcon sx={{ color: podiumColors[index] }} />
                      )}
                      {index >= 3 && index + 1}
                      {"  "}
                      {entry.username}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{entry.moveCount}</TableCell>
                  <TableCell align="center">
                    {ConvertSecondsToTimerDisplay(entry.timeTaken)}
                  </TableCell>
                  <TableCell align="center">{entry.score}</TableCell>
                  {isLeaderboard && (
                    <>
                      <TableCell align="center">
                        {entry.currentStreak}
                      </TableCell>
                      <TableCell align="center">{entry.blanksLeft}</TableCell>
                      <TableCell align="center">{entry.bigHintsUsed}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            {displayGroup === "global" &&
              globalScores?.map((entry, index) => {
                return (
                  <TableRow
                    key={entry.username}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      // component="th"
                      scope="row"
                      sx={{
                        left: 0,
                        position: "sticky",
                        backgroundColor: colors.white,
                      }}
                    >
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          handleUsernameClicked(entry.userID);
                        }}
                      >
                        {index < 3 && (
                          <EmojiEventsIcon
                            sx={{ color: podiumColors[index] }}
                          />
                        )}
                        {index >= 3 && index + 1}
                        {"  "}
                        {entry.username}
                      </Button>
                    </TableCell>
                    <TableCell align="center">{entry.moveCount}</TableCell>
                    <TableCell align="center">
                      {ConvertSecondsToTimerDisplay(entry.timeTaken)}
                    </TableCell>
                    <TableCell align="center">{entry.score}</TableCell>
                    {isLeaderboard && (
                      <>
                        <TableCell align="center">
                          {entry.currentStreak}
                        </TableCell>
                        <TableCell align="center">{entry.blanksLeft}</TableCell>
                        <TableCell align="center">
                          {entry.bigHintsUsed}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            <TableRow onClick={navigatePage}>
              <TableCell
                // component="th"
                scope="row"
                sx={{
                  color: colors.accent,
                  fontStyle: "italic",
                  left: 0,
                  position: "sticky",
                  backgroundColor: colors.white,
                }}
                className="hover:cursor-pointer"
              >
                {isLeaderboard ? "Home >>>" : "See All >>>"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Leaderboard;
