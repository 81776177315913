"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanAddAnchors = exports.ShouldDisplayAnchors = exports.Difficulty = void 0;
var Difficulty;
(function (Difficulty) {
    Difficulty[Difficulty["EASY"] = 0] = "EASY";
    Difficulty[Difficulty["MEDIUM"] = 1] = "MEDIUM";
    Difficulty[Difficulty["HARD"] = 2] = "HARD";
    Difficulty[Difficulty["EXPERT"] = 3] = "EXPERT";
    Difficulty[Difficulty["GOD"] = 4] = "GOD";
})(Difficulty || (exports.Difficulty = Difficulty = {}));
function ShouldDisplayAnchors(difficulty) {
    if (difficulty === Difficulty.EASY || difficulty === Difficulty.MEDIUM)
        return true;
    return false;
}
exports.ShouldDisplayAnchors = ShouldDisplayAnchors;
function CanAddAnchors(difficulty) {
    if (difficulty === Difficulty.EASY || difficulty === Difficulty.MEDIUM)
        return false;
    return true;
}
exports.CanAddAnchors = CanAddAnchors;
