import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Checkbox, Collapse, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { colors, Difficulty, ExpansionPackDetails } from "@summitrhode/shared";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

interface PackDetails {
    packDetails: ExpansionPackDetails;
    open: boolean;
}

interface Props {
    packDetails: ExpansionPackDetails[];
    handleClose: (saved: boolean) => void;
    handleSave: (levels: ExpansionPackDetails[]) => void;
    open: boolean;
}

export default function ExpansionPackDetailsModal({ packDetails, handleClose, handleSave, open }: Props) {
    const [levels, setLevels] = useState<PackDetails[]>([]);
    const [enableSave, setEnableSave] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            const levels: PackDetails[] = packDetails.map((pack) => {
                return {
                    packDetails: pack,
                    open: false,
                };
            });

            setLevels(levels);
        } else {
            setLevels([]);
            setEnableSave(false);
        }
    }, [open]);

    const handleSubtextOpen = (levelIndex: number) => {
        const tempLevels: PackDetails[] = levels.map((level, index) => {
            if (index === levelIndex) {
                level.open = !level.open;
            }

            return level;
        });
        setLevels(tempLevels);
    };

    const handleMoveLevelNumber = (tempLevels: PackDetails[], levelIndex: number, moveUp: boolean) => {
        // const tempLevels: PackDetails[] = levels;

        const replacementLevelIndex = moveUp ? -1 : 1;

        const replacementLevel = tempLevels[levelIndex + replacementLevelIndex];
        tempLevels[levelIndex + replacementLevelIndex] = tempLevels[levelIndex];
        tempLevels[levelIndex] = replacementLevel;

        setEnableSave(false);
        tempLevels.forEach((level, index) => {
            if (level.packDetails.levelNumber - 1 !== index) {
                setEnableSave(true);
            }
        });

        setLevels(tempLevels);
    };

    const resetLevels = async () => {
        setLevels([]);
        return;
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <List
                    sx={{
                        // justifyContent: "space-around",
                        width: "100%",
                        maxWidth: 800,
                        maxHeight: 600,
                        overflow: "auto",
                        bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Pack Details
                        </ListSubheader>
                    }
                >
                    {levels.map((level, index) => (
                        <>
                            <ListItemButton
                                disableRipple={true}
                                sx={{
                                    backgroundColor: index % 2 ? colors.white : colors.grayscaleTint,
                                }}
                            >
                                <ListItemButton
                                    disabled={index === 0}
                                    onClick={() => {
                                        const tempLevels = levels;
                                        resetLevels().then(() => handleMoveLevelNumber(tempLevels, index, true));
                                    }}
                                    sx={{ justifyContent: "center", maxWidth: "20px" }}
                                >
                                    <ArrowUpwardIcon color={index === 0 ? "disabled" : "primary"} fontSize="medium" />
                                </ListItemButton>
                                <ListItemButton
                                    disabled={index === levels.length - 1}
                                    onClick={() => {
                                        const tempLevels = levels;
                                        resetLevels().then(() => handleMoveLevelNumber(tempLevels, index, false));
                                    }}
                                    sx={{ justifyContent: "center", maxWidth: "20px" }}
                                >
                                    <ArrowDownwardIcon color={index === levels.length - 1 ? "disabled" : "secondary"} fontSize="medium" />
                                </ListItemButton>
                                <ListItemText primary={index + 1} />
                                <ListItemText primary={Difficulty[level.packDetails.difficulty]} />
                                <ListItemText primary={level.packDetails.title} />
                                <Button onClick={() => handleSubtextOpen(index)} variant="contained" size="small" color="secondary">
                                    Words
                                </Button>
                            </ListItemButton>

                            <Collapse in={level.open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton
                                        disableRipple={true}
                                        sx={{
                                            pl: 4,
                                            backgroundColor: index % 2 ? colors.white : colors.grayscaleTint,
                                        }}
                                    >
                                        <ListItemText primary={level.packDetails.words.map((word, index) => word.wordName + (index === level.packDetails.words.length - 1 ? "" : ", "))} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </>
                    ))}
                </List>
                <Button onClick={() => handleClose(false)}>Close</Button>
                <Button
                    disabled={!enableSave}
                    onClick={() =>
                        handleSave(
                            levels.map((level) => {
                                return level.packDetails;
                            })
                        )
                    }
                >
                    Save
                </Button>
            </Box>
        </Modal>
    );
}
