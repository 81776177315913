"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function msToHMS(duration) {
    let milliseconds = (duration % 1000) / 100, seconds = Math.floor((duration / 1000) % 60), minutes = Math.floor((duration / (1000 * 60)) % 60), hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    minutes = minutes < 10 && hours > 0 ? "0" + minutes : minutes;
    hours = hours < 10 ? "0" + hours : hours;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    hours = hours === "00" ? "0" : hours;
    minutes = minutes === "00" ? "0" : minutes;
    if (hours < 1) {
        return minutes + ":" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
}
exports.default = msToHMS;
