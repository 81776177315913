import { useEffect, useState } from "react";
import {
  colors,
  UserFullDailyStats,
  getDailyLeaderboardStats,
} from "@summitrhode/shared";
import Pagination from "@mui/material/Pagination";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LoadingWheel } from "../UtilityComponents/LoadingWheel";
import Leaderboard from "../Leaderboard";
import { useNavigate } from "react-router-dom";
let Podium = require("@summitrhode/shared/assets/Podium.svg");

const podiumColors: string[] = ["gold", "silver", "#CD7F32"];
const PAGINATION_LIMIT = 250;
const ROW_DISPLAY_LIMIT = 50;

export function DailyLeaderboardPage() {
  const mql = window.matchMedia("(max-width: 600px)");
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  const [displayGroup, setDisplayGroup] = useState("global");
  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLoaded, setPagesLoaded] = useState<number>(0);
  const [totalRows, setTotalRows] = useState({ friends: 0, global: 0 });

  const [globalTopDailys, setGlobalTopDailys] = useState<UserFullDailyStats[][]>(
    []
  );
  const [friendsTopDailys, setFriendsTopDailys] = useState<
  UserFullDailyStats[][]
  >([]);
  const navigate = useNavigate();
  // Save for later
  // let mobileView = mql.matches;
  // const navigate = useNavigate();

  useEffect(() => {
    getInitialStats().then(() => {
      setPageLoaded(true);
      setPagesLoaded(pagesLoaded + 3);
    });
  }, [paginationStart]);

  const getInitialStats = async () => {
    await getDailyLeaderboardStats(PAGINATION_LIMIT, 0).then((res) => {
      if (res.globalTopDailys) {
        // populate the stats data
        setGlobalTopDailys(
          separateIntoArrays(res.globalTopDailys, ROW_DISPLAY_LIMIT)
        );
        setTotalRows({ ...totalRows, global: res.globalTopDailys.length });
      }

      if (res.friendsTopDailys) {
        setFriendsTopDailys(
          separateIntoArrays(res.friendsTopDailys, ROW_DISPLAY_LIMIT)
        );
        setTotalRows({ ...totalRows, friends: res.friendsTopDailys.length });
      }
    });
  };
  const separateIntoArrays = (arr: any[], chunkSize: number) => {
    const resultArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      resultArray.push(arr.slice(i, i + chunkSize));
    }
    return resultArray;
  };

  const handleDisplayGroupChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value) {
      setDisplayGroup(value);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const count =
    displayGroup === "global"
      ? globalTopDailys.length // globalStats.totalCount
      : friendsTopDailys.length; // friendStats.totalCount

  if (pageLoaded) {
    return (
      <div className="container mt-3">
        <div className="flex items-center">
          <img src={Podium} className="h-8 mr-2" />
          <h3 className="!mb-0">{`Top 250 ${
            displayGroup === "global" ? "World Wide" : "Friends"
          }`}</h3>
        </div>

        <ToggleButtonGroup
          color="secondary"
          fullWidth
          value={displayGroup}
          exclusive
          onChange={(event, value) => handleDisplayGroupChange(event, value)}
          // aria-label="Platform"
          sx={{
            backgroundColor: colors.grayscaleTint,
            justifyContent: "center",
          }}
        >
          <ToggleButton value="friends">
            <span
              className={
                displayGroup === "friends" ? "underline font-bold" : ""
              }
            >
              Friends
            </span>
          </ToggleButton>
          <ToggleButton value="global">
            <span
              className={displayGroup === "global" ? "underline font-bold" : ""}
            >
              Global
            </span>
          </ToggleButton>
        </ToggleButtonGroup>
        <Leaderboard
          displayGroup={displayGroup}
          friendScores={friendsTopDailys[currentPage - 1]}
          globalScores={globalTopDailys[currentPage - 1]}
          podiumColors={podiumColors}
          navigatePage={() => navigate("/")}
          isLeaderboard
        />
        <Pagination
          count={count === 0 ? 1 : Math.ceil(count / ROW_DISPLAY_LIMIT)}
          showFirstButton
          showLastButton
          onChange={handleChange}
        />
      </div>
    );
  } else {
    return <LoadingWheel />;
  }
}
