"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertSecondsToTimerDisplay = void 0;
/**
 * Converts seconds into MM:SS:MS display
 * @param seconds
 * @returns string
 */
function ConvertSecondsToTimerDisplay(seconds) {
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    let minutesString = "" + minutes;
    // if (minutesString.length === 1) {
    //   minutesString = "0" + minutesString;
    // }
    let secondsString = "" + seconds;
    if (seconds < 10) {
        secondsString = "0" + secondsString;
    }
    secondsString = secondsString.substring(0, 5);
    return minutesString + ":" + secondsString;
}
exports.ConvertSecondsToTimerDisplay = ConvertSecondsToTimerDisplay;
