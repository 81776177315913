import { Avatar, BottomNavigation, BottomNavigationAction, Box, Button, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
    getUserProfileDetailInformation,
    Difficulty,
    submitFriendRequest,
    handleFriendRequest,
    colors,
    UserCareerDailyStreakStats,
    UserCareerTopDailyStats,
    UserCareerStatsByDifficulty,
} from "@summitrhode/shared";
import { AccountCircle, Check, People, ShowChart } from "@mui/icons-material";
import { LoadingWheel } from "../UtilityComponents/LoadingWheel";
import { ProfilePageOverview } from "./ProfilePageOverview";
import { ProfilePageStats } from "./ProfilePageStats";
import { useAuth } from "../../context/authContext";
import { useLocation } from "react-router-dom";
import { FriendsList } from "./FriendsList";
import { UserPartialDailyStats } from "@summitrhode/shared";
import { FriendListEntry, ProfileData } from "@summitrhode/shared/build/models/Profile";

interface Props {
    defaultNavigationIndex: number;
}

export function ProfilePage({ defaultNavigationIndex }: Props) {
    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;
    const [username, setUsername] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [navigationValue, setNavigationValue] = useState(defaultNavigationIndex);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);

    const { user } = useAuth();
    const { state } = useLocation();
    const { profileToView } = state;

    const [index, setIndex] = useState(0);
    const [desiredCase, setDesiredCase] = useState("uppercase");
    const [desiredTheme, setDesiredTheme] = useState("light");

    const [friendStatus, setFriendStatus] = useState<{
        RequesterId: string;
        AddresseeId: string;
        StatusCode: string;
    }>({ RequesterId: "", AddresseeId: "", StatusCode: "" });

    const [friendList, setFriendList] = useState<FriendListEntry[]>([]);

    const [pendingFriendRequestsList, setPendingFriendRequests] = useState<FriendListEntry[]>([]);

    const [profileData, setProfileData] = useState<ProfileData>({
        firstName: "",
        lastName: "",
        username: "",
        userID: "",
    });
    const [userDailyStats, setUserDailyStats] = useState<UserPartialDailyStats>({
        moveCount: -1,
        score: -1,
        timeTaken: -1,
        userID: "",
    });
    const [userByDifficultyStats, setUserByDifficultyStats] = useState<UserCareerStatsByDifficulty[]>([
        {
            averageScore: -1,
            averageTime: -1,
            difficulty: Difficulty.EASY,
            levelsPlayed: -1,
        },
    ]);
    const [userCareerDailyStats, setUserCareerDailyStats] = useState<UserCareerTopDailyStats>({
        date: "",
        percentile: -1,
        ranking: -1,
        timesDone: -1,
        totalPlayers: -1,
        userID: "",
    });
    const [userCareerDailyStreakStats, setUserCareerDailyStreakStats] = useState<UserCareerDailyStreakStats>({
        currentStreak: 0,
        currentStreakPoints: 0,
        lastDailyCompleteDate: new Date("1999-01-06"),
        lastUpdatedDate: new Date("1999-01-06"),
        longestStreak: 0,
        longestStreakPoints: 0,
        userID: "",
    });
    const [userCareerExpansionPackStats, setUserCareerExpansionPackStats] = useState<{
        levelsComplete: number;
        packsComplete: number;
    }>({
        levelsComplete: 0,
        packsComplete: 0,
    });

    // get the user data from the context
    useEffect(() => {
        setPageLoaded(false);
        if (user) {
            let userID = user.userId;
            if (profileToView) {
                userID = profileToView;
            }
            getProfileData(userID);
            // setNavigationValue(0);
        }
        setNavigationValue(defaultNavigationIndex);

        getDesiredCaseData();
        getDesiredTheme();
    }, [profileToView]);

    const getProfileData = async (userID: string) => {
        const tempProfileData = await getUserProfileDetailInformation(userID);
        if (tempProfileData.profileData.length > 0) {
            setProfileData(tempProfileData.profileData[0]);
        }
        if (tempProfileData.currentFriendStatus.length > 0) {
            setFriendStatus(tempProfileData.currentFriendStatus[0]);
        } else {
            setFriendStatus({ RequesterId: "", AddresseeId: "", StatusCode: "" });
        }

        if (tempProfileData.friendList) {
            setFriendList(tempProfileData.friendList);
        }

        if (tempProfileData.dailyStats.length > 0) {
            setUserDailyStats(tempProfileData.dailyStats[0]);
        }
        if (tempProfileData.userByDifficultyStats) {
            setUserByDifficultyStats(tempProfileData.userByDifficultyStats);
        }
        if (tempProfileData.userCareerDailyStats) {
            setUserCareerDailyStats(tempProfileData.userCareerDailyStats);
        }
        if (tempProfileData.userCareerDailyStreakStats.length > 0) {
            setUserCareerDailyStreakStats(tempProfileData.userCareerDailyStreakStats[0]);
        }
        if (tempProfileData.userCareerExpansionPackStats.length > 0) {
            setUserCareerExpansionPackStats(tempProfileData.userCareerExpansionPackStats[0]);
        }

        if (tempProfileData.pendingFriendRequests) {
            setPendingFriendRequests(tempProfileData.pendingFriendRequests);
        }

        setPageLoaded(true);
        // updateUserID(tempProfileData.profileData[0].userID);
    };

    const getDesiredCaseData = async () => {
        try {
            const value = localStorage.getItem("@desiredCase");
            // console.log(value);
            if (value !== null) {
                setDesiredCase(value);
            }
        } catch (e) {
            // error reading value
        }
    };

    const desiredCaseStoreKey = "@desiredCase";
    const storeDesiredCaseData = async (value: string) => {
        try {
            localStorage.setItem(desiredCaseStoreKey, value);
        } catch (e) {
            // saving error
        }
    };

    const getDesiredTheme = async () => {
        try {
            const value = localStorage.getItem("@desiredTheme");
            // console.log(value);
            if (value !== null) {
                setDesiredTheme(value);
            }
        } catch (e) {
            // error reading value
        }
    };

    const desiredThemeStoreKey = "@desiredTheme";
    const storeDesiredThemeData = async (value: string) => {
        try {
            localStorage.setItem(desiredThemeStoreKey, value);
        } catch (e) {
            // saving error
        }
    };

    const handleDesiredCaseChange = (value: string) => {
        setDesiredCase(value);

        storeDesiredCaseData(value);
    };

    const handleDesiredThemeChange = (value: string) => {
        setDesiredTheme(value);

        storeDesiredThemeData(value);
    };

    const handleAddFriendButtonClicked = async () => {
        if (user) {
            await submitFriendRequest(user.userId, profileData.userID);
            setFriendStatus({
                RequesterId: user.userId,
                AddresseeId: profileData.userID,
                StatusCode: "R",
            });
        }
    };

    const handleAcceptFriendRequestButtonClicked = async () => {
        await handleFriendRequest(friendStatus.RequesterId, friendStatus.AddresseeId, "A");
        setFriendStatus({
            RequesterId: friendStatus.RequesterId,
            AddresseeId: friendStatus.AddresseeId,
            StatusCode: "A",
        });
    };

    const handleDeclineFriendRequestButtonClicked = async () => {
        await handleFriendRequest(friendStatus.RequesterId, friendStatus.AddresseeId, "D");
        setFriendStatus({
            RequesterId: friendStatus.RequesterId,
            AddresseeId: friendStatus.AddresseeId,
            StatusCode: "D",
        });
    };

    //TODO implement settings
    const getSettings = () => {
        // const friends = [
        //   {
        //     key: 1,
        //     userName: "capital",
        //     Name: "Capital",
        //     picture: "https://picsum.photos/id/103/100/",
        //   },
        //   {
        //     key: 2,
        //     userName: "joeyd",
        //     Name: "Joey Dwyer",
        //     picture: "https://picsum.photos/id/129/100/",
        //   },
        //   {
        //     key: 3,
        //     userName: "laiterface",
        //     Name: "Christian Laiter",
        //     picture: "https://picsum.photos/id/77/100/",
        //   },
        //   {
        //     key: 4,
        //     userName: "Sarawin",
        //     Name: "Sara Winslow",
        //     picture: "https://picsum.photos/id/64/100/",
        //   },
        //   {
        //     key: 5,
        //     userName: "WordyMcWordFace",
        //     Name: "Luis McLuisFace",
        //     picture: "https://picsum.photos/id/21/100/",
        //   },
        // ];
        // return (
        //   <View
        //     style={[styles.container, { backgroundColor: theme.colors.surface }]}
        //   >
        //     <View
        //       style={[styles.header, { backgroundColor: theme.colors.surface }]}
        //     >
        //       <Avatar.Image
        //         size={125}
        //         source={{ uri: "https://picsum.photos/100?grayscale" }}
        //       />
        //       <View>
        //         <Text variant="headlineMedium">BillyBob14</Text>
        //         <Button mode="contained">Add As Friend</Button>
        //       </View>
        //     </View>
        //     <View>
        //       <Text variant="bodyMedium">
        //         Would you prefer the puzzle tiles be lowercase or UPPERCASE?
        //       </Text>
        //       <SegmentedButtons
        //         value={desiredCase}
        //         onValueChange={(val) => handleDesiredCaseChange(val)}
        //         buttons={[
        //           {
        //             value: "lowercase",
        //             label: "lowercase",
        //           },
        //           {
        //             value: "uppercase",
        //             label: "UPPERCASE",
        //           },
        //         ]}
        //       />
        //       <Text variant="bodyMedium">
        //         Would you prefer the color palette be light, dark, or color blind
        //         friendly?
        //       </Text>
        //       <SegmentedButtons
        //         value={desiredTheme}
        //         onValueChange={(val) => handleDesiredThemeChange(val)}
        //         buttons={[
        //           {
        //             value: "light",
        //             label: "Light",
        //           },
        //           {
        //             value: "dark",
        //             label: "Dark",
        //           },
        //           {
        //             value: "colorblind",
        //             label: "Color Blind",
        //           },
        //         ]}
        //       />
        //     </View>
        //   </View>
        // );
    };

    // const [routes] = useState([
    //   {
    //     key: "overview",
    //     title: "Overview",
    //     focusedIcon: "text-account",
    //     unfocusedIcon: "text-account",
    //     component: getOverview(),
    //   },
    //   {
    //     key: "stats",
    //     title: "Stats",
    //     focusedIcon: "chart-line",
    //     component: getStats(),
    //   },
    //   {
    //     key: "friends",
    //     title: "Friends",
    //     focusedIcon: "account-group",
    //     component: getFriends(),
    //   },
    //   {
    //     key: "settings",
    //     title: "Settings",
    //     focusedIcon: "cog",
    //     unfocusedIcon: "cog",
    //     component: getSettings(),
    //   },
    // ]);
    if (pageLoaded) {
        return (
            <Box>
                <Box
                    sx={{
                        backgroundColor: colors.grayscaleTint,
                        maxHeight: "70vh",
                        overflow: "auto",
                    }}
                >
                    <Paper sx={{ display: "flex", flexDirection: "row" }}>
                        <Avatar src={"https://picsum.photos/100?grayscale"} />
                        <Box>
                            <Typography>{profileData.username}</Typography>
                            {user && user.userId !== profileData.userID && (friendStatus.StatusCode === "" || friendStatus.StatusCode === "D") && (
                                <Button onClick={() => handleAddFriendButtonClicked()}>Add As Friend</Button>
                            )}
                            {user && user.userId !== profileData.userID && friendStatus.StatusCode === "R" && friendStatus.RequesterId === user.userId && (
                                <Typography fontStyle={"italic"}>Friend Request Sent</Typography>
                            )}
                            {user && user.userId !== profileData.userID && friendStatus.StatusCode === "R" && friendStatus.AddresseeId === user.userId && (
                                <>
                                    <Button variant="contained" onClick={() => handleAcceptFriendRequestButtonClicked()}>
                                        Accept Friend
                                    </Button>
                                    <Button variant="contained" style={{ color: "tertiary" }} onClick={() => handleDeclineFriendRequestButtonClicked()}>
                                        Decline Friend
                                    </Button>
                                </>
                            )}
                            {user && user.userId !== profileData.userID && friendStatus.StatusCode === "A" && (
                                <Typography fontStyle={"italic"}>
                                    <Check />
                                    Friends
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                    {navigationValue === 0 && (
                        <ProfilePageOverview
                            careerDailyStats={userCareerDailyStats}
                            careerDailyStreakStats={userCareerDailyStreakStats}
                            careerExpansionPackStats={userCareerExpansionPackStats}
                            dailyStats={userDailyStats}
                            profileData={profileData}
                        />
                    )}
                    {navigationValue === 1 && <ProfilePageStats profileData={profileData} statsByDifficulty={userByDifficultyStats} />}
                    {navigationValue === 2 && <FriendsList friends={friendList} pendingFriendRequests={pendingFriendRequestsList} />}
                </Box>
                {/* {routes[navigationValue].component} */}
                <BottomNavigation
                    showLabels
                    value={navigationValue}
                    onChange={(event, newValue) => {
                        // if (newValue === routes[0].key) {
                        //   getOverview();
                        // }
                        setNavigationValue(newValue);
                    }}
                    sx={{ width: "100%", position: "absolute", bottom: 0 }}
                >
                    <BottomNavigationAction label="Overview" icon={<AccountCircle />} />
                    <BottomNavigationAction label="Stats" icon={<ShowChart />} />
                    <BottomNavigationAction label="Friends" icon={<People />} />
                </BottomNavigation>
            </Box>
        );
    } else {
        return <LoadingWheel />;
    }
}
