interface Props {
  fillColor: string;
  accentColor: string;
  outterWidth: string;
}

export function LeftArrow({ fillColor, accentColor, outterWidth }: Props) {
  return (
    <svg
      width={outterWidth}
      height={outterWidth}
      viewBox="0 0 604 663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.5772 337.887C35.0979 322.134 35.0979 282.499 62.5772 266.747L395.137 76.1072C422.47 60.4385 456.527 80.1716 456.527 111.677L456.527 492.956C456.527 524.462 422.47 544.195 395.137 528.526L62.5772 337.887Z"
        fill={accentColor}
      />

      <rect
        x="304.527"
        y="391.157"
        width="177"
        height="358.628"
        rx="62"
        transform="rotate(-90 304.527 391.157)"
        fill={accentColor}
      />
      <path
        d="M84.0103 333.343C59.8821 319.512 59.8821 284.71 84.0103 270.879L388.123 96.5467C412.123 82.7889 442.027 100.115 442.027 127.779L442.027 476.444C442.027 504.107 412.123 521.434 388.124 507.676L84.0103 333.343Z"
        fill={fillColor}
      />
      <rect
        x="347.527"
        y="375.157"
        width="146.584"
        height="297"
        rx="45"
        transform="rotate(-90 347.527 375.157)"
        fill={fillColor}
      />
    </svg>
  );
}
